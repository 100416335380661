import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
declare var $: any;

enum infoOpen {
  none = 0,
  nobble = 1,
  clergy = 2,
  knights = 3,
  oligarchy = 4,
  plebs = 5
}

@Component({
  selector: 'app-social-order',
  templateUrl: './social-order.component.html',
  styleUrls: ['./social-order.component.css']
})

export class SocialOrderComponent implements OnInit, AfterViewInit {

  constructor() {
    
  }
  info: infoOpen;
  scroll: any;
  scrollTime: number = 4000;

  ngOnInit(): void {
    this.info = 0;
  }

  ngAfterViewInit(): void {

    if (!this.ShowParalax())
      return;

    gsap.timeline({ scrollTrigger: { trigger: '.scrolltop', start: 'center center', toggleActions: "play none none none", onLeave: self => self.endAnimation(), onLeaveBack: self => self.endAnimation() } })
      .to(".Brave", { duration: 2, text: "Are you brave enough to<br>challenge the established order?", ease: "linear" })

    let tlEffect01 = this.createEffect01();
    let tlEffect02 = this.createEffect02();
    var cloudAnim = gsap.to(".cloud2", {
      duration: 360,
      ease: "none",
      x: "+=2500",
      modifiers: {
        x: gsap.utils.unitize(x => parseFloat(x) % 2500)
      },
      repeat: -1
    });


    var targets = document.querySelectorAll(".scrollDist");
    
    var options = {
      threshold: 0.1  //10% height is visible, observer should be triggered
    }

    const callback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          cloudAnim.play();
          if(tlEffect01)
            tlEffect01.play();
          if (tlEffect02)
            tlEffect02.play();
        } else {
          cloudAnim.pause(0);
          if (tlEffect01)
            tlEffect01.pause(0);
          if (tlEffect02)
            tlEffect02.pause(0);
        }
      });
    }
    var observer = new IntersectionObserver(callback, options);
    targets.forEach(target => observer.observe(target));
    
    gsap.timeline({
      scrollTrigger: {
        trigger: '.scrollDist', start: 'top center-=600', end: 'bottom bottom+=50', scrub: 0}
    })
      .fromTo('.sky', { y: -20, scale: "+=0.025", x: -22, autoAlpha: 1.5 }, { y: 150, scale: "-=0.025", x: 0, autoAlpha: 0.3 }, 0)
      .fromTo('.layer7', { y: -20, x: -2, scale: "+=0.012" }, { y: 120, x: 0, scale: "-=0.012" }, 0)
      .fromTo('.layer6', { y: 0 }, { y: 120 }, 0)
      .fromTo('.layer3', { y: -10 }, { y: 70 }, 0)
      .fromTo('.layer2', { y: -150 }, { y: 120 }, 0)
      .fromTo('.layer1', { y: -250 }, { y: 150 }, 0)
  }

  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    this.wheel(event);
    if (this.scroll) {
      this.scroll.stop();
      this.scroll = undefined;
    }
  }

  public wheel(event) {
    return;
    var delta = 0;
    if (event.wheelDelta) delta = event.wheelDelta / 120;
    else if (event.detail) delta = -event.detail / 3;
    this.handle(delta);
    if (event.preventDefault) event.preventDefault();
    event.returnValue = false;
  }

  handle(delta) {
    var time = 600;
    var distance = window.innerHeight / 3;
    $('html, body').stop().animate({
      scrollTop: $(window).scrollTop() - (distance * delta)
    }, time);
  }

  ScrollTo(dist) {
    if (this.scroll) {
      this.scroll.stop();
      this.scroll = undefined;
    }
    this.scroll = $('html, body').animate({
      scrollTop: $(window).scrollTop()
        + (dist)
    }, {
      duration: this.scrollTime,
      iterations: 1
    });
  }

  createEffect01() {
    return gsap.timeline({ repeat: -1, delay:0.2 })
      .to(".layer4",
        {
          y: "-=4",
          duration: 2,
          ease: Sine.easeInOut
        }
      )
      .to(".layer4",
        {
          y: "+=4",
          duration: 2,
          ease: Sine.easeInOut
        }
      );
  }
  createEffect02() {
    return gsap.timeline({ repeat: -1, delay: 0.2 })
      .to(".layer5",
        {
          y: "+=10",
          duration: 3,
          ease: Sine.easeInOut
        }
      )
      .to(".layer5",
        {
          y: "-=10",
          duration: 3,
          ease: Sine.easeInOut
        }
      );
  }

  NobilityMouseEnter() {
    gsap.fromTo('.RectFN', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  NobilityMouseLeave() {
    gsap.fromTo('.RectFN', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  ClergyMouseEnter() {
    gsap.fromTo('.RectFC', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  ClergyMouseLeave() {
    gsap.fromTo('.RectFC', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  GuardiansMouseEnter() {
    gsap.fromTo('.RectFG', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  GuardiansMouseLeave() {
    gsap.fromTo('.RectFG', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  OligarchyMouseEnter() {
    gsap.fromTo('.RectFO', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  OligarchyMouseLeave() {
    gsap.fromTo('.RectFO', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  PlebeiansMouseEnter() {
    gsap.fromTo('.RectFP', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  PlebeiansMouseLeave() {
    gsap.fromTo('.RectFP', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }

  closeAllPaper() {
    if (this.scroll) {
      this.scroll.stop();
      this.scroll = undefined;
    }
    if (this.info == 1) {
      gsap.fromTo('.paper1', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
      gsap.fromTo('.scroll1', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
    }
    if (this.info == 2) {
      gsap.fromTo('.paper2', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
      gsap.fromTo('.scroll2', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
    }
    if (this.info == 3) {
      gsap.fromTo('.paper3', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
      gsap.fromTo('.scroll3', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
    }
    if (this.info == 4) {
      gsap.fromTo('.paper4', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
      gsap.fromTo('.scroll4', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
    }
    if (this.info == 5) {
      gsap.fromTo('.paper5', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
      gsap.fromTo('.scroll5', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
    }
    this.info = 0;
  }

  triggerAnim1() {
    console.log(this.info);
    if (this.info != 1) {
      this.closeAllPaper();
      this.ScrollTo(document.querySelector(".scrollDist")!.getBoundingClientRect().top);
      //this.scroll = gsap.to(window, { duration: 3, scrollTo: { y: ".scrollDist", offsetY: 0, autoKill: true } });
      gsap.fromTo('.paper1', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
      gsap.fromTo('.scroll1', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
      this.info = 1;
    }
    else {
      this.closeAllPaper();
      this.info = 0;
    }
  }

  triggerAnim2() {
    console.log(this.info);
    if (this.info != 2) {
      this.closeAllPaper();
      this.ScrollTo(document.querySelector(".scrollDist")!.getBoundingClientRect().top + this.GetHeight() * .20);
      //this.scroll = gsap.to(window, { duration: 3, scrollTo: { y: ".scrollDist", offsetY: -this.GetHeight() * .20, autoKill: true } });
      gsap.fromTo('.paper2', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
      gsap.fromTo('.scroll2', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
      this.info = 2;
    }
    else {
      this.closeAllPaper();
      this.info = 0;
    }
  }

  triggerAnim3() {
    if (this.info != 3) {
      this.closeAllPaper();
      this.ScrollTo(document.querySelector(".scrollDist")!.getBoundingClientRect().top + this.GetHeight() * .30);
      //this.scroll = gsap.to(window, { duration: 3, scrollTo: { y: ".scrollDist", offsetY: -this.GetHeight() * .35, autoKill: true } });
      gsap.fromTo('.paper3', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
      gsap.fromTo('.scroll3', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
      this.info = 3;
    }
    else {
      this.closeAllPaper();
      this.info = 0;
    }
  }

  triggerAnim4() {
    if (this.info != 4) {
      this.closeAllPaper();
      this.ScrollTo(document.querySelector(".scrollDist")!.getBoundingClientRect().top + this.GetHeight() * .45);
      //this.scroll = gsap.to(window, { duration: 3, scrollTo: { y: ".scrollDist", offsetY: -this.GetHeight() * .45, autoKill: true } });
      gsap.fromTo('.paper4', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
      gsap.fromTo('.scroll4', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
      this.info = 4;
    }
    else {
      this.closeAllPaper();
      this.info = 0;
    }
  }

  triggerAnim5() {

    if (this.info != 5) {
      this.closeAllPaper();
      this.ScrollTo(document.querySelector(".scrollDist")!.getBoundingClientRect().top + this.GetHeight() * .52);
      //this.scroll = gsap.to(window, { duration: 3, scrollTo: { y: ".scrollDist", offsetY: -this.GetHeight() * .52, autoKill: true } });
      gsap.fromTo('.paper5', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
      gsap.fromTo('.scroll5', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
      this.info = 5;
    }
    else {
      this.closeAllPaper();
      this.info = 0;
    }
  }

  triggerAnim6() {
    this.closeAllPaper();
    this.ScrollTo(document.querySelector(".scrollDist")!.getBoundingClientRect().top + this.GetHeight() * .52);
    //this.scroll = TweenMax.to(window, 5 , { scrollTo: { y: ".scrollDist", offsetY: -this.GetHeight() * .52, autoKill: true } });
  }

  GetHeight(): number {
    const target = document.querySelector(".scrollDist")
    return target!.scrollHeight;
  }

  ShowParalax() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width > 1200) {
      return true;
    } else {
      return false;
    }
  }


  getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    const browser =
      agent.indexOf('edge') > -1 ? 'Microsoft Edge'
        : agent.indexOf('edg') > -1 ? 'Chromium-based Edge'
          : agent.indexOf('opr') > -1 ? 'Opera'
            : agent.indexOf('chrome') > -1 ? 'Chrome'
              : agent.indexOf('trident') > -1 ? 'Internet Explorer'
                : agent.indexOf('firefox') > -1 ? 'Firefox'
                  : agent.indexOf('safari') > -1 ? 'Safari'
                    : 'other';

    return browser;
  }
}
