import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {
  showCharacters = false;
  french = false;

  ngOnInit() {
    if (window.screen.width > 1660) {
      this.showCharacters = true;
    }
    var userLang = navigator.language;
    this.french = userLang == "fr" || userLang == "fr-FR";
  }

  OnClickFr() {
    this.french = true;
  }
  OnClickEn() {
    this.french = false;
  }

  @HostListener("window:resize", [])
  onResize() {
    var width = window.screen.width;
    this.showCharacters = width > 1660;
  }
}
