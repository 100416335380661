<!--Section: FAQ-->
<app-hud></app-hud>
<section class="m-auto" style="padding-top: 75px; background-image: url(assets/assets_web_2/bg-dashboard.webp); background-attachment: fixed; background-position: bottom; position: relative; background-repeat: no-repeat; background-size: cover;">
  <div class="text-center mb-4 pb-2 pt-5 pt-lg-2">
    <h1 class="my-auto" id="title">FAQ</h1>
  </div>
  <div class="row gx-5 px-5">
    <p class="text-center mb-5">
      Find the answers for the most frequently asked questions below.<br /><br />
      We would like to remind you that soulcast is a game <strong>in development</strong><br /> and therefore the information provided here is subject to change.
    </p>
    <div class="col-md-6 col-lg-4 mb-4 moving">
      <h6 class="mb-3">
        What is SoulCast exactly?
      </h6>
      <p>
        SoulCast is an <strong>Online Action RPG</strong><br />
        with a focus on instance-based PvP and PvPvE combat.
        It combines the depth, immersion and customization of an RPG with the speed and skill-shots of a modern multiplayer game.
      </p>
    </div>

    <div class="col-md-6 col-lg-4 mb-4 moving">
      <h6 class="mb-3 ">
        Is Soulcast multiplayer only or can I also play solo?
      </h6>
      <p>
        For the moment, we plan to implement mainly multiplayer gameplay based on <strong>teams of 3 or 5 players</strong>
        and later even large scale battles with 32 vs 32 players.
        We want to create also PvE content that you can theoretically do alone,
        but we encourage you to meet other soulcasters.<br /> Together you are stronger.
      </p>
    </div>

    <div class="col-md-6 col-lg-4 mb-4 moving">
      <h6 class="mb-3 ">
        Will the game have an in-game shop ?
      </h6>
      <p>
        <strong>Yes</strong>, we plan to implement an in-game shop, however, it will contain only cosmetic items.
        We are gamers ourselves and therefore<strong> we are strictly against any P2W or P2F mechanics.</strong>
      </p>
    </div>

    <div class="col-md-6 col-lg-4 mb-4 moving">
      <h6 class="mb-3 ">
        How can I play ?
      </h6>
      <p routerLink="/shop" class="PlayBtn" style="cursor:pointer">Visit our shop</p>
      <p>
        Here, you have several packs to choose. Read through the additional informations given in the description
        <strong>Different packs give you earlier access to the game:</strong>
      </p>
      <table class="table" style="text-align:center">
        <thead>
          <tr>
            <th>Alpha 1</th>
            <th>Alpha 2</th>
            <th>Beta 1</th>
            <th>Beta 2</th>
            <th>Release</th>
          </tr>
        </thead>
      </table>
    </div>

    <div class="col-md-6 col-lg-4 mb-4 moving">
      <h6 class="mb-3 ">
        Is Soulcast an open world?
      </h6>
      <p>
        SoulCast is a game that is meant to be divided into levels, that is why <strong>we can't call SoulCast an open world for now</strong>. The game is set in Abaddon, a huge city subdivided into several districts.
        Each district is associated with a game mode that offers its own PvP or PvE activities.
        When players move from one district to another, they will migrate to one of those via matchmaking.
      </p>
    </div>

    <div class="col-md-6 col-lg-4 mb-4 moving">
      <h6 class="mb-3 ">
        Is this game a MMO ?
      </h6>
      <p>
        <strong>If you expect hundreds of players on the map, then no</strong>, Soulcast is not an MMO. We plan to have instances of 32 to 64 players, but we feel having more players in an instance does not serve our gameplay.  While we try to avoid other MMO-typical features like excessive  grinding and tedious farming, we kept all the other features that define an MMO: the social experience, for example, that allows players to play together without limitation and to compete with each other on the leaderboard. You will also find a consistent world
        that will contain PvP and PvE activities and a global market across instances.
      </p>
    </div>

    <div class="col-md-6 col-lg-4 mb-4 moving">
      <h6 class="mb-3 ">
        Do you plan to integrate gamepad controllers ?
      </h6>
      <p>
        <strong>Yes</strong>, we want to integrate gamepad controllers, however this is not in our immediate priority.
      </p>
    </div>

    <div class="col-md-6 col-lg-4 mb-4 moving">
      <h6 class="mb-3 ">
        Will SoulCast be playable on console ?
      </h6>
      <p>
        <strong>Currently this is not planned</strong>, however when we have integrated the gamepad controllers, we will reconsider the question.
      </p>
    </div>

    <div class="col-md-6 col-lg-4 mb-4 moving">
      <h6 class="mb-3 ">
        Do you plan to update to UE5 ?
      </h6>
      <p>
        <strong>Yes, we plan to update the game to UE5.</strong><br />
        We are currently testing to switch to UE5.
        Nevertheless we prioritize performances.
      </p>
    </div>

    <div class="col-md-6 col-lg-4 mb-4 moving">
      <h6 class="mb-3 ">
        Can I get refunds ?
      </h6>
      <p>
        Please check our <a href="./terms-and-conditions"><strong>Payment and Refunds Policy</strong></a> Please make sure to read the pack description and this FAQ carefully before you make a purchase.
      </p>
    </div>

  </div>

  <a href="https://www.paypal.com/donate/?hosted_button_id=FGA6KKC23GJ56" style="text-decoration:none">
    <div class="coffee" style="margin-bottom:50px">
      <p style="color: #ff972e;">Buy us a Coffee<br />(Donate)</p>
      <img style="width:200px; max-width:20vw;transform:translate(5%,0)" src="assets/assets_web_2/coffee.webp" />
    </div>
  </a>
</section>
<!--Section: FAQ-->
<app-footer></app-footer>
