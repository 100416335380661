import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    gsap.timeline({ scrollTrigger: { trigger: 'section', start: 'top center', toggleActions: "play none none none", onLeave: self => self.endAnimation(), onLeaveBack: self => self.endAnimation() } })
      .staggerFromTo(".moving", 0.5, { opacity: 0, x: 400 }, { opacity: 1, x: 0 }, 0.2)
  }

}
