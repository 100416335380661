
<!-- Modal -->
<div class="modal fade" id="avatarModal" tabindex="-1" aria-labelledby="avatarModalLabel">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title" id="ModalLabel">Choose your avatar</p>
      </div>
      <div class="modal-body">
        <table style="z-index: 999999999;">
          <tr>
            <td>
              <div class="profile-img-container">
                <a [ngClass]="{'profile-img-containerhover': selectedPicId == 0 }" [routerLink]="" (click)="SelectPic(0)"><img loading="lazy" src='assets/Characters/0.webp' alt="avatar" class="img-fluid p-1" style="width: 250px;" draggable="false"></a>
                <div [ngClass]="{'profile-img-none': selectedPicId !== 0}" class="profile-img-i-container">
                </div>
              </div>
            </td>
            <td>
              <div class="profile-img-container">
                <a [ngClass]="{'profile-img-containerhover': selectedPicId == 1 }" [routerLink]="" (click)="SelectPic(1)"><img loading="lazy" src='assets/Characters/1.webp' alt="avatar" class="img-fluid p-1" style="width: 250px;" draggable="false"></a>
                <div [ngClass]="{'profile-img-none': selectedPicId !== 1}" class="profile-img-i-container">
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="profile-img-container">
                <a [ngClass]="{'profile-img-containerhover': selectedPicId == 2 }" [routerLink]="" (click)="SelectPic(2)"><img loading="lazy" src='assets/Characters/2.webp' alt="avatar" class="img-fluid p-1" style="width: 250px;" draggable="false"></a>
                <div [ngClass]="{'profile-img-none': selectedPicId !== 2}" class="profile-img-i-container">
                </div>
              </div>
            </td>
            <td>
              <div class="profile-img-container">
                <a [ngClass]="{'profile-img-containerhover': selectedPicId == 3 }" [routerLink]="" (click)="SelectPic(3)"><img loading="lazy" src='assets/Characters/3.webp' alt="avatar" class="img-fluid p-1" style="width: 250px;" draggable="false"></a>
                <div [ngClass]="{'profile-img-none': selectedPicId !== 3}" class="profile-img-i-container">
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="modal-footer">
        <div class="underlineWhite m-auto" data-bs-dismiss="modal" aria-label="Close" style="cursor:pointer">
          <div class="underline">
            <svg height="66px" width="120px">
              <rect class="navicon" id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(15 33) rotate(-45)" />
              <text x="45px" fill="white" y="40px" style="font-size: 18px; left: 5vw; ">
                <tspan>Cancel</tspan>
              </text>
            </svg>
          </div>
        </div>

        <div class="underlineWhite m-auto" (click)="SaveAvatarPic()" data-bs-dismiss="modal" aria-label="Close" style="cursor:pointer">
          <div class="underline">
            <svg height="66px" width="180px">
              <rect class="navicon" id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(15 33) rotate(-45)" />
              <text x="45px" fill="white" y="40px" style="font-size: 18px; left: 5vw; ">
                <tspan>Save changes</tspan>
              </text>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex px-5 justify-content-center">
  <div class="col-lg-4 pt-5">
    <div class=" mb-4">
      <div class="-body text-center">
        <div class="profile-pic">
          <a [routerLink]="" data-bs-toggle="modal" data-bs-target="#avatarModal" style="cursor:pointer">
            <div class="edit">
              <p>EDIT</p>
            </div>
            <img loading="lazy" src='{{ "assets/Characters/" + this.user.icon_index + ".webp" }}' alt="avatar" class="rounded-circle img-fluid" style="width: 150px;" draggable="false">
          </a>
        </div>
        <p class="my-3"  style="text-transform: uppercase; font-size: calc(25px + (30 - 25) * ((100vw - 300px) / (2000 - 300))); ">{{user.username}}</p>
        <p class="text-muted mb-1">Last login : {{user.last_login | date :'short'}}</p>
        <p class="text-muted mb-1">joined : {{user.date_joined | date :'short'}}</p>
      </div>
    </div>
    <div class=" pb-4 mb-lg-0">
      <div style="text-align:center; margin: auto">
        <!-- STEAM -->
        <i class="fab fa-steam fa-lg" style="color: #171a21"></i>
        <div *ngIf="!user.is_steam_account_linked">
          <a [routerLink]="" (click)="LoginSteam()">
            <p style="margin:auto"> <i class="fas fa-link"></i> Link Steam</p>
          </a>
        </div>
        <p *ngIf="user.is_steam_account_linked" style="margin:auto">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg> Steam Account linked
        </p>
      </div>
      <div *ngIf="false" style="text-align:center; margin: auto">
        <!-- DISCORD -->
        <i class="fab fa-discord fa-lg" style="color: #5865F2 "></i>
        <div *ngIf="!user.is_discord_account_linked">
          <a href="#">
            <p style="margin:auto"> <i class="fas fa-link"></i> Link Discord</p>
          </a>
        </div>
        <p *ngIf="user.is_discord_account_linked" style="margin:auto">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg> Discord Account linked
        </p>
      </div>
      <div *ngIf="false" style="text-align:center; margin: auto">
        <!-- TWITCH -->
        <i class="fab fa-twitch fa-lg" style="color: #6441a5 "></i>
        <div *ngIf="!user.is_twitch_account_linked">
          <a href="#">
            <p style="margin:auto"> <i class="fas fa-link"></i> Link Twitch</p>
          </a>
        </div>
        <p *ngIf="user.is_twitch_account_linked" style="margin:auto">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
          </svg> Twitch Account linked
        </p>
      </div>
    </div>
  </div>

  <div class="col-lg-8 pt-5">
    <div class=" mb-4">
      <div class="-body">
        <div class="row">
          <div class="col-sm-3">
            <p class="mb-0">Full Name</p>
          </div>
          <div class="col-sm-9">
            <p *ngIf="user.first_name" class="text-muted mb-0">{{user.first_name}} {{user.last_name}}</p>
            <p *ngIf="!user.first_name" class="text-warning mb-0">You haven't set the account owner.</p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-3">
            <p class="mb-0">Email</p>
          </div>
          <div class="col-sm-9">
            <p *ngIf="user.email" class="text-muted mb-0">{{user.email}}</p>
            <p *ngIf="!user.email" class="text-warning mb-0">You haven't set your email.</p>
          </div>
        </div>
      </div>
    </div>

    <div class=" mb-4">
      <div class="-body">
        <div class="row">
          <div class="col-sm-4" style="max-width:350px">
            <circle-progress [percent]="progressPRC" ></circle-progress>
          </div>
          <div class="col-sm-8 m-auto">
            <!-- Warning -->
            <p class="text-danger mb-0" *ngIf="!this.user.first_name"><i class="fa-solid fa-triangle-exclamation p-1"></i>Update account owner</p>
            <p class="text-danger mb-0" *ngIf="!this.user.has_password"><i class="fa-solid fa-triangle-exclamation p-1"></i>Set account password</p>
            <p class="text-danger mb-0" *ngIf="!this.user.email"><i class="fa-solid fa-triangle-exclamation p-1"></i>Update your Email address</p>
            <p *ngIf="!user.has_verified_email && user.email" class="text-danger mb-0">Please confirm your email address.</p>
            <p class="text-danger mb-0" *ngIf="!this.user.has_subscribed_to_newsletter || !this.user.email"><i class="fa-solid fa-triangle-exclamation p-1"></i>subscribe to newsletters</p>
            <!-- Success -->
            <p class="text-success mb-2" style="text-transform: uppercase;" *ngIf="progressPRC == 100">
              Your account is in optimal condition
            </p>
            <p class="text-success mb-0" *ngIf="this.user.first_name">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2-circle m-1" viewBox="0 0 16 16">
                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
              </svg>Account owner is set
            </p>
            <p class="text-success mb-0" *ngIf="this.user.has_password">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2-circle m-1" viewBox="0 0 16 16">
                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
              </svg>Your account has a password
            </p>
            <p class="text-success mb-0" *ngIf="this.user.email">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2-circle m-1" viewBox="0 0 16 16">
                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
              </svg>Your account email is set
            </p>
            <p class="text-success mb-0" *ngIf="this.user.has_subscribed_to_newsletter && this.user.email">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2-circle m-1" viewBox="0 0 16 16">
                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
              </svg>You have subscribed to Newsletter
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
