import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import Player from '@vimeo/player';

enum SpellOpen {
  FireOpen,
  AirOpen,
  EarthOpen,
  WaterOpen
}
enum SpellFocus {
  FireFocus,
  AirFocus,
  EarthFocus,
  WaterFocus,
  none
}

@Component({
  selector: 'app-magic-elements',
  templateUrl: './magic-elements.component.html',
  styleUrls: ['./magic-elements.component.css']
})
export class MagicElementsComponent implements OnInit, AfterViewInit {
  @ViewChild('playerSpellContainer') playerContainer: ElementRef;

  @ViewChild('playerMagicFireMobile') playerContainerMagicFireMobile: ElementRef;
  @ViewChild('playerMagicAirMobile') playerContainerMagicAirMobile: ElementRef;
  @ViewChild('playerMagicWaterMobile') playerContainerMagicWaterMobile: ElementRef;
  @ViewChild('playerMagicEarthBowMobile') playerContainerMagicEarthMobile: ElementRef;

  FireVideoId = 775752845
  AirVideoId = 775781918
  WaterVideoId = 775804368
  EarthVideoId = 775753372

  isSpellFocus: SpellFocus = SpellFocus.none;
  isSpellOpen: SpellOpen = SpellOpen.FireOpen;
  PlayHoverAnim;
  numEffect = 25;
  videoPlayer: Player;

  constructor() { }

  ngOnInit(): void {
    this.isSpellOpen = SpellOpen.FireOpen;
  }

  ngAfterViewInit(): void {

    this.PlayHoverAnim = gsap.timeline().fromTo('.FireRelic', 0.4, { autoAlpha: 0 }, { autoAlpha: 1 });
    
    gsap.timeline({ repeat: -1, delay: 0.2 })
      .to(".ElementsArt",
        {
          y: "-=4",
          duration: 2,
          ease: Sine.easeInOut
        }
      )
      .to(".ElementsArt",
        {
          y: "+=4",
          duration: 2,
          ease: Sine.easeInOut
        }
      );
    gsap.timeline({ repeat: -1, delay: 0.2 })
      .to(".ElementsArt",
        {
          opacity: "-=0.2",
          duration: 1.4,
          ease: Sine.easeInOut
        }
      )
      .to(".ElementsArt",
        {
          opacity: "+=0.2",
          duration: 1,
          ease: Sine.easeInOut
        }
      );


    const options = {
      id: 775752845,
      height: document.getElementById('SoulCastSpellVideo')?.clientHeight,
      loop: true,
      background: true,
      controls: false,
      autoplay: false,
      autopause: false
    };

    let setupIntersectionObserver = function (target) {
      target.pause();
      var optionsIntersectionObserver = { threshold: 0.1 }
      const callback = function (entries) {
        if (entries[0].isIntersecting) {
          target.play();
        } else {
          target.pause();
        }
      }
      var observer = new IntersectionObserver(callback, optionsIntersectionObserver);
      observer.observe(target.element);
    };

    if (this.IsMobile()) {
      let player = new Player(this.playerContainerMagicFireMobile.nativeElement, options);
      player.ready().then(setupIntersectionObserver(player));

      options.id = this.AirVideoId;
      player = new Player(this.playerContainerMagicAirMobile.nativeElement, options);
      player.ready().then(setupIntersectionObserver(player));

      options.id = this.WaterVideoId;
      player = new Player(this.playerContainerMagicWaterMobile.nativeElement, options);
      player.ready().then(setupIntersectionObserver(player));

      options.id = this.EarthVideoId;
      player = new Player(this.playerContainerMagicEarthMobile.nativeElement, options);
      player.ready().then(setupIntersectionObserver(player));
      return;
    }

    this.StartEffect();

    
    this.videoPlayer = new Player(this.playerContainer.nativeElement, options);

    this.videoPlayer.ready().then(setupIntersectionObserver(this));
  }

  StartEffect() {
    
    let anims: TweenLite[] = [];

    const random = (min, max) => {
      return Math.floor(Math.random() * (max - min) + min);
    }

    var container = document.getElementById('container');
    let clientWidth = document.getElementById('container')?.clientWidth;
    let posX = clientWidth! - random(100, 0);
    let clientHeight = document.getElementById('container')?.clientHeight;
    let posY = clientHeight! - random(100, 0);
    
    for (var i = 0; i < this.numEffect; i++) {
      let sparcks = document.createElementNS("http://www.w3.org/2000/svg", "circle");
      sparcks.setAttribute('class', 'firefly');
      sparcks.setAttributeNS(null, 'r', "" + random(2,4));
      sparcks.setAttributeNS(null, 'style', 'fill: #DC8E35; fill-opacity: 1; stroke-width: 1px;');
      sparcks.setAttributeNS(null, "cx", posX + "px");
      sparcks.setAttributeNS(null, "cy", posY + "px");
      sparcks.setAttributeNS(null, "filter", "saturate(0%) brightness(0%) contrast(0%)");
      container!.appendChild(sparcks);
    }

    var fireflies = document.querySelectorAll('.firefly');

    for (var i = 0; i < this.numEffect; i++) {

      let sparcksSize = random(4, 7);
      let upFactor = 1 / sparcksSize * 4;
      let ix = random(-clientWidth! * 0.22, clientWidth! * 0.10);
      let iy = random(-clientWidth! * 0.4, clientWidth! * 0.10);
      let fx = random(ix - clientWidth! / 2 - 50, ix);      
      let fy = random(iy - clientHeight! / 2, iy);
      let dirx = fx - ix;
      let diry = fy - iy;
      let size = Math.sqrt(dirx * dirx + diry * diry);

      fireflies[i].setAttributeNS(null, "transform", "rotate(" + this.dotProduct([dirx, diry], [0, -1]) / size * 90.0 + ") scale(" + sparcksSize +",1)");

      let duration = random(10, 20);
      let delay = random(0, 3);
      anims.push(gsap.fromTo(fireflies[i], {
        x: ix,
        y: iy,
        filter: "saturate(100%) brightness(0%) contrast(100%)",
      }, {
        x: fx,
        y: fy,
        scaleX: "-=3",
        filter:"saturate(1352%) brightness(119%) contrast(119%)",
        duration: duration,
        ease: "rough ({template: none.out, strength: 1, points: 20, taper: 'none', randomize: true, clamp:false})",
        repeat: -1,
        delay: delay
      }));
      anims.push(gsap.fromTo(fireflies[i], {
        opacity: 0,
      }, {
        opacity: 0.6,
        duration: duration / 4,
        repeat: -1,
        yoyo: true,
        delay: delay
      }));
    };

    var options = {
      threshold: 0.1  //10% height is visible, observer should be triggered
    }

    const callback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          anims.forEach(anim => anim.play())
        } else {
          anims.forEach(anim => anim.pause(0))
        }
      });
    }
    var observer = new IntersectionObserver(callback, options);
    observer.observe(container!)
  }

  dotProduct(a, b) {
  const result = a.reduce((acc, cur, index) => {
    acc += (cur * b[index]);
    return acc;
  }, 0);
  return result;
  }

  // click on spell

  ClickSpellFire() {
    if (this.isSpellOpen == SpellOpen.FireOpen)
      return;
    if (this.PlayHoverAnim)
      this.PlayHoverAnim.reverse()

    this.PlayHoverAnim = gsap.timeline().fromTo('.FireRelic', 0.4, { autoAlpha: 0 }, { autoAlpha: 1 });
    this.ChangeEffectColor('fill:#DC8E35');
    gsap.fromTo('.RectFB', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });

    this.ChangeVideoURL(this.FireVideoId);
    this.OnClickClose();
    this.isSpellOpen = SpellOpen.FireOpen;
    this.OnClickOpen();
  }

  ClickSpellAir() {
    if (this.isSpellOpen == SpellOpen.AirOpen)
      return;
    if (this.PlayHoverAnim)
      this.PlayHoverAnim.reverse();

    this.PlayHoverAnim = gsap.timeline().fromTo('.AirRelic', 0.4, { autoAlpha: 0 }, { autoAlpha: 1 });
    this.ChangeEffectColor('fill:#fffed5');
    gsap.fromTo('.RectFW', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });

    this.ChangeVideoURL(this.AirVideoId);
    this.OnClickClose();
    this.isSpellOpen = SpellOpen.AirOpen;
    this.OnClickOpen();
  }

  ClickSpellWater() {
    if (this.isSpellOpen == SpellOpen.WaterOpen)
      return;
    if (this.PlayHoverAnim)
      this.PlayHoverAnim.reverse();

    this.PlayHoverAnim = gsap.timeline().fromTo('.WaterRelic', 0.4, { autoAlpha: 0 }, { autoAlpha: 1 });
    this.ChangeEffectColor('fill:#3cc9ca');
    gsap.fromTo('.RectFWa', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });

    this.ChangeVideoURL(this.WaterVideoId);
    this.OnClickClose();
    this.isSpellOpen = SpellOpen.WaterOpen;
    this.OnClickOpen();
  }

  ClickSpellEarth() {
    if (this.isSpellOpen == SpellOpen.EarthOpen)
      return;
    if (this.PlayHoverAnim)
      this.PlayHoverAnim.reverse();

    this.PlayHoverAnim = gsap.timeline().fromTo('.EarthRelic', 0.4, { autoAlpha: 0 }, { autoAlpha: 1 });
    this.ChangeEffectColor('fill:#347437');
    gsap.fromTo('.RectFE', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });

    this.ChangeVideoURL(this.EarthVideoId);
    this.OnClickClose();
    this.isSpellOpen = SpellOpen.EarthOpen;
    this.OnClickOpen();
  }

  ChangeVideoURL(value: number) {
    this.videoPlayer.loadVideo(value).then(function (this) {
      this.videoPlayer.play();
    })
  }
  ChangeEffectColor(value: string) {
    var fireflies = document.querySelectorAll('.firefly');
    for (var i = 0; i < this.numEffect; i++) {
      fireflies[i].setAttribute('style', value)
    }
  }

  OnClickClose()
  {
    if (this.isSpellOpen == SpellOpen.EarthOpen) {
      gsap.timeline().to(".EarthText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFE', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
    if (this.isSpellOpen == SpellOpen.WaterOpen) {
      gsap.timeline().to(".WaterText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFWa', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
    if (this.isSpellOpen == SpellOpen.AirOpen) {
      gsap.timeline().to(".AirText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFW', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
    if (this.isSpellOpen == SpellOpen.FireOpen) {
      gsap.timeline().to(".FireText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFB', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
  }

  OnClickOpen() {
    if (this.isSpellOpen == SpellOpen.EarthOpen)
      gsap.timeline().to(".EarthText", { autoAlpha: 1, duration: 0.5 });
    if (this.isSpellOpen == SpellOpen.WaterOpen)
      gsap.timeline().to(".WaterText", { autoAlpha: 1, duration: 0.5 });
    if (this.isSpellOpen == SpellOpen.AirOpen)
      gsap.timeline().to(".AirText", { autoAlpha: 1, duration: 0.5 });
    if (this.isSpellOpen == SpellOpen.FireOpen)
      gsap.timeline().to(".FireText", { autoAlpha: 1, duration: 0.5 });
  }

  AirElementMouseEnter() {
    this.isSpellFocus = SpellFocus.AirFocus;
    gsap.fromTo('.RectFW', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  AirElementMouseLeave() {
    if (this.IsMobile()) return;
    this.isSpellFocus = SpellFocus.none;
    if (this.isSpellOpen != SpellOpen.AirOpen)
      gsap.fromTo('.RectFW', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  FireElementMouseEnter() {
    this.isSpellFocus = SpellFocus.FireFocus;
    gsap.fromTo('.RectFB', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  FireElementMouseLeave() {
    if (this.IsMobile()) return;
    this.isSpellFocus = SpellFocus.none;
    if (this.isSpellOpen != SpellOpen.FireOpen)
      gsap.fromTo('.RectFB', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  WaterElementMouseEnter() {
    this.isSpellFocus = SpellFocus.WaterFocus;
    gsap.fromTo('.RectFWa', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  WaterElementMouseLeave() {
    if (this.IsMobile()) return;
    this.isSpellFocus = SpellFocus.none;
    if (this.isSpellOpen != SpellOpen.WaterOpen)
      gsap.fromTo('.RectFWa', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  EarthElementMouseEnter() {
    this.isSpellFocus = SpellFocus.EarthFocus;
    gsap.fromTo('.RectFE', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  EarthElementMouseLeave() {
    if (this.IsMobile()) return;
    this.isSpellFocus = SpellFocus.none;
    if (this.isSpellOpen != SpellOpen.EarthOpen)
      gsap.fromTo('.RectFE', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }

  IsMobile() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 1200) {
      return true;
    } else {
      return false;
    }
  }

}
