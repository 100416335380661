import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from 'src/app/services/account.service';
import { PaymentsService } from '../services/payments.service';

@Component({
  selector: 'app-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
  styleUrls: ['./terms-and-conditions-modal.component.css']
})
export class TermsAndConditionsModalComponent {

  constructor(public activeModal: NgbActiveModal, private accountService: AccountService, private paymentsService: PaymentsService) { }

  itemId: string = "";

  ngOnInit(): void { }

  SetItemId(item) {
    this.itemId = item;
  }

  AcceptTermsAndConditions() {
    this.accountService.AcceptTermsAndConditions().subscribe(
      () => {
        if (this.itemId == "")
          return;
        this.paymentsService.checkout(this.itemId).subscribe(
          () => {
          },
          (error) => {
            console.log(error)
          }
        )
      },
      (error) => {
        console.log(error)
      }
    )
  }
}
