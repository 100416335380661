import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { AccountComponent } from './account/account.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { AuthGuardService } from './auth-guard.service';
import { ShopComponent } from './shop/shop.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { FAQComponent } from './faq/faq.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ThanksComponent } from './thanks/thanks.component';
import { RedirectComponent } from './redirect/redirect.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  { path: '', component: FrontPageComponent },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuardService] },
  { path: 'register', component: RegisterComponent },
  { path: 'shop', component:ShopComponent },
  { path: 'shop/:id', component:ProductDetailsComponent },
  { path: 'faq', component: FAQComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'thanks', component: ThanksComponent },
  { path: 'password-reset-confirm/:id/:id', component: RedirectComponent },
  { path: 'email-change/confirm/:id', component: RedirectComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
