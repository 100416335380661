<div>
  <app-hud [displaySocialLinks]="false"></app-hud>
  <div style="min-height: 100vh; background-image: url(assets/assets_web_2/bg-dashboard.webp); background-position: bottom; position: relative; background-repeat: no-repeat; background-size: cover; ">
    <div class="pt-5 pt-lg-2" style="text-align: center;">
      <h1 style="margin-top: 10vh;">{{product.name}}</h1>
    </div>
    <div class="d-flex justify-content-center">
      <div style="max-width:min(1400px,calc(100vw - 1rem))">
        <button routerLink="/shop" style="background-color: transparent; border: none;">
          <div class="d-flex flex-row align-items-center">
            <i class="fa-solid fa-angle-left fa-lg"></i>
            <span style="color: white; margin-left: 5px;">Back to store</span>
          </div>
        </button>
        <div class="row mb-2">
          <div class="col">
            <app-product [product]=product></app-product>
            <div class="d-flex justify-content-center">
              <span class="align-text-bottom price" style="margin-bottom: 1px; padding-right: 0;">€</span>
              <span class="text-start price" style="padding-left: 6px; margin-bottom: 1px;">{{getReducedPrice().toFixed(2)}}</span>
            </div>
          </div>
          <div class="col" style="padding-left:35px">
            <div *ngFor="let desc of description" #moving>
              <svg height="30px" width="30px" class="rect">
                <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(5 10) rotate(-45)" />
              </svg>
              <p class="desc">{{desc}}</p>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <div class="underlineWhite" style="width: 200px; cursor: pointer;">
            <div class="underline">
              <a class="buyButton" (click)="onBuyEvent(product)">
                <svg height="66px" width="200px">
                  <text x="55px" fill="white" y="40px" style="font-size: 50px; left: 5vw; ">
                    <tspan>BUY</tspan>
                  </text>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div  *ngIf="ShowKey('Beta')" class="d-flex justify-content-center" style="position: relative; filter: drop-shadow(0 2px 20px #ff0000);">
          <p style="position:absolute;top:0;left:50%;transform:translate(-50%,50%)">BETA PASS</p>
          <img class="Key" style="width:50px;height:auto;margin-left:auto;margin-right:auto;padding-top:50px" src="assets/assets_web_2/BetaKey.webp" />
        </div>
        <div *ngIf="ShowKey('Alpha')" class="d-flex justify-content-center" style="position: relative; filter: drop-shadow(0 2px 20px #ff0000);">
          <p style="position:absolute;top:0;left:50%;transform:translate(-50%,50%)">ALPHA PASS</p>
          <img class="Key" style="width:50px;height:auto;margin-left:auto;margin-right:auto;padding-top:50px"  src="assets/assets_web_2/AlphaKey.webp" />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center ">
      <p class="px-3 px-lg-0 py-5" style="text-align:center">SoulCast is a game currently in <strong>development phase.</strong><br /> Support us by buying one of our supporter pack.<br /> Gain an exclusive access to the game, earn exclusive cosmetics.<br />Become a SoulCaster !</p>
    </div>
    <app-footer></app-footer>
  </div>
</div>

