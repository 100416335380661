<!--Section: FAQ-->
<app-hud></app-hud>
<section class="m-auto" style="padding-top: 75px; background-image: url(assets/assets_web_2/bg-dashboard.webp); background-attachment: fixed; background-position: bottom; position: relative; background-repeat: no-repeat; background-size: cover;height:100vh">

  <div style="position: absolute; top: 50%; left: 50%;transform:translate(-50%,0)">
    <h1 id="title">Thank you for your support !</h1>
  </div>
 
  <img *ngIf="showCharacters" style="width: 600px; max-width: 100vw; height: auto; position: fixed; top: 300px; right: -50px; z-index: 0" src="assets/assets_web_2/TheDuchess.webp" alt="Characters" />
  <img *ngIf="showCharacters" style="width: 600px; max-width: 100vw; height: auto; position: fixed; top: 300px; left: -120px; z-index: 0" src="assets/assets_web_2/Herald.webp" alt="Characters" />
</section>
<!--Section: FAQ-->
<app-footer></app-footer>


