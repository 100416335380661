<div (click)="OpenInfo()" (mouseover)="over($event)" (mouseout)="out($event)" (mousemove)="move($event)" #productContainer class="productContainer m-auto" [ngClass]="{ 'productClick' : showDetail}" style="box-sizing: border-box;">
  <!-- (mousemove)="move($event)" (mouseout)="out($event)" -->
  <!-- PROMO VALUE BOX-->
  <div *ngIf="product.reduction !== 0" class="ribbon ribbon-top-right"><span>{{product.reduction}}% OFF</span></div>
  <!-- HEADER IMAGE-->
  <div class="imgContainer" [ngClass]="{ 'imgContainerLarge' : !showDetail }">
    <img #header_img class="header_img" [ngClass]="{ 'header_imgLarge' : !showDetail }" src={{productImagePath}}>
    <img class="borderBlack" src="assets/border3.webp" alt="border">
  </div>
  <!--  BODY-->
  <!-- Next play session -->

  <div *ngIf="false">
    <div class="timer px-2" id="timer" *ngIf="(timeLeft$ | async) as time; else loading" style="font-size: medium; pointer-events: none;height:60px">
      <div *ngIf="time.monthToDday > 0">
        <p class="m-0"> Next play session in : </p>
        <div style="font-style: italic; color: #FFC837; ">
          <span id="days"> {{time.monthToDday}} </span>Month<span *ngIf="time.monthToDday > 1">s</span>
          <span id="days"> {{time.daysToDday}} </span>Day<span *ngIf="time.daysToDday > 1">s</span>
          <span id="hours"> {{time.hoursToDday}} </span>Hr<span *ngIf="time.hoursToDday > 1">s</span>
          <span id="minutes"> {{time.minutesToDday}} </span>Min
          <span id="seconds"> {{time.secondsToDday}} </span>S
        </div>
      </div>
      <div class="pt-2 animPlay" *ngIf="timeDiff < 0" style="font-size: larger;">
        <strong style="color:green">PLAY NOW</strong>
      </div>
    </div>
    <ng-template #loading class="px-2" style="height: 60px">
      <div class="spinner-border" style="color: #FFC837; pointer-events: none;" role="status"></div>
    </ng-template>
  </div>

  <div style="width: 100%; height: fit-content; pointer-events: none;">
    <!-- TITLE-->
    <div style="padding-top: 0px; height:100px; position:relative">
      <p class="product_name px-2">{{product.name}}</p>
    </div>

    <div *ngIf="showDetail">
      <!-- PRICE BOX-->
      <div class="d-flex d-flex-row">
        <!-- ORIGINAL PRICE -->
        <div [ngClass]="{ 'price-text-box' : product.reduction === 0, 'price-text-box-crossed' : product.reduction !== 0}" class="d-flex">
          <div class="d-flex align-items-center">
            <span class="align-text-bottom price px-2" style="margin-bottom: 1px; padding-right: 0;">€</span>
          </div>
          <span class="text-start price" style="padding-left: 1px; margin-bottom: 1px;">{{product.price.toFixed(2)}}</span>
        </div>
        <!-- REDUCED PRICE -->
        <div *ngIf="product.reduction !== 0" class="d-flex price-text-box" style="padding-left: 10px;">
          <div class="d-flex align-items-center">
            <span class="align-text-bottom price" style="margin-bottom: 1px; padding-right: 0;">€</span>
          </div>
          <span class="text-start price" style="padding-left: 1px; margin-bottom: 1px;">{{getReducedPrice(product).toFixed(2)}}</span>
        </div>
      </div>

      <div class="alert-success rounded m-2">
        {{product.additional_text}}
      </div>
    </div>
  </div>
  <!--  FOOTER-->
  <div *ngIf="showDetail">
    <div class="d-flex d-flex-row">
      <div class="d-flex align-items-center justify-content-center">
        <div class="underlineWhite" style="margin-left:15px">
          <div class="underline" >
            <a [routerLink]="'./'+product.id" >
              <svg height="66px" width="150px">
                <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(15 33) rotate(-45)" />
                <text x="45px" fill="white" y="40px" style="font-size: 18px; left: 5vw; ">
                  <tspan>MORE INFO</tspan>
                </text>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="underlineWhite" style="margin-left: 15px">
        <div class="underline">
          <a class="buyButton" (click)="$event.stopPropagation();onBuyEvent(product)">
            <svg height="66px" width="150px">
              <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(25 33) rotate(-45)" />
              <text x="55px" fill="white" y="40px" style="font-size: 30px; left: 5vw; ">
                <tspan>BUY</tspan>
              </text>
            </svg>
          </a>
      </div>
      </div>
    </div>
  </div>
</div>
