<!--Section: FAQ-->
<app-hud></app-hud>
<section class="m-auto" style="padding-top: 75px; background-image: url(assets/assets_web_2/bg-dashboard.webp); background-attachment: fixed; background-position: bottom; position: relative; background-repeat: no-repeat; background-size: cover;">

  <div class="text-center mb-4 pb-2 pt-5 pt-lg-2">
    <h1 class="my-auto" id="title">About-Us</h1>
  </div>
  <div class="" style="width: 700px; max-width:90vw; margin-left: auto; margin-right: auto;z-index:200">
    <img style="width: 700px; max-width: 90vw; height: auto; margin-left: auto; margin-right: auto;" src="assets/OneShotLogo_OneLine.png" alt="OnshotStudioLogo">
    <p class="smallTitle">About us:</p>
    <p>
      <br />
      OneShot Studios was founded in 2021, but our first game, SoulCast, is a project that has been in the making for more than six years.<br />
      <br />
      Our founders, Ivann Rudrauf and François Callet, both share a passion for RPG and competitive multiplayer games.<br />
      Their original vision for SoulCast was to combine both genres and add in a fun and responsive movement system.<br />
      Over the years, we have iterated and improved the game multiple times to make it truly unique and innovative.<br />
      <br />
      In 2021, we were joined by Clement Lubineau as the artist and Martin Engelhaus as the game designer.<br />
      Their contributions have helped to shape the game's vision and bring it closer to our original vision.<br />
      With SoulCast, we aim to create a game that offers a new and exciting experience for players who love both RPG and multiplayer games.<br />
      The game will feature a captivating story, richly detailed characters, and an immersive setting.<br />
      <br />
      After years of hard work, we are excited to announce that SoulCast will soon be available on Kickstarter, and we hope you will give it a try.
    </p>

    <a href="https://www.paypal.com/donate/?hosted_button_id=FGA6KKC23GJ56" style="text-decoration:none">
      <div class="coffee">
        <p style="color: #ff972e;">Buy us a Coffee<br />(Donate)</p>
        <img style="width:200px; max-width:20vw;transform:translate(5%,0)" src="assets/assets_web_2/coffee.webp" />
      </div>
    </a>
    
    <p class="smallTitle">Our vision:</p>
    <p>
      <br />
      At OneShot Studios, we don't just want to create another competitive multiplayer game.<br />
      Our long-term vision is to build multiple game modes, including an adventure mode that will include a semi-open world.<br />
      Imagine spawning into a world where you can explore your surroundings, take on dynamic missions, and interact with the environment.<br />
      But be warned, as other players can interfere with your missions, adding a thrilling level of unpredictability to the gameplay.<br />
      <br />
      Moreover, we will implement an intricate legality-illegality system that will hold players accountable for their actions.<br />
      You will have to face the consequences of your choices, adding depth and realism to the game.<br />
      <br />
      Our world will be packed with unique locations, each with their own set of challenges, secrets, and treasures for players to discover.<br />
      We believe that this immersive world will provide an unforgettable gaming experience that you won't find anywhere else.
    </p>
    <div *ngIf="false" class="row flex items-center justify-center">
      <div class="Team col">
        <img src="assets/Characters/2.png" class="TeamPic">
        <p>NAME</p>
        <p>Quality description</p>
      </div>
      <div class="Team col">
        <img src="assets/Characters/2.png" class="TeamPic">
        <p>NAME</p>
        <p>Quality description</p>
      </div>
      <div class="Team col">
        <img src="assets/Characters/2.png" class="TeamPic">
        <p>NAME</p>
        <p>Quality description</p>
      </div>
      <div class="Team col">
        <img src="assets/Characters/2.png" class="TeamPic">
        <p>NAME</p>
        <p>Quality description</p>
      </div>
    </div>

  </div>
  <img *ngIf="showCharacters" style="width: 600px; max-width: 100vw; height: auto; position: fixed; top: 300px; right: -50px; z-index: 0" src="assets/assets_web_2/TheDuchess.webp" alt="Characters" />
  <img *ngIf="showCharacters" style="width: 600px; max-width: 100vw; height: auto; position: fixed; top: 300px; left: -120px; z-index: 0" src="assets/assets_web_2/Herald.webp" alt="Characters" />
</section>
<!--Section: FAQ-->
<app-footer></app-footer>

