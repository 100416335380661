import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { User } from 'src/app/login/login.component';
import { Subject, Observable, Subscription, BehaviorSubject, tap } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

export enum SupportSubject {
  FEEDBACK = 'F',
  ORDER_ISSUE = 'O',
  EMAIL_ISSUE = 'E',
  BUG = 'B',
  FEATURE_REQUEST = 'R',
  PROFESSIONAL_CONTACT = 'P',
  OTHER = 'A',
}

export const SupportSubjectToLabelMapping: Record<SupportSubject, string> = {
  [SupportSubject.FEEDBACK]: "Feedback",
  [SupportSubject.ORDER_ISSUE]: "Order Issue",
  [SupportSubject.EMAIL_ISSUE]: "Email Issue",
  [SupportSubject.BUG]: "Bug",
  [SupportSubject.FEATURE_REQUEST]: "Feature Request",
  [SupportSubject.PROFESSIONAL_CONTACT]: "Professional Contact",
  [SupportSubject.OTHER]: 'Other'
};

@Injectable({
  providedIn: 'root'
})
export class AccountService implements OnInit, OnDestroy {
  private userSubject: Subject<User>;
  public userObservable: Observable<User>;
  user: User;
  userSub: Subscription;

  // timer limit checkAccountStatus() req
  timerRef;
  canCheckAccountStatus: boolean = false;

  constructor(private http: HttpClient, private cookieService: CookieService, private router: Router) {
    this.user = JSON.parse(localStorage.getItem('user')!);
    this.userSubject = new BehaviorSubject<User>(this.user);
    this.userObservable = this.userSubject.asObservable();
    this.canCheckAccountStatus = true;
    this.userSub = this.userObservable.subscribe(
      (next: User) => {
        this.user = next;
      },
      (error) => {
        console.log(error);
      }
    )
    if (this.user !== null) {
      this.canCheckAccountStatus = true;
      this.checkAccountStatus();
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    clearInterval(this.timerRef);
  }

  public setUser(newUser: User) {
    if (newUser.isLogin) {
      this.startTimer();
    }
    this.userSubject.next(newUser);
    localStorage.setItem('user', JSON.stringify(newUser));
  }

  SetIsLogin(isLogin: boolean) {
    this.user.isLogin = isLogin;
  }

  // Http functions

  login(User: string, Pass: string): Observable<any> {
    const headers = new HttpHeaders()
    const body = {
      "username": User,
      "password": Pass
    };
    return this.http.post<any>(environment.apiURL + '/services/auth', body, { headers, observe: 'response', withCredentials: true })
      .pipe(tap(response => {
        if (response.status == 200) {
          let jsonObj: User = JSON.parse(JSON.stringify(response.body))
          jsonObj.isLogin = true;
          this.setUser(jsonObj);
        }
        else {
          let jsonObj: User = JSON.parse(JSON.stringify(response.body))
          jsonObj.isLogin = false;
          this.setUser(jsonObj);
        }
      }));
  }

  logout(): Observable<any> {
    const headers = new HttpHeaders()
    const body = {}
    return this.http.post<any>(environment.apiURL + '/services/logout', body, { headers, observe: 'response', withCredentials: true })
      .pipe(tap(response => {
        if (response.status == 200) {
          this.canCheckAccountStatus = true;
          localStorage.removeItem('user');
          this.cookieService.deleteAll();
          this.user.isLogin = false;
          this.userSubject.next(this.user);
        }
      }));
  }

  AcceptTermsAndConditions(): Observable<any> {
    const headers = new HttpHeaders()
    const body = {}
    return this.http.post<any>(environment.apiURL + '/services/accept-terms-and-conditions', body, { headers, observe: 'response', withCredentials: true })
      .pipe(tap(response => {
        if (response.status == 200) {
          this.user.has_accepted_termsAndConditions = true;
          this.setUser(this.user);
        }
      }));
  }

  checkAccountStatus() {
    if (this.canCheckAccountStatus) {
      this.startTimer();
    }
    else return;

    const headers = new HttpHeaders()
    this.http.get(environment.apiURL + '/services/get-user-infos', { headers, observe: 'response', withCredentials: true })
      .subscribe(
        (response) => {
          let jsonObj: User = JSON.parse(JSON.stringify(response.body)) // update user
          this.user = jsonObj;
          this.user.isLogin = true;
          this.setUser(this.user);
        },
        (error) => {//Error callback
          localStorage.removeItem('user');
          this.cookieService.deleteAll();
          this.user.isLogin = false;
          this.userSubject.next(this.user);
          this.router.navigate(['/'])
        })
  }

  // http req account info update

  updateAccountOwner(firstname: string, lastName: string): Observable<any> {
    const headers = new HttpHeaders()
    const body = {
      "first_name": firstname,
      "last_name": lastName
    };
    return this.http.post(environment.apiURL + '/services/update-account-owner-infos', body, { headers, observe: 'response', withCredentials: true })
      .pipe(tap(response => {
        if (response.status == 200) {
          this.user.first_name = firstname;
          this.user.last_name = lastName;
          this.setUser(this.user);
        }
      }));
  }

  ClearUnverifiedEmail(): Observable<any> {
    const headers = new HttpHeaders()
    const body = {};
    return this.http.post(environment.apiURL + '/services/email/ClearUnverifiedEmail', body, { headers, observe: 'response', withCredentials: true })
      .pipe(tap(response => {
        if (response.status == 200) {
          this.user.has_verified_email = false;
          this.user.email = "";
          this.setUser(this.user);
        }
      }));
  }

  updatePassword(lastPassword: string, password: string): Observable<any> {
    const headers = new HttpHeaders()
    const body = {
      "last_password": lastPassword,
      "new_password": password
    };
    return this.http.post(environment.apiURL + '/services/update-password', body, { headers, observe: 'response', withCredentials: true })
      .pipe(tap(response => {
        if (response.status == 200) {
          let jsonObj: User = JSON.parse(JSON.stringify(response.body))
          jsonObj.isLogin = true;
          this.setUser(jsonObj);
        }
      }));
  }

  setPassword(password: string): Observable<any> {
    const headers = new HttpHeaders()
    const body = {
      "new_password": password
    };
    return this.http.post(environment.apiURL + '/services/set-password', body, { headers, observe: 'response', withCredentials: true })
      .pipe(tap(response => {
        if (response.status == 200) {
          let jsonObj: User = JSON.parse(JSON.stringify(response.body))
          jsonObj.isLogin = true;
          this.setUser(jsonObj);
        }
      }));
  }

  sendSupportMessage(Name: string, Email: string, subject: SupportSubject, message: string, copy: boolean): Observable<any> {
    const headers = new HttpHeaders()
    const body = {
      "message": message,
      "copy": copy,
      "subject": subject
    };
    return this.http.post<any>(environment.apiURL + '/services/send-support-message', body, { headers, observe: 'response', withCredentials: true })
      .pipe(tap(response => {
        if (response.status == 200) {

        }
        else {

        }
      }));
  }

  subscribeAccountToNewsLetter(value: boolean): Observable<any> {
    const headers = new HttpHeaders()
    const body = {"subscribeToNewsletter": value};
    return this.http.post(environment.apiURL + '/services/suscribe-account-to-newsletter', body, { headers, observe: 'response', withCredentials: true })
      .pipe(tap(response => {
        if (response.status == 200) {
          this.user.has_subscribed_to_newsletter = value;
          this.setUser(this.user);
        }
      }));
  }

  SetEmail(value: string): Observable<any> {
    const headers = new HttpHeaders()
    const body = { "email": value };
    return this.http.post(environment.apiURL + '/services/email/set-email', body, { headers, observe: 'response', withCredentials: true })
      .pipe(tap(response => {
        if (response.status == 200) {
          this.user.email = value;
          this.setUser(this.user);
        }
      }));
  }

  SendMailActivation(): Observable<any> {
    const headers = new HttpHeaders()
    const body = { };
    return this.http.post(environment.apiURL + '/services/email/send-mail-activation', body, { headers, observe: 'response', withCredentials: true })
      .pipe(tap(response => {}));
  }

  changeAvatarPic(value: number): Observable<any> {
    const headers = new HttpHeaders()
    const body = { "icon_index": value };
    return this.http.post(environment.apiURL + '/services/update-avatar', body, { headers, observe: 'response', withCredentials: true })
      .pipe(tap(response => {
        if (response.status == 200) {
          this.user.icon_index = value;
          this.setUser(this.user);
        }
      }));
  }

  subscribeMailToNewsLetter(email: string): Observable<any> {
    const headers = new HttpHeaders()
    const body = { "email": email };
    return this.http.post(environment.apiURL + '/services/suscribe-email-to-newsletter', body, { headers, observe: 'response', withCredentials: true })
  }

  CheckCreatorCode(creator_code: string): Observable<any> {
    const headers = new HttpHeaders()
    const body = { "creator_code": creator_code };
    return this.http.post(environment.apiURL + '/services/check-creator-code', body, { headers, observe: 'response', withCredentials: true })
  }

  getErrorStr(status: number) : string {
    var str: string = "";
    switch (status) {
      case 400:      //BAD_REQUEST
        str += "BAD_REQUEST contact admin";
        break;
      case 403:     //forbidden
        str += "Invalid username or password";
        break;
      case 403:     //CONFLICT
        str += "CONFLICT contact admin";
        break;
      case 429:     //Too Many Requests
        str += "Too Many Requests";
        break;
      case 504:     //Gateway Timeout
        str += "Timeout";
        break;
    }
    if (status === undefined)
      str += "Unable to connect";
    return str;
  }

  // timer

  startTimer() {
    this.canCheckAccountStatus = false;
    this.timerRef = setInterval(() => {
      this.canCheckAccountStatus = true;
      clearInterval(this.timerRef);
    }, 20000);
  }
}
