<div style="background-image: url(assets/assets_web_2/bg-dashboard.webp); background-attachment: fixed; background-position: center; width: 100vw; min-height: 100vh; position: relative; background-repeat: no-repeat; background-size: cover; ">
  <app-hud [displaySocialLinks]="false"></app-hud>
  <div class=" container" style="height:100vh;background-color:transparent;border:none">

    <h1 id="title" style="text-align:center;padding-top:20vh">Register</h1>

    <div *ngIf="canCreateAccount()" style="width:max(320px, 30vw); margin: auto">
      <form [formGroup]="RegisterForm">
        <div class="form-group">
          <label for="firstName">First Name <font size="3">(optional)</font> </label>
          <div class="underlineWhite">
            <div class="underline">
              <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
                <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(2 15) rotate(-45)" />
              </svg>
              <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
              <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">First Name is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="lastName">Last Name <font size="3">(optional)</font> </label>
          <div class="underlineWhite">
            <div class="underline">
              <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
                <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(2 15) rotate(-45)" />
              </svg>
              <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
              <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="lastName">Email</label>
          <div class="underlineWhite">
            <div class="underline">
              <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
                <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(2 15) rotate(-45)" />
              </svg>
              <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors && !f.email.errors.required">Email not valid</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="username">Username</label>
          <div class="underlineWhite">
            <div class="underline">
              <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
                <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(2 15) rotate(-45)" />
              </svg>
              <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Username is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <div class="underlineWhite">
            <div class="underline">
              <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
                <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(2 15) rotate(-45)" />
              </svg>
              <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-2" style="font-size:18px; text-align:center">
          <div class="form-check form-switch" style="text-align: left;">
            <input class="form-check-input" style="border-color: red;" [ngStyle]="thermsCondition?{'border-style':'none'} : {'border-style':'solid'}" (change)="AcceptTermsAndCondition($event);" [checked]="thermsCondition" type="checkbox" id="flexSwitchCheckChecked" checked>
            <label style="cursor: pointer; margin: 0; text-align: left; " routerLink="/terms-and-conditions">Accept Terms & Conditions</label>
          </div>
          
        </div>
        <div class="form-group" style="width:150px; margin: auto;cursor:pointer" (click)="onSubmit()">
          <div class="underlineWhite">
            <div class="underline">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              <p style="text-align: center; padding-top: 10px; padding-bottom: 10px">Register</p>
            </div>
          </div>
          <div class="underlineWhite" style="width: 150px; margin: auto; cursor: pointer" routerLink="">
            <div class="underline">
              <p style="text-align: center; padding-bottom: 10px">Cancel</p>
            </div>
          </div>

        </div>
      </form>
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      <p style="text-align:center">{{FormMessage}}</p>
    </div>

    <div *ngIf="!canCreateAccount()" style="margin-left: auto; margin-right: auto">
      <div style="text-align:center" class="alert alert-danger" role="alert">
        Registrations are closed for now.
      </div>
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <button routerLink="/" class="btn btn-primary">Home</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
