<div class="flexible-container" style="position:relative">
    <div id="SoulCastAboutVideo">
      <div #playerAboutContainer style="position: absolute; top: 50%; left: 50%; transform: translate(-50% ,-50%)"></div>
    </div>
   

    <div id="video_overlays">
      <img loading="lazy" src="assets/border3.webp" alt="border" style="position: absolute; transform: translate(0%,2.4%) rotate(179deg) skew(-1deg, 0deg);height: 65vh; width: 100%; bottom: 0; pointer-events: none; z-index: 3">

      <div style="position: relative; margin-bottom: 6vh; z-index: 3; pointer-events: all; width: 100vw;">

        <div class="px-4 px-lg-5">
          <h3 class="py-3" style="line-height: 80%; width: 80vw;">
            There's a new player in town
          </h3>
          <p class="small" style="max-width:80vw">
            Discover SoulCast, an Online Action RPG set in a world ripe with opportunity.<br />
            Fight bosses, enemy factions and other players in intense multiplayer clashes.
            Serve and protect or become an outlaw,<br /> Wield a deadly arsenal of weapons and spells,
            and use a set of incredible movement skills.<br />
            In Abaddon,the only way is up.
          </p>
        </div>
        

        <div [ngClass]="{'px-5': !IsMobile()}" class="d-flex flex-wrap" style="width: 100vw;">

          <div class="underlineWhite" [ngClass]="{'CenteredPlay': IsMobile()}">
            <div class="underline" (mouseover)="OnPlayOver()" (mouseleave)="OnPlayLeave()">
              <button class="btn btn-link" routerLink="./shop" style="cursor: pointer;" aria-label="" role="link">
                <svg height="66px" width="150px">
                  <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
                  <rect style="opacity:0;" class="RectF" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
                  <text x="60px" fill="white" y="45px" style="font-size: 1.2rem; left: 5vw; ">
                    <tspan>PLAY</tspan>
                  </text>
                </svg>
              </button>
            </div>
          </div>

          <app-newsletter-subscribe *ngIf="!IsMobile()" class="px-3"></app-newsletter-subscribe>
          
          <div [ngClass]="{'CenteredPlay': IsMobile()}" style="position:relative;">
            <a href="https://discord.gg/Tph9tp4Qan" target="_blank" rel="noopener">
              <img loading="lazy" class="discordlink" src="https://discordapp.com/api/guilds/1036349647674945626/widget.png?style=banner2" alt="">
            </a>
          </div>
        </div>
      </div>

    </div>
</div>
