import { Injectable } from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  private sidenav: MatSidenav;
  private openedSubject: Subject<boolean>;
  public opened: Observable<boolean>;
  private openedbymouseSubject: Subject<boolean>;
  public openedbymouse: Observable<boolean>;

  constructor() {
    this.openedSubject = new BehaviorSubject<boolean>(false);
    this.opened = this.openedSubject.asObservable();
    this.openedbymouseSubject = new BehaviorSubject<boolean>(false);
    this.openedbymouse = this.openedbymouseSubject.asObservable();
  }

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }
  public setOpenedbymouse(value: boolean) {
    this.openedbymouseSubject.next(value);
  }

  public toggle(): void {
    this.sidenav.toggle();
    this.openedSubject.next(this.sidenav.opened)
  }
}
