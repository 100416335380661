import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject, Observable, Subscription, BehaviorSubject, tap } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Order } from 'src/app/orders-templated-list/orders-templated-list.component'

@Injectable({
  providedIn: 'root'
})
export class OrdersService implements OnInit, OnDestroy {

  private ordersSubject: Subject<Array<Order>>;
  public ordersObservable: Observable<Array<Order>>;
  orders: Array<Order>;
  ordersSub: Subscription;

  constructor(private http: HttpClient, private cookieService: CookieService)
  {
    this.orders = []
    this.ordersSubject = new BehaviorSubject<Array<Order>>(this.orders);
    this.ordersObservable = this.ordersSubject.asObservable();
    this.ordersSub = this.ordersObservable.subscribe(
      (next: Array<Order>) => {
        this.orders = next;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  ngOnInit(): void
  {
  }

  ngOnDestroy()
  {
    this.ordersSub.unsubscribe();
  }

  public setOrders(newOrders: Array<Order>)
  {
    this.ordersSubject.next(newOrders);
  }

  public getOrders()
  {
    const headers = new HttpHeaders()
    this.http.get(environment.apiURL + '/services/orders/get-orders', { headers, observe: 'response', withCredentials: true })
      .subscribe(
        (response) => {
          let orders: Array<Order> = JSON.parse(JSON.stringify(response.body)) // update user
          this.setOrders(orders);
        },
        (error) => {//Error callback
          this.setOrders([]);
        })
  }

  saveOrder(sessionId: string) {
    return this.http.post(environment.apiURL + '/services/orders/create-order', {session_id: sessionId}, {withCredentials: true})
      .pipe(tap(
        (order) => {
          this.orders.push(<Order>order);
          console.log(this.orders);
          this.ordersSubject.next(this.orders);
        }))
  }
}
