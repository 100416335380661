import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  showCharacters = false;

  ngOnInit() {
    if (window.screen.width > 1660) {
      this.showCharacters = true;
    }
  }

  @HostListener("window:resize", [])
  onResize() {
    var width = window.screen.width;
    this.showCharacters = width > 1660;
  }
}
