import { Component, OnInit, OnDestroy, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AccountService } from 'src/app/services/account.service';
import { environment } from 'src/environments/environment';

export interface User {
  isLogin: boolean; // not in json body

  date_joined: Date;
  email: string;
  first_name: string;
  groups: [];
  id: string;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  last_login: Date;
  last_name: string;
  user_permissions: [];
  username: string;
  has_subscribed_to_newsletter: boolean;
  has_verified_email: boolean;
  has_accepted_termsAndConditions: boolean;
  is_steam_account_linked: boolean;
  is_discord_account_linked: boolean;
  is_twitch_account_linked: boolean;
  has_password: boolean;
  icon_index: number;
}

export const DefaultUser: User = {
  isLogin: false,
  date_joined: new Date(),
  email: "anonymous@soulcast.online",
  first_name: "John",
  groups: [],
  id: "",
  is_active: true,
  is_staff: false,
  is_superuser: false,
  last_login: new Date(),
  last_name: "Doe",
  user_permissions: [],
  username: "Anonymous",
  has_subscribed_to_newsletter: true,
  has_verified_email: false,
  has_accepted_termsAndConditions: false,
  is_steam_account_linked: false,
  is_discord_account_linked: false,
  is_twitch_account_linked: false,
  has_password: false,
  icon_index:2
}


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

@Injectable({
  providedIn: 'root'
})
export class LoginComponent implements OnInit, OnDestroy  {

  
  public profileForm:FormGroup;
  private username: FormControl;
  private password: FormControl;
  f;

  user: User;
  userSub: Subscription;

  LogInMessage: string;
  isWaitingReq: boolean = false;

  constructor(private formBuilder: FormBuilder, private accountService: AccountService) {
    this.username = new FormControl('', [Validators.required])
    this.password = new FormControl('', [Validators.required, Validators.minLength(6)])

    this.profileForm = formBuilder.group({
      username: this.username,
      password: this.password
    })
    this.f = this.profileForm.controls;
    this.subscribeUser();
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  subscribeUser() {
    this.userSub = this.accountService.userObservable.subscribe(
      (next: User) => {
        this.user = next;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  isUserLoggedIn(): boolean {
    if (this.user === null)
      return false;
    else return this.user.isLogin;
  }

  ngOnInit(): void
  {
    
  }

  onSubmit() {
    if (this.profileForm.invalid) {
      this.LogInMessage = "Invalid username or password"
      return;
    }
    if (this.isWaitingReq)
      return;
    this.LogInMessage = "Please Wait"
    this.isWaitingReq = true;
    this.login(this.profileForm.value.username, this.profileForm.value.password);
    console.log("user: " + this.profileForm.value.username);
  }

  login(User : string, Pass : string) {
    this.accountService.login(User, Pass)
    .subscribe(
      (response) => {
        this.isWaitingReq = false;
      },
      (error) => {//Error callback
        this.LogInMessage = this.accountService.getErrorStr(error.status);
        this.isWaitingReq = false;
      }
    );
  }

  passwordReset()
  {
    location.href = environment.apiURL + "/password-reset/";
  }

  loginWithSteam(){
    location.href = environment.apiURL + "/login/steam/";
  }

  logout() {
    this.accountService.logout()
    .subscribe(
      (response) => {
        console.log("logout");
      }
    );
  }
}
