

<div class="flexible-container" style="position: relative;">

  <div style="display: flex; justify-content: center;">
    <svg width="600px" height="160px">
      <image loading="lazy" href="assets/assets_web_2/Ornement-v2.svg" alt="Timeline" width="600px" height="160px" />
    </svg>
  </div>

  <h3 id="title" class="justify-content-center" style="text-align:center">TimeLine</h3>
  <p class="justify-content-center" style="text-align:center">Development Roadmap</p>

  <img loading="lazy" src="assets/assets_web_2/TIMELINE_final.webp" alt="Timeline" style="width: min(1200px,calc(100vw)); display: block; height: 100%; margin-left: auto; margin-right: auto; " />

  <div class="container-fluid d-flex justify-content-center" style="position: relative; height: max(1000px,calc(110vh)); clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); ">

    <img loading="lazy" src="assets/assets_web_2/masque_section_5.webp" alt="Graphic" style="padding-top: 2vh; position: absolute; height: max(1000px,calc(110vh)); width: 100vw; top: 0; pointer-events: none; user-select: none; z-index: 1">

    <div id="video_overlays">
      <div class="px-5 pt-5" style="width:20vw">

        <div class="row pt-5">
          <a class="btn btn-link" style="cursor: pointer;" (click)="ClickLore()" (mouseenter)="LoreMouseEnter()" (mouseleave)="LoreMouseLeave()">
            <svg viewBox="0 0 400 66" width="min(400px,50vh)" height="min(66px,50vh)" preserveAspectRatio="xMaxYMax">
              <rect width="400px" height="66px" />
              <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <rect class="RectFL" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <text x="60px" fill="white" y="45px" style="font-size: 30px; left: 5vw; ">
                <tspan>ENTICING LORE</tspan>
              </text>
            </svg>
          </a>
        </div>

        <div class="row">
          <a class="btn btn-link" style="cursor: pointer;" (click)="ClickWorld()" (mouseenter)="WorldMouseEnter()" (mouseleave)="WorldMouseLeave()">
            <svg viewBox="0 0 400 66" width="min(400px,50vh)" height="min(66px,50vh)" preserveAspectRatio="xMaxYMax">
              <rect width="400px" height="66px" />
              <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <rect style="opacity:0" class="RectFW" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <text x="60px" fill="white" y="45px" style="font-size: 30px; left: 5vw; ">
                <tspan>A WORLD TO EXPLORE</tspan>
              </text>
            </svg>
          </a>
        </div>

        <div class="row">
          <a class="btn btn-link" style="cursor: pointer;" (click)="ClickQuest()" (mouseenter)="QuestMouseEnter()" (mouseleave)="QuestMouseLeave()">
            <svg viewBox="0 0 400 66" width="min(400px,50vh)" height="min(66px,50vh)" preserveAspectRatio="xMaxYMax">
              <rect width="400px" height="66px" />
              <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <rect style="opacity:0" class="RectFQ" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <text x="60px" fill="white" y="45px" style="font-size: 30px; left: 5vw; ">
                <tspan>DYNAMIC QUESTS</tspan>
              </text>
            </svg>
          </a>
        </div>

        <div class="row">
          <a class="btn btn-link" style="cursor: pointer;" (click)="ClickCrime()" (mouseenter)="CrimeMouseEnter()" (mouseleave)="CrimeMouseLeave()">
            <svg viewBox="0 0 400 66" width="min(400px,50vh)" height="min(66px,50vh)" preserveAspectRatio="xMaxYMax">
              <rect width="400px" height="66px" />
              <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <rect style="opacity:0" class="RectFC" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
              <text x="60px" fill="white" y="45px" style="font-size: 30px; left: 5vw; ">
                <tspan>A LIFE OF CRIME</tspan>
              </text>
            </svg>
          </a>
        </div>

      </div>

      <div class="row px-5 pt-3 small" style="position:relative">
        <div class="LoreText" style=" position: absolute;width:25vw">
          <p>
            Stranded in the mysterious world of Abaddon, you will find a city in the grasps of unrest
            and a fractured society, seething with corruption.
            Take advantage of the civil war brewing under the surface to work your way up in Abaddon's' hierarchy and find answers.
          </p>
          <p>
            With every victory and every new encounter, you will get closer to revealing this city's secrets.
          </p>
        </div>
        <div class="Op0 ExploreText" style=" position: absolute; margin-right: 5vh;width:25vw">
          <p>
            Roam the crowded squares and dark alleys of the lower districts,
            walk among the splendid manors and palaces of the higher quarters
            and explore the catacombs and spires of its imposing cathedrals.
          </p>
          <p>
            In each district you will meet loyal friends,
            mortal enemies and others whose motives are not so clear.
            Get to know the different faces of power, fight men and monsters
            and leave your mark on this city as you rise to the top.
          </p>
        </div>
        <div class="Op0 QuestText" style=" position: absolute; margin-right: 5vh;width:25vw">
          <p>
            During your adventures you will deal not only with the high and mighty of Abaddon.
            Through random encounters you will come into contact with citizens across all social classes.
          </p>
          <p>
            These characters have their own motivations and offer opportunities based on your relationship with them.
            Do a favor for a rich oligarch and he may consider you the next time he needs someone for a job.
            Disappoint or betray him and he may refuse to do business with you.<br />
            In Abbadon, you will be judged by your actions.
          </p>
        </div>
        <div class="Op0 CrimeText" style=" position: absolute; margin-right: 5vh;width:25vw">
          <p>
            Life in Abaddon is subject to strict laws. Tresspassing, theft and acts of violence
            will alert nearby guardians and the use of forbidden magic
            is considered a serious crime.
            In extreme cases, perpetrators will find themselves with a bounty on their head:
            they become outlaws, hunted down mercilessly for a lucrative reward.
          </p>
          <p>
            After all, crime does pay in Abaddon.
          </p>
        </div>
      </div>
    </div>

    <div id="SoulCastGamePlayVideo">
      <div #playerGamePlayContainer style="position: absolute; top: 50%; left: 50%; transform: translate(-35% ,-50%)"></div>
    </div>

  </div>
