


<div [@toolbar-visibility]="topToolbarVisible || !ToggleNav ? 'toolbar-visible' : 'toolbar-hidden'" class="d-flex justify-content-between top-toolbar">

  <img *ngIf="!isBiggerScreen()" style="height: 65px; width: auto; padding-left:100px " src="assets/OneShotLogo_OneLine.png" alt="OnshotStudioLogo" id="OneShotStudioLogo">

  <nav class="navbar navbar-expand-lg" style="position: fixed; z-index: 3000; width: 100vw; padding:0;">
    <div class="container-fluid">

      
      <div class="navbar-nav m-auto me-lg-5">
        <div class="nav">
          <a class="nav-link text-reset px-1 px-lg-2" routerLink="/" aria-label="H">HOME</a>
          <a class="nav-link text-reset px-1 px-lg-2" routerLink="/shop" aria-label="Shop">SHOP</a>
          <a class="nav-link text-reset px-1 px-lg-2" routerLink="/faq" aria-label="FAQ">FAQ</a>
          <a *ngIf="!user.isLogin" class="nav-link text-reset px-1 px-lg-2" style="cursor: pointer;" (click)="toggleSidenav();">LOGIN</a>
          <a *ngIf="user.isLogin" routerLink="/account" class="nav-link text-reset px-1 px-lg-2" style="cursor: pointer;">ACCOUNT</a>
        </div>
      </div>
    </div>
  </nav>
</div>

<mat-sidenav-container style="z-index: 99999;">
  <!-- Sidenav -->
  <mat-sidenav  id="sidenav" #sidenav [mode]="isBiggerScreen() ? 'over' : 'side'" [(opened)]="opened || openedbymouse" [fixedInViewport]="true" position="start"
               [fixedTopGap] style="min-width:320px">
    <button type="button" class="close px-2" style="position:absolute;top:0;right:0" aria-label="Close" (click)="toggleSidenav()">
      <span aria-hidden="true" style="color:white;font-size:40px">&times;</span>
    </button>
    <mat-nav-list style="min-width:250px">
      <div mat-list-item style="text-align:center">
        <app-login></app-login>
      </div>
      <div class="d-flex justify-content-center mb-2">
        <img loading="lazy" src="assets/SoulCastSmall.webp" alt="Logo" class="m-auto" style="bottom: 20px; width: 10vw; min-width: 200px; height: auto; filter: drop-shadow(5px 5px 5px #000000); ">
      </div>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>
