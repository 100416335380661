import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input, ViewChild, Output, ElementRef } from '@angular/core';
import { GetImage, GetStripeId, Product } from 'src/app/products-templated-list/products-templated-list.component'
import { User } from '../login/login.component';
import { AccountService } from '../services/account.service';
import { LoginModalComponent } from '../login/login-modal/login-modal.component';
import { TermsAndConditionsModalComponent } from '../terms-and-conditions-modal/terms-and-conditions-modal.component';
import { PaymentsService } from '../services/payments.service';
import { ModalService } from '../services/modal.service';
import { interval, Observable, Subscription } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

export function reducedPrice(product: Product) {
  return Math.max(0, product.price * (1 - (product.reduction / 100)))
}

interface timeComponents {
  secondsToDday: number;
  minutesToDday: number;
  hoursToDday: number;
  daysToDday: number;
  monthToDday: number;
}

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('productContainer') Container: ElementRef;
  @ViewChild('header_img') Image: ElementRef;

  @Input() product: Product;
  @Input() showDetail: Boolean;
  productImagePath: String;
  error: Error;
  user: User;
  userSub: Subscription;

  public timeLeft$: Observable<timeComponents>;
  timeDiff: number = 1;
  constructor(private accountService: AccountService, private paymentsService: PaymentsService, private modalService: ModalService, private router: Router) { }

  ngOnInit(): void {
    this.userSub = this.accountService.userObservable.subscribe(
      {
        next: user => {
          if (user) {
            this.user = user;
          }
        },
        error: err => {
          this.error = err;
        }
      }
    )
    this.SetImage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("product" in changes) {
      this.timeLeft$ = interval(1000).pipe(
        map(x => this.calcDateDiff()),
        shareReplay(1)
      );
    }
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

  onBuyEvent(product: Product) 
  {
    let itemId = GetStripeId(product);
    if (this.user?.isLogin) {
      if (!this.user.has_accepted_termsAndConditions) {
        const termsAndConditionsModal = this.modalService.open(TermsAndConditionsModalComponent, { size: 'lg', modalDialogClass: 'modal-dialog-centered' });
        termsAndConditionsModal.componentInstance.SetItemId(itemId);
      }
      else {
        this.paymentsService.checkout(itemId).subscribe(
          () => {
          },
          (error) => {
            console.log(error)
            this.error = error;
          }
        )
      }
    } else {
      const loginModal = this.modalService.open(LoginModalComponent, { size: 'lg', modalDialogClass: 'modal-dialog-centered' });
    }
  }

  mouseOverBoundingElem(evt) {
    let bounding = this.Container.nativeElement.getBoundingClientRect();
    let x = evt.clientX - Math.round(bounding.left);
    let y = evt.clientY - Math.round(bounding.top);

    return {
      x: Math.max(0, x),
      y: Math.max(0, y),
      width: Math.round(bounding.width),
      height: Math.round(bounding.height)
    };
  }

  over(event) {
    var style;
    var style = this.Container.nativeElement.style;
    if (style) {
      style.transform += `scale(1.2)`;
      style.filter = `brightness(1.2)`;
      style.zIndex = '2';
    }
    this.Image.nativeElement.style.transform = `scale(1.2)`;
  }

  move(event) {
    const maxTilt = 15;
    let bounding = this.mouseOverBoundingElem(event);
    let posX = bounding.width / 2 - bounding.x;
    let posY = bounding.height / 2 - bounding.y;
    let hypotenuseCursor = Math.sqrt(Math.pow(posX, 2) + Math.pow(posY, 2));
    let hypotenuseMax = Math.sqrt(Math.pow(bounding.width / 2, 2) + Math.pow(bounding.height / 2, 2));
    let ratio = hypotenuseCursor / hypotenuseMax;

    var style;
    var style = this.Container.nativeElement.style;

    style.transform = `rotate3d(${posY / hypotenuseCursor}, ${-posX / hypotenuseCursor}, 0, ${ratio * maxTilt}deg)
                       scale(1.2)
                       translateX(${posX * ratio * 0.02}px) translateY(${posY * ratio * 0.02}px)` // 0.75 = offset

    //this.Image.nativeElement.style.transform = `scale(1.2) translateX(${posX * 0.01}px) translateY(${posY * 0.01}px)` // 0.75 = offset
    style.filter = `brightness(${this.clamp(1 + bounding.y / bounding.height, 1.1, 1.4)})`;
  }

  OpenInfo() {
    this.router.navigate(['/shop', this.product.id]);
  }

  out(event) {
    var style;
    var style = this.Container.nativeElement.style;
    style.transform = '';
    style.filter = '';
    style.zIndex = '1';
    this.Image.nativeElement.style.transform = ``;
  }

  clamp(num, min, max) {
    return num <= min
      ? min
      : num >= max
        ? max
        : num
  }

  getReducedPrice(product: Product) {
    return reducedPrice(product)
  }

  calcDateDiff(): timeComponents {
    const milliSecondsInASecond = 1000;
    const hoursInADay = 24;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;
    const dayInMonth = 30;
    const timeDifference = new Date(this.product.availabilityDate).getTime().valueOf() - Date.now();
    this.timeDiff = timeDifference;
    const monthToDday = Math.floor(timeDifference / (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay * dayInMonth));
    const daysToDday = Math.floor(timeDifference / (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay) % dayInMonth);
    const hoursToDday = Math.floor((timeDifference / (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) % hoursInADay);
    const minutesToDday = Math.floor((timeDifference / (milliSecondsInASecond * minutesInAnHour)) % secondsInAMinute);
    const secondsToDday = Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;
    return { secondsToDday, minutesToDday, hoursToDday, daysToDday, monthToDday };
  }

  SetImage() {
    this.productImagePath = GetImage(this.product);
  }
}
