import {Component, OnInit} from '@angular/core';
import {Product, DefaultProduct, ProductSortType, productSortTypeToString} from 'src/app/products-templated-list/products-templated-list.component';
import {ProductsService} from '../services/products.service';
import {ActivatedRoute} from '@angular/router';
import {OrdersService} from '../services/orders.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {

  error: Error;
  success: boolean;
  shopDisabled: boolean;
  listProducts: Array<Product> = [];
  
  currentSortType: ProductSortType = ProductSortType.PricesFromLowToHigh;
  ProductSortType = ProductSortType;


  constructor(private productsService: ProductsService, private route: ActivatedRoute,private ordersService: OrdersService) {
  }

  ngOnInit(): void {
    this.shopDisabled = false;

    this.productsService.getProducts().subscribe(
      next => {
        this.listProducts = next.map(item => {
          return {...item, price: +item.price}
        });
      },
      (err) => {
        this.error = err;
        this.shopDisabled = true;
        console.log(err);
      }
    )

    this.route.queryParams.subscribe(
      (params) => {
        this.success = JSON.parse(params?.['success'] || null);
        if (this.success) {
          this.ordersService.saveOrder(params?.['session_id'])
            .subscribe({
              next: () => {
                /*this.router.navigate(['']);*/
                console.log('success');
              },
              error: (err) => {
                this.error = err;
              }
            });
        }
      }
    )
  }

  productSortTypeToString(sortType:ProductSortType): string
  {
    return productSortTypeToString(sortType);
  }

  setCurrentSortType(sortType:ProductSortType): void
  {
    this.currentSortType = sortType;
  }

}
