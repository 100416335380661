import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-newsletter-subscribe',
  templateUrl: './newsletter-subscribe.component.html',
  styleUrls: ['./newsletter-subscribe.component.css']
})
export class NewsletterSubscribeComponent implements OnInit {

  constructor(private accountService: AccountService) { }

  subscribe_email = new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$')]);
  hasClickOnSubscribe: boolean = false;

  ngOnInit(): void {
  }

  onSubmitSubscribe() {
    this.hasClickOnSubscribe = true;
    if (this.subscribe_email.invalid) { this.getErrorMessage();return;}
    let email = this.subscribe_email.value != null ? this.subscribe_email.value : ""
    this.accountService.subscribeMailToNewsLetter(email)
      .subscribe(
        (response) => {
          alert("you have successfully subscribed to the newsletter");
        },
        (error) => {//Error callback
          var errorstr: string = "fail suscribing to newsletter: ";
          errorstr += this.accountService.getErrorStr(error.status)
          alert(errorstr);
        }
      );
  }

  getErrorMessage() {
    if (this.subscribe_email.hasError('required')) {
      return 'You must enter a valid email';
    }
    return this.subscribe_email.hasError('email') ? 'Not a valid email' : '';
  }

}
