import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Player from '@vimeo/player';

@Component({
  selector: 'app-about-video',
  templateUrl: './about-video.component.html',
  styleUrls: ['./about-video.component.css']
})
export class AboutVideoComponent implements OnInit, AfterViewInit {
  @ViewChild('playerAboutContainer') playerContainer: ElementRef;
  videoPlayer: Player;
  PlayHoverAnim;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    let options;
    let _height = document.getElementById('SoulCastAboutVideo')?.clientHeight;
    let _width = document.getElementById('SoulCastAboutVideo')?.clientWidth;
    if (_height! > _width!) {
      options = {
        id: 766973548,
        height: _height,
        loop: true,
        background: true,
        controls: false
      };
    }
    else {
      options = {
        id: 766973548,
        width: _width,
        loop: true,
        background: true,
        controls: false
      };
    }
    this.videoPlayer = new Player(this.playerContainer.nativeElement, options);
    // IntersectionObserver
    let setupIntersectionObserver = function (target) {
      target.videoPlayer.pause();
      var optionsIntersectionObserver = { threshold: 0.1 }
      const callback = function (entries) {
        if (entries[0].isIntersecting) {
          target.videoPlayer.play();
        } else {
          target.videoPlayer.pause();
        }
      }
      var observer = new IntersectionObserver(callback, optionsIntersectionObserver);
      observer.observe(target.videoPlayer.element);
    };
    this.videoPlayer.ready().then(setupIntersectionObserver(this));
  }

  OnPlayOver() {
    if (this.PlayHoverAnim)
      this.PlayHoverAnim.kill()
    this.PlayHoverAnim = gsap.timeline().fromTo('.RectF', 0.4, { autoAlpha: 0 }, { autoAlpha: 1 });
  }

  OnPlayLeave() {
    if (this.PlayHoverAnim) {
      this.PlayHoverAnim.duration(2)
      this.PlayHoverAnim.reverse();
    }
  }

  IsMobile() { return window.innerWidth <= 1080; }

}
