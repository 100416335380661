import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { SidenavService } from '../../services/sidenav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private sidenavService: SidenavService, private route: Router) { }

  ngOnInit(): void {}

  toggleLoginSidenav() {
    this.sidenavService.toggle();
  }

  redirectToRegister() {
    this.route.navigate(['./register']);
  }
}
