import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output} from '@angular/core';
import {OrdersService} from '../services/orders.service';
import { PaymentsService } from '../services/payments.service';
import { AccountService } from 'src/app/services/account.service';
import { environment } from 'src/environments/environment';

export enum ProductSortType {
  PricesFromLowToHigh = 0,
  PricesFromHighToLow = 1
}

export function productSortTypeToString(sortType: ProductSortType) : string
{
  return sortType === ProductSortType.PricesFromLowToHigh ? "Prices from low to high" : "Prices from high to low";
}

export interface Product {
  id: number;
  stripe_id: string;
  test_stripe_id: string;
  name: string;
  description: string;
  additional_text: string;
  price: number;
  reduction: number;
  availabilityDate: Date;
}

export function GetImage(product: Product) {
  if (product.id == 2)
    return "assets/Set/Set01_f.jpg"
  else if (product.id == 3)
    return "assets/Set/Set02_f.jpg"
  else if (product.id == 4)
    return "assets/Set/Set03_f.jpg"
  else if (product.id == 5)
    return "assets/Set/Set04_f.jpg"
  else return "";
}

export function GetSquaredImage(product: Product) {
  if (product.id == 2)
    return "assets/Set/Set01_squared_f.jpg"
  else if (product.id == 3)
    return "assets/Set/Set02_squared_f.jpg"
  else if (product.id == 4)
    return "assets/Set/Set03_squared_f.jpg"
  else if (product.id == 5)
    return "assets/Set/Set04_squared_f.jpg"
  else return "";
}

export function GetStripeId(product: Product) {
  return environment.production ? product.stripe_id : product.test_stripe_id
}


export function lowToHigh(product1: Product, product2: Product)
{
  return product1.price < product2.price ? -1 : product1.price > product2.price ? 1 : 0;
}

export function highToLow(product1: Product, product2: Product)
{
  return -lowToHigh(product1, product2);
}

export const DefaultProduct: Product = {
  id: 0,
  stripe_id: 'price_xxxxxxxxxxxxxxxx',
  test_stripe_id: 'price_xxxxxxxxxxxxxxxx',
  name: "",
  description: "",
  additional_text: "",
  price: 0.00,
  reduction: 0,
  availabilityDate: new Date(2023, 0, 1)
}

@Component({
  selector: 'app-products-templated-list',
  templateUrl: './products-templated-list.component.html',
  styleUrls: ['./products-templated-list.component.css']
})

export class ProductsTemplatedListComponent implements OnInit, OnChanges {

  error: Error;
 
  constructor(private paymentsService: PaymentsService, private accountService: AccountService) {
    
  }

  @Input()
  products: Array<Product> = [];

  @Input()
  productSortType: ProductSortType;

  productsRows: Array<Array<Product>> = [];

  @Input()
  itemsPerRow: number = 4;

  @Output() buyEvent = new EventEmitter<string>();

  Message: string;
  creatorCode: string;
  promotion_code: string;
  isWaitingReq: boolean = false;
  isValid: boolean = false;

  ngOnInit(): void {
    this.creatorCode = "";
    this.Message = "";
  }

  keyup(event) {
    if (this.isWaitingReq) {
      event.target.value = this.creatorCode;
      return;
    }
    this.isValid = false;
    if (event.target.value.length == 0) {
      this.Message = ""
      return;
    }
    if (event.target.value.length > 6) {
      event.target.value = this.creatorCode ? this.creatorCode.toUpperCase() : "";
    }

    this.creatorCode = event.target.value.toUpperCase();

    if (this.creatorCode.length < 6) {
      event.target.value = this.creatorCode;
      this.Message = "Creator Code is too short"
      this.isWaitingReq = false;
    }
    else {
      this.Message = "Checking creator Code"
      this.accountService.CheckCreatorCode(this.creatorCode).subscribe(
        (response) => {
          this.isWaitingReq = false;
          response = JSON.parse(JSON.stringify(response.body))
          this.promotion_code = response["promotion_code"];
          let percentOFF = response["promotion_prc"]
          this.Message = "This Creator Code is valid"
          this.isValid = true;
          this.UseCreatorCode(percentOFF);
        },
        (error) => {//Error callback
          this.isWaitingReq = false;
          console.log(error)
          this.Message = "This Creator Code is not valid"
        }
      );
    }
  }

  UseCreatorCode(percentOFF: number) {

    if (!this.isValid)
      return;
    // check creator code

    this.paymentsService.SetCreatorCode(this.creatorCode, this.promotion_code, percentOFF);
    for (let i = 0; i < this.products.length; i++) {
      this.products[i].reduction = percentOFF;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if("products" in changes || "itemPerRow" in changes || "productSortType" in changes)
    {
      let sortedProducts = this.products;
      sortedProducts.sort(this.productSortType === ProductSortType.PricesFromLowToHigh ? lowToHigh : highToLow)
      this.updateRows(sortedProducts);
    }
  }

  getNumberOfRows() : number
  {
    return Math.ceil(this.products.length/this.itemsPerRow);
  }

  private updateRows(newProducts: Array<Product>) : void
  {
    let numberOfRows = this.getNumberOfRows()
    let newRows = Array<Array<Product>>(numberOfRows);
    for(let i = 0; i < numberOfRows; i++)
    {
      let newRow = Array<Product>();
      for(let j = 0; j < this.itemsPerRow && i * this.itemsPerRow + j < this.products.length; j++)
      {
        newRow.push(newProducts[i * this.itemsPerRow + j])
      }
      newRows[i] = newRow;
    }
    this.productsRows = newRows;
  }
}
