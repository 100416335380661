import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { TextPlugin } from "gsap/TextPlugin";

// TimeoutInterceptor
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(timeout(20000));
  }
}
// End TimeoutInterceptor

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, AfterViewInit {
  title = 'SoulCastAngular';
  constructor(private router: Router, private renderer: Renderer2) { }
  ngOnInit() {
    window.scrollTo(0, 0);
    gsap.registerPlugin(ScrollTrigger, TextPlugin);
    gsap.ticker.fps(24);
    gsap.ticker.lagSmoothing(1000,20)
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    /*
    var docWidth = document.documentElement.offsetWidth;
    console.log("test width");
    [].forEach.call(
      document.querySelectorAll('*'),
      function (el: any) {
        if (el.offsetWidth > docWidth) {
          console.log("FIX THIS WIDTH : ");
          console.log(el);
        }
      }
    );
    */
  }
  ngAfterViewInit() {
    let loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }
}
