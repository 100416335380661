import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import Player from '@vimeo/player';

enum WeaponOpen {
  SwordOpen = "SwordOpen",
  BowOpen = "BowOpen",
  ScytheOpen = "ScytheOpen",
  SwordShieldOpen = "SwordShieldOpen",
  Hel = "Hel",
  Disjuncture = "Disjuncture"
}

@Component({
  selector: 'app-weapon-parallax',
  templateUrl: './weapon-parallax.component.html',
  styleUrls: ['./weapon-parallax.component.css']
})

export class WeaponParallaxComponent implements OnInit, AfterViewInit {

  constructor() { }
  @ViewChild('playerWeaponContainer') playerContainer: ElementRef;
  @ViewChild('playerWeaponSwordMobile') playerContainerSworldMobile: ElementRef;
  @ViewChild('playerContainerSworldShieldMobile') playerContainerSworldShieldMobile: ElementRef;
  @ViewChild('playerContainerScytheMobile') playerContainerScytheMobile: ElementRef;
  @ViewChild('playerContainerBowMobile') playerContainerBowMobile: ElementRef;
  @ViewChild('playerContainerSniperMobile') playerContainerSniperMobile: ElementRef;
  @ViewChild('playerContainerBasicMobile') playerContainerBasicMobile: ElementRef;

  swordVideoId = 767691972
  swordShieldVideoId = 767691972
  scytheVideoId = 767691972
  BowVideoId = 767687739
  SniperVideoId = 805118735
  BasicVideoId = 767691972

  isWeaponOpen: WeaponOpen = WeaponOpen.SwordOpen;
  WeaponVideoUrl: string;
  numEffect = 20;
  videoPlayer: Player;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const options = {
      id: this.swordVideoId,
      height: document.getElementById('SoulCastWeaponVideo')?.clientHeight,
      loop: true,
      background: true,
      controls: false,
      autoplay: true,
      autopause: false
    };

    let setupIntersectionObserver = function (target) {
      target.pause();
      var optionsIntersectionObserver = { threshold: 0.1 }
      const callback = function (entries) {
        if (entries[0].isIntersecting) {
          target.play();
        } else {
          target.pause();
        }
      }
      var observer = new IntersectionObserver(callback, optionsIntersectionObserver);
      observer.observe(target.element);
    };

    // MOBILE
    if (this.IsMobile()) {
      let player = new Player(this.playerContainerSworldMobile.nativeElement, options);
      player.ready().then(setupIntersectionObserver(player));

      options.id = this.swordShieldVideoId;
      player = new Player(this.playerContainerSworldShieldMobile.nativeElement, options);
      player.ready().then(setupIntersectionObserver(player));

      options.id = this.scytheVideoId;
      player = new Player(this.playerContainerScytheMobile.nativeElement, options);
      player.ready().then(setupIntersectionObserver(player));

      options.id = this.BowVideoId;
      player = new Player(this.playerContainerBowMobile.nativeElement, options);
      player.ready().then(setupIntersectionObserver(player));

      options.id = this.SniperVideoId;
      player = new Player(this.playerContainerSniperMobile.nativeElement, options);
      player.ready().then(setupIntersectionObserver(player));

      options.id = this.BasicVideoId;
      player = new Player(this.playerContainerBasicMobile.nativeElement, options);
      player.ready().then(setupIntersectionObserver(player));

      return;
    }
    // PC
    this.StartEffect();
    this.videoPlayer = new Player(this.playerContainer.nativeElement, options);
    this.videoPlayer.ready().then(setupIntersectionObserver(this.videoPlayer));
  }

  StartEffect() {
    let anims: TweenLite[] = [];
    const random = (min, max) => {
      return Math.floor(Math.random() * (max - min) + min);
    }

    var container = document.getElementById('containerWeapon');
    let clientWidth = document.getElementById('containerWeapon')?.clientWidth;
    let posX = random(100, 0);
    let clientHeight = document.getElementById('containerWeapon')?.clientHeight;
    let posY = clientHeight! - random(100, 0);

    for (var i = 0; i < this.numEffect; i++) {
      let sparcks = document.createElementNS("http://www.w3.org/2000/svg", "circle");
      sparcks.setAttribute('class', 'fireflyWepon');
      sparcks.setAttributeNS(null, 'r', "" + random(2, 4));
      sparcks.setAttributeNS(null, 'style', 'fill: #DC8E35; fill-opacity: 1; stroke-width: 1px;');
      sparcks.setAttributeNS(null, "cx", posX + "px");
      sparcks.setAttributeNS(null, "cy", posY + "px");
      sparcks.setAttributeNS(null, "filter", "saturate(0%) brightness(0%) contrast(0%)");
      container!.appendChild(sparcks);
    }

    var fireflies = document.querySelectorAll('.fireflyWepon');

    for (var i = 0; i < this.numEffect; i++) {

      let sparcksSize = random(4, 7);
      let upFactor = 1 / sparcksSize * 4;

      let ix = random(-clientWidth! * 0.22, clientWidth! * 0.10);
      let iy = random(-clientWidth! * 0.4, clientWidth! * 0.10);
      let fx = random(clientWidth! / 2 + 50, ix);
      let fy = random(iy - clientHeight! / 2, iy);
      let dirx = fx - ix;
      let diry = fy - iy;
      let size = Math.sqrt(dirx * dirx + diry * diry);

      fireflies[i].setAttributeNS(null, "transform", "rotate(" + this.dotProduct([dirx, diry], [0, 1]) / size * 90.0 + ") scale(" + sparcksSize + ",1)");

      let duration = random(10, 20);
      let delay = random(0, 3);
      anims.push(gsap.fromTo(fireflies[i], {
        x: ix,
        y: iy,
        filter: "saturate(100%) brightness(0%) contrast(100%)",
      }, {
        x: fx,
        y: fy,
        scaleX: "-=3",
        filter: "saturate(1352%) brightness(119%) contrast(119%)",
        duration: duration,
        ease: "rough ({template: none.out, strength: 1, points: 20, taper: 'none', randomize: true, clamp:false})",
        repeat: -1,
        delay: delay
      }));
      anims.push(gsap.fromTo(fireflies[i], {
        opacity: 0,
      }, {
        opacity: 0.6,
        duration: duration / 4,
        repeat: -1,
        yoyo: true,
        delay: delay
      }));
    };

    var options = {
      threshold: 0.1  //10% height is visible, observer should be triggered
    }

    const callback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          anims.forEach(anim => anim.play())
        } else {
          anims.forEach(anim => anim.pause(0))
        }
      });
    }
    var observer = new IntersectionObserver(callback, options);
    observer.observe(container!)
  }

  dotProduct(a, b) {
    const result = a.reduce((acc, cur, index) => {
      acc += (cur * b[index]);
      return acc;
    }, 0);
    return result;
  }

  ClickWeaponSword() {
    if (this.isWeaponOpen == WeaponOpen.SwordOpen)
      return;
    this.OnClickClose();
    this.isWeaponOpen = WeaponOpen.SwordOpen;
    this.OnClickOpen();
    this.ChangeVideoURL(this.swordVideoId);
    gsap.fromTo('.RectFWS', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });
  }
  ClickWeaponBow() {
    if (this.isWeaponOpen == WeaponOpen.BowOpen)
      return;
    this.OnClickClose();
    this.isWeaponOpen = WeaponOpen.BowOpen;
    this.OnClickOpen();
    this.ChangeVideoURL(this.BowVideoId);
    gsap.fromTo('.RectFWB', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });
  }
  ClickWeaponSwordAndShield() {
    if (this.isWeaponOpen == WeaponOpen.SwordShieldOpen)
      return;
    this.OnClickClose();
    this.isWeaponOpen = WeaponOpen.SwordShieldOpen;
    this.OnClickOpen();
    this.ChangeVideoURL(this.swordShieldVideoId);
    gsap.fromTo('.RectFWSH', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });
  }
  ClickWeaponScythe() {
    if (this.isWeaponOpen == WeaponOpen.ScytheOpen)
      return;
    this.OnClickClose();
    this.isWeaponOpen = WeaponOpen.ScytheOpen;
    this.OnClickOpen();
    this.ChangeVideoURL(this.scytheVideoId);
    gsap.fromTo('.RectFWSC', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });
  }
  ClickWeaponHel() {
    if (this.isWeaponOpen == WeaponOpen.Hel)
      return;
    this.OnClickClose();
    this.isWeaponOpen = WeaponOpen.Hel;
    this.OnClickOpen();
    this.ChangeVideoURL(this.BasicVideoId);
    gsap.fromTo('.RectFWC', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });
  }
  ClickWeaponDisjuncture() {
    if (this.isWeaponOpen == WeaponOpen.Disjuncture)
      return;
    this.OnClickClose();
    this.isWeaponOpen = WeaponOpen.Disjuncture;
    this.OnClickOpen();
    this.ChangeVideoURL(this.SniperVideoId);
    gsap.fromTo('.RectFWD', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });
  }

  OnClickClose() {
    if (this.isWeaponOpen == WeaponOpen.SwordOpen) { 
      gsap.timeline().to(".SwordText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFWS', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
    if (this.isWeaponOpen == WeaponOpen.BowOpen) {
      gsap.timeline().to(".BowText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFWB', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
    if (this.isWeaponOpen == WeaponOpen.SwordShieldOpen) {
      gsap.timeline().to(".SSText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFWSH', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
    if (this.isWeaponOpen == WeaponOpen.ScytheOpen) {
      gsap.timeline().to(".ScytheText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFWSC', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
    if (this.isWeaponOpen == WeaponOpen.Hel) {
      gsap.timeline().to(".HelText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFWC', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
    if (this.isWeaponOpen == WeaponOpen.Disjuncture) {
      gsap.timeline().to(".ZulText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFWD', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
  }
  OnClickOpen() {
    if (this.isWeaponOpen == WeaponOpen.SwordOpen)
      gsap.timeline().to(".SwordText", { autoAlpha: 1, duration: 0.5 });
    if (this.isWeaponOpen == WeaponOpen.BowOpen)
      gsap.timeline().to(".BowText", { autoAlpha: 1, duration: 0.5 });
    if (this.isWeaponOpen == WeaponOpen.SwordShieldOpen)
      gsap.timeline().to(".SSText", { autoAlpha: 1, duration: 0.5 });
    if (this.isWeaponOpen == WeaponOpen.ScytheOpen)
      gsap.timeline().to(".ScytheText", { autoAlpha: 1, duration: 0.5 });
    if (this.isWeaponOpen == WeaponOpen.Hel)
      gsap.timeline().to(".HelText", { autoAlpha: 1, duration: 0.5 });
    if (this.isWeaponOpen == WeaponOpen.Disjuncture)
      gsap.timeline().to(".ZulText", { autoAlpha: 1, duration: 0.5 });
  }

  ChangeVideoURL(value: number) {
    this.videoPlayer.loadVideo(value).then(function (this) {
      this.videoPlayer.play();
    })
  }

  WeaponSwordEnter() {
    gsap.fromTo('.RectFWS', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  WeaponSwordLeave() {
    if (this.isWeaponOpen != WeaponOpen.SwordOpen)
      gsap.fromTo('.RectFWS', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  WeaponBowEnter() {
    gsap.fromTo('.RectFWB', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  WeaponBowLeave() {
    if (this.isWeaponOpen != WeaponOpen.BowOpen)
      gsap.fromTo('.RectFWB', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  WeaponShieldEnter() {
    gsap.fromTo('.RectFWSH', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  WeaponShieldLeave() {
    if (this.isWeaponOpen != WeaponOpen.SwordShieldOpen)
      gsap.fromTo('.RectFWSH', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  WeaponScytheEnter() {
    gsap.fromTo('.RectFWSC', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  WeaponScytheLeave() {
    if (this.isWeaponOpen != WeaponOpen.ScytheOpen)
      gsap.fromTo('.RectFWSC', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  WeaponHelEnter() {
    gsap.fromTo('.RectFWC', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  WeaponHelLeave() {
    if (this.isWeaponOpen != WeaponOpen.Hel)
      gsap.fromTo('.RectFWC', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  WeaponDisjunctureEnter() {
    gsap.fromTo('.RectFWD', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  WeaponDisjunctureLeave() {
    if (this.isWeaponOpen != WeaponOpen.Disjuncture)
      gsap.fromTo('.RectFWD', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }

  IsMobile() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 1200) {
      return true;
    } else {
      return false;
    }
  }
}
