
<div class="container-fluid px-lg-5">
  <div class="row justify-content-center" *ngFor="let row of productsRows; index as i">
    <!-- PRODUCT -->
    <div *ngFor="let product of row; index as j" class="col-md p-2">
      <app-product [product]="product" [showDetail]="true"></app-product>
    </div>
  </div>

  <div class="pt-5">
    <p style="text-align:center">USE A CREATOR CODE</p>
    <form class="underlineWhite" style="width: 350px;margin: auto auto;position:relative">
      <div class="underline" style="position:relative;">
        <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
          <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(5 30) rotate(-45)" />
        </svg>
        <input type="text" (keyup)="keyup($event)" name="creatorcode" placeholder="Creator Code Here" style="margin-top: 5px;height:60px" class="form-control transparent-input" />
      </div>
      <span style="position:absolute;right:0;top:0" *ngIf="isWaitingReq" class="spinner-border spinner-border-sm mr-1"></span>
    </form>
    <div style="height: auto">
      <p [ngClass]="{'invalid': !isValid, 'wait': isWaitingReq, 'valid': isValid}" style="text-align:center;font-size:20px">{{Message}}</p>
    </div>
  </div>

</div>
