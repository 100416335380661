import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { GetSquaredImage, Product, DefaultProduct } from 'src/app/products-templated-list/products-templated-list.component';
import { reducedPrice } from 'src/app/product/product.component';
import { Router } from '@angular/router';

export interface OrderProduct {
  quantity: number; // not in json body

  product: Product;
}

export const DefaultOrderProduct: OrderProduct = {
  quantity: 1,
  product: DefaultProduct
}

enum OrderStatus {
  CREATED = 1,
  SHIPPED = 2
}

export interface Order {
  id: number;
  order_date: Date;
  status: OrderStatus;
  products: Array<OrderProduct>;
}

export const DefaultOrder: Order = {
  id: 2883928,
  order_date: new Date(),
  status: OrderStatus.CREATED,
  products: [ DefaultOrderProduct,DefaultOrderProduct,DefaultOrderProduct,DefaultOrderProduct,DefaultOrderProduct,DefaultOrderProduct,DefaultOrderProduct]
}

export function totalPrice(order: Order) {
  var sum = 0;
  for(var order_product of order.products)
  {
    sum += order_product.quantity * reducedPrice(order_product.product)
  }
  return sum;
}

export function ordersProductsToString(order: Order) {
  var str = "";
  var i = 0;
  for(var order_product of order.products)
  {
    if(i != 0)
    {
      str += ", ";
    }
    str += order_product.quantity + " " + order_product.product.name;
    i++;
  }
  return str;
}

export function orderStatusToString(orderStatus: OrderStatus)
  {
    var str = ""
    if(orderStatus == OrderStatus.CREATED)
      str = "CREATED";
    else if(orderStatus == OrderStatus.SHIPPED)
      str = "SHIPPED";
    return str;
  }

@Component({
  selector: 'app-orders-templated-list',
  templateUrl: './orders-templated-list.component.html',
  styleUrls: ['./orders-templated-list.component.css']
})
export class OrdersTemplatedListComponent implements OnInit, OnChanges {
  router: Router;

  constructor(router: Router) { 
    this.router = router;
  }

  ngOnInit(): void {
  }

  @Input()
  orders: Array<Order> = [];

  ordersCollapsed: Array<boolean> = [];

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    if("orders" in changes)
    {
      let change = changes["orders"];
      this.updateCollapsedList(change.currentValue);
    }

  }

  getImage(product: Product) {
    return GetSquaredImage(product);
  }

  orderPrice(order: Order){
    return totalPrice(order);
  }

  orderStatusToString(orderStatus: OrderStatus)
  {
    return orderStatusToString(orderStatus);
  }

  ordersProductsToString(order: Order)
  {
    return ordersProductsToString(order);
  }

  setOrderDetailsVisible(visible: boolean, index : number)
  {
    this.ordersCollapsed[index] = visible;
  }

  updateCollapsedList(orders: Array<Order>) : void
  {
    let newCollapsedList : Array<boolean> = new Array(orders.length);
    for (let i = 0; i < orders.length; i++) 
    {
      if(i >= 0 && i < this.ordersCollapsed.length)
        newCollapsedList[i] = this.ordersCollapsed[i];
      else
        newCollapsedList[i] = true;
    }
    this.ordersCollapsed = newCollapsedList;
  }

  ShowFullDetail() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width > 768) {
      return true;
    } else {
      return false;
    }
  }

  goToProductDescription(orderProduct: OrderProduct)
  {
    this.router.navigate(['/shop/' + orderProduct.product.id] )
  }
}
