import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy  } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { delay } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { Router, NavigationEnd } from '@angular/router';
import { LoginComponent, User, DefaultUser } from 'src/app/login/login.component';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})

export class AccountComponent implements OnInit, OnDestroy, AfterViewInit  {

  constructor(private observer: BreakpointObserver, private router: Router, private account: AccountService) 
  { 
    this.router.events.subscribe(event => 
    {
      if (event instanceof NavigationEnd) {this.account.checkAccountStatus()}
    });
  }
  user: User;
  userSub: Subscription;

  active = 1;
  @ViewChild('sidenav1', { static: true }) sidenavAccount: MatSidenav;

  toastStatus: number = 4; // 0:valid / 1:error / 2:spining / value>=4:hidden
  toastMessage: string;
  toastPromise: Promise<void>;

  ngOnInit(): void {
    this.initializeUser();
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  initializeUser() {
    this.user = DefaultUser
    this.userSub = this.account.userObservable.subscribe(
      (next: User) => {
        // Cette ligne est executée chaque fois qu'un nouvel user est emis par le subject
        if (next != null)
          this.user = next;
        else
          this.user = DefaultUser
      },
      (error) => {
        console.log(error);
      }
    )
  }

  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1))
      .subscribe((res) => {
        if (res.matches) {
          this.sidenavAccount.mode = 'over';
          this.sidenavAccount.close();
        } else {
          this.sidenavAccount.mode = 'side';
          this.sidenavAccount.open();
        }
      });
  }

  public async showToast(info: string, type: number) {
    if (this.toastPromise)
      this.toastPromise.finally();
    this.toastStatus = type;
    this.toastMessage = info;
    
    if (this.toastStatus < 2) { // is success or fail
      this.toastPromise = new Promise<void>(done => setTimeout(() => done(), 3000)); //wait & close
      await this.toastPromise;
      this.toastStatus = 4;
      this.toastMessage = "";
    }
  }

  closeSidenavIfMobile()
  {
    if(this.sidenavAccount.mode == 'over')
      this.sidenavAccount.close();
  }
}
