import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { Router, NavigationEnd  } from '@angular/router';

@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.css']
})
export class FrontPageComponent implements OnInit {

  constructor(private accountService: AccountService, private router: Router)
  {
    this.router.events.subscribe(event =>
      {
        if (event instanceof NavigationEnd)
        {
            //this.accountService.checkAccountStatus()
        }
      });
  }
  ngOnInit() {

  }
  IsMobile() { return window.innerWidth <= 1080; }
}
