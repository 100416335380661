<!--<img loading="lazy" src="./assets/UI_GoldenRatioGrid.jpg" style="width: 100vw; height: 100vh; align-self: start; position: fixed; pointer-events: none; z-index:99999999999999;opacity:0" />-->
<!-- [ngStyle]="{'opacity': navbarVisible ? '1' : '0' }"-->

<div class="content" role="main">
  <app-hud></app-hud>

  <app-home-video></app-home-video>
  <img loading="lazy" *ngIf="!IsMobile()" src="assets/assets_web_2/separateur.webp" alt="border" style="position: absolute; transform: translate(0%,100%); height: auto; width: 95%; left: 2.5%; bottom: 10px; pointer-events: none; z-index: 2">

  <app-about-video></app-about-video>
  <app-weapon-parallax></app-weapon-parallax>
  <app-magic-elements></app-magic-elements>
  <app-social-order></app-social-order>
  <app-gameplay-activities *ngIf="!IsMobile()"></app-gameplay-activities>
  <app-footer></app-footer>
</div>
