<div *ngIf="!isUserLoggedIn()">
  <h1 id="title" class="p-3">Login</h1>
  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" style="padding:20px">
    <div>
      <p style="margin:0">Username</p>

      <div class="underlineWhite">
        <div class="underline" style="position:relative">
          <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
            <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(5 15) rotate(-45)" />
          </svg>
          <input type="text" formControlName="username" name="username" style="margin-top: 5px;" class="form-control transparent-input" />
        </div>
      </div>

    </div>
    <div>
      <p style="margin:0; margin-top: 10px;">Password</p>

      <div class="underlineWhite">
        <div class="underline" style="position:relative">
          <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
            <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(5 15) rotate(-45)" />
          </svg>
          <input type="password" formControlName="password" name="password" style="margin-top: 5px;" class="form-control transparent-input" [ngClass]="{ 'is-invalid': f.password.errors && f.password.value }" />
        </div>
      </div>

      <div style="position:absolute;overflow:hidden;color:red;" *ngIf="f.password.errors && f.password.value">Password is too short</div>
    </div>
    <div style="padding:20px; margin-top: 15px" class="form-group">

      <div class="d-flex justify-content-center">

        <div class="underlineWhite" style="width: 100px;">
          <div class="underline" style="position:relative">
            <button type="submit" routerLinkActive="active" class="btn" style="cursor: pointer;padding:0;">
              <svg height="70px" width="120px">
                <text x="15px" fill="white" y="45px" style="font-size: 30px; left: 5vw; ">
                  <tspan>Login</tspan>
                </text>
              </svg>
            </button>
          </div>
        </div>

        <div class="underlineWhite" style="width: 130px;margin-left:20px">
          <div class="underline" style="position:relative">
            <button type="submit" routerLink="/register" class="btn" style="cursor: pointer;padding:0">
              <svg height="70px" width="150px">
                <text x="15px" fill="white" y="45px" style="font-size: 30px; left: 5vw; ">
                  <tspan>Register</tspan>
                </text>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mb-2">
        <button type="button" (click)="loginWithSteam()" class="btn btn-primary" style="border: 0; background: transparent; margin-top: 10px;">
          <img loading="lazy" alt="" src="https://steamcdn-a.akamaihd.net/steamcommunity/public/images/steamworks_docs/english/sits_small.png" width="153" height="23" />
        </button>
      </div>
      <div class="d-flex justify-content-center mb-2">
        <a (click)="passwordReset()" class="btn btn-link">Recover Password</a>
      </div>
    </div>
  </form>
  <span *ngIf="isWaitingReq" class="spinner-border spinner-border-sm mr-1"></span>
  <p>{{LogInMessage}}</p>
</div>

<div *ngIf="isUserLoggedIn()">
  <div class="mb-4 bg-transparent">
    <div class="-body text-center">
      <img loading="lazy" src='{{ "assets/Characters/" + this.user.icon_index + ".webp" }}' alt="avatar" class="rounded-circle img-fluid" style="width: 175px; margin-left: auto; margin-right: auto;" draggable="false">
      <p class="my-3" style="text-transform: uppercase;">{{user.username}}</p>

      <div class="d-flex justify-content-center mb-2" style="gap: 10px; ">
        <div class="underlineWhite" style="width: 140px;">
          <div class="underline" style="position:relative">
            <button routerLink="/account" type="button" class="btn" style="cursor: pointer;padding:0;">
              <svg height="70px" width="140px">
                <text x="15px" fill="white" y="45px" style="font-size: 30px; left: 5vw; ">
                  <tspan>Account</tspan>
                </text>
              </svg>
            </button>
          </div>
        </div>

        <div class="underlineWhite" style="width: 120px;margin-left:20px">
          <div class="underline" style="position:relative">
            <button (click)="logout()" class="btn" style="cursor: pointer;padding:0;">
              <svg height="70px" width="120px">
                <text x="15px" fill="white" y="45px" style="font-size: 30px; left: 5vw; ">
                  <tspan>Logout</tspan>
                </text>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</div>
