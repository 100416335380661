import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { User } from './login/login.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
    const user: User = JSON.parse(localStorage.getItem('user')!);
    if (user === null) 
    {
      var csrftoken = this.getCookie('csrftoken')
      if (csrftoken === null)
      {
        alert('You are not allowed to view this page. You are redirected to home Page');
        this.router.navigate(["/"], { queryParams: { retUrl: route.url } });
        return false;
      }
    }

    return true;
  }

  getCookie(name) {
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)')); 
    return match ? match[1] : null;
  }
}
