

<form (ngSubmit)="onSubmitSubscribe()" class="form-inline" style="font-size:1vw;">
  <div class="underlineWhite">
    <div class="input-group underline">
      <input class="form-control transparent-input " [formControl]="subscribe_email" required type="text" placeholder="Join our newsletter : email" aria-label="Recipient's username">

      <button class="btn btn-link" type="submit" style="cursor: pointer;" aria-label="Subscribe">
        <svg height="66px" width="250px">
          <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
          <rect [ngStyle]="{'opacity': !subscribe_email.invalid ? '1' : '0'}" class="RectFQ" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
          <text x="60px" fill="white" y="45px" style="font-size: 1.2rem; left: 5vw; ">
            <tspan>Subscribe</tspan>
          </text>
        </svg>
      </button>

    </div>
  </div>
  <p style="position: absolute; margin-top: -20px; margin-left: 15px; font-size: 1rem; " *ngIf="subscribe_email.invalid  && (subscribe_email.value || hasClickOnSubscribe)">
    {{getErrorMessage()}}
  </p>
</form>
