<div class="content" role="main">
  <app-hud [displaySocialLinks]="false"></app-hud>
  <div style="min-height: 100vh; background-image: url(assets/assets_web_2/bg-dashboard.webp); background-position: bottom; position: relative; background-repeat: no-repeat; background-size: cover; padding-top: 75px ">
    <div class="col text-center">
      <div class="py-4 py-sm-2">
        <h1 class="my-auto" id="title">Pre-Order</h1>
      </div>

      <div class="d-flex justify-content-center" *ngIf="false">
        <div class="input-group" style="min-width: 30%; max-width: max(40%,400px); height:20px">
          <input class="form-control" required type="text" placeholder="Search" aria-label="Recipient's username">
          <button class="btn btn-outline-primary" type="submit" style="margin-right: 5px;"><i class="fa-solid fa-magnifying-glass"></i></button>

          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-primary" id="sortDropdown" ngbDropdownToggle>Sort</button>
            <!-- SORT MENU -->
            <div ngbDropdownMenu aria-labelledby="sortDropdown">
              <button (click)="setCurrentSortType(ProductSortType.PricesFromLowToHigh)" ngbDropdownItem [ngClass]="{ 'active' : currentSortType === ProductSortType.PricesFromLowToHigh}">
                {{productSortTypeToString(ProductSortType.PricesFromLowToHigh)}}
              </button>
              <button (click)="setCurrentSortType(ProductSortType.PricesFromHighToLow)" ngbDropdownItem [ngClass]="{ 'active' : currentSortType === ProductSortType.PricesFromHighToLow}">
                {{productSortTypeToString(ProductSortType.PricesFromHighToLow)}}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="shopDisabled" style="margin-left: auto; margin-right: auto">
        <div style="text-align:center" class="alert alert-danger" role="alert">
          Shop disabled for now.
        </div>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <button routerLink="/" class="btn btn-primary">Home</button>
            </div>
          </div>
        </div>
      </div>

      <div class="alert alert-light small p-2" *ngIf="success == true">
        Thank you for your purchase
      </div>
      <div class="d-flex justify-content-center" *ngIf="!shopDisabled">
        <app-products-templated-list [products]="listProducts" [productSortType]="currentSortType" style="width: 100vw; max-width: 1770px">
        </app-products-templated-list>
      </div>

    </div>
  </div>
  <div class="d-flex justify-content-center ">
    <p class="px-3 px-lg-0 py-5" style="text-align:center">SoulCast is a game currently in <strong>development phase.</strong><br /> Support us by buying one of our supporter pack.<br /> Gain an exclusive access to the game, earn exclusive cosmetics.<br />Become a SoulCaster !</p>
  </div>
  <app-footer></app-footer>
</div>
