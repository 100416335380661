
<div class="modal-body" style="background-image: url(assets/assets_web_2/SHOP/SHOPModal.png); background-size: 100% 100%; ">
  <div class="p-2">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <p style="text-align:center">You must accept terms and conditions to proceed</p>

    <div class="row justify-content-center">
      <div class="underlineWhite" style="width: 320px; cursor: pointer; padding:0">
        <div class="underline">
          <a (click)="activeModal.dismiss('Cross click');" routerLink="/terms-and-conditions">
            <svg height="66px" width="320px">
              <text x="15px" fill="white" y="40px" style="font-size: 30px; left: 5vw; ">
                <tspan>Terms And Conditions</tspan>
              </text>
            </svg>
          </a>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">

      <div class="underlineWhite" style="width: 150px; cursor: pointer; padding:0">
        <div class="underline">
          <a (click)="activeModal.dismiss('Cross click');AcceptTermsAndConditions();">
            <svg height="66px" width="150px">
              <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(15 33) rotate(-45)" />
              <text x="45px" fill="white" y="40px" style="font-size: 30px; left: 5vw; ">
                <tspan>Accept</tspan>
              </text>
            </svg>
          </a>
        </div>
      </div>

      <div class="underlineWhite" style="width: 150px; cursor: pointer; padding: 0; margin-left: 30px ">
        <div class="underline">
          <a (click)="activeModal.dismiss('Cross click')">
            <svg height="66px" width="150px">
              <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(15 33) rotate(-45)" />
              <text x="45px" fill="white" y="40px" style="font-size: 30px; left: 5vw; ">
                <tspan>Decline</tspan>
              </text>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

