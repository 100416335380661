
<table class="table">
  <thead class="">
    <tr>
      <th style="max-width: 35px;"></th>
      <th>Order</th>
      <th *ngIf="ShowFullDetail()">Date</th>
      <th>status</th>
      <th>Total</th>
      <th>Details</th>
    </tr>
  </thead>
  <tbody class="table-body cell-1 " *ngFor="let order of orders; index as i">
    <tr>
      <td style="max-width: 35px;">
        <button (click)="setOrderDetailsVisible(!ordersCollapsed[i], i)" style="background-color: transparent; border: none;">
          <div *ngIf="ordersCollapsed[i]">
            <svg width="35px" height="35px"><image loading="lazy" href="assets/assets_web_2/angle-right-rounded.svg" alt="Icon" width="30px" height="30px" /></svg>
          </div>
          <div *ngIf="!ordersCollapsed[i]">
            <svg width="35px" height="35px"><image loading="lazy" href="assets/assets_web_2/angle-bottom-rounded.svg" alt="Icon" width="30px" height="30px" /></svg>
          </div>
        </button>
      </td>
      <td>{{order.id}}</td>
      <td *ngIf="ShowFullDetail()">{{order.order_date | date :'short'}}</td>
      <td><div class="d-flex justify-content-center align-items-center"><span class="badge" [ngClass]="{'bg-success': orderStatusToString(order.status)=='SHIPPED', 'bg-info': orderStatusToString(order.status)=='CREATED' }">{{orderStatusToString(order.status)}}</span></div></td>
      <td><div class="d-flex justify-content-center align-items-center">{{orderPrice(order)}} EUR</div></td>
      <td>
          <div class="d-flex align-items-center">
            <div class="flex-fill">
              {{ordersProductsToString(order)}}
            </div>
          </div>
      </td>
    </tr>
    <tr *ngIf="!ordersCollapsed[i]" class="colapsedOrder" style="max-width: fit-content;">
      <td colspan="6">
        <div class="row">
          <div *ngFor="let product of order.products" class="col p-2">
            <div class="d-flex align-items-center">
              <svg height="66px" width="66px">
                <rect id="Rectangle_60" loading="lazy" alt="Icon" width="10px" height="10px" transform="translate(15 33) rotate(-45)" />
              </svg>
              <span style="color: white;">
                {{product.quantity}}
              </span>
              <button (click)="goToProductDescription(product)" style="background-color: transparent; border: none;">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="d-flex flex-column" style="gap:5px">
                    <img loading="lazy" src="{{getImage(product.product)}}" alt="avatar" class="img-fluid" style="width: min(200px,80vw); height: auto">
                    <span style="white-space: nowrap; color: white;">
                      {{product.product.name}}
                    </span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
