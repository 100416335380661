<div class="d-flex px-5 justify-content-center">
  <div class="col-lg-12">
    <p class="my-3">PERSONAL INFORMATIONS</p>
    <div class=" my-2">
      <div class="-body text-center">
        <form *ngIf="!user.first_name" [formGroup]="NameForm" (ngSubmit)="onSubmitNameForm()">
          <div class="-body text-center">
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label for="firstName">First Name</label>
                  <div class="underlineWhite">
                    <div class="underline">
                      <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
                        <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(2 15) rotate(-45)" />
                      </svg>
                      <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submittedNameForm && nameform.firstName.errors }" />
                    </div>
                  </div>
                  <div *ngIf="submittedNameForm && nameform.firstName.errors" class="invalid-feedback">
                    <div *ngIf="nameform.firstName.errors.required">First Name is required</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label for="lastName">Last Name</label>
                  <div class="underlineWhite">
                    <div class="underline">
                      <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
                        <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(2 15) rotate(-45)" />
                      </svg>
                      <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submittedNameForm && nameform.lastName.errors }" />
                    </div>
                  </div>
                  <div *ngIf="submittedNameForm && nameform.lastName.errors" class="invalid-feedback">
                    <div *ngIf="nameform.lastName.errors.required">Last Name is required</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group" style="margin-top: 2vh">
                  <button type="submit" [disabled]="isWaitingNameReq || !NameForm.valid" class="btn btn-sm btn-primary">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div *ngIf="user.first_name" class="row">
          <div class="col-sm-3">
            <p class="mb-0">Full Name</p>
          </div>
          <div class="col-sm-9">
            <p class="text-muted mb-0">{{user.first_name}} {{user.last_name}}</p>
          </div>
        </div>
      </div>
    </div>

    <p class="my-3">MAIL INFORMATION</p>

    <div class=" mb-12 my-2">
      <div class="-body text-center">
        <div class="row justify-content-center">

          <div *ngIf="user.email">
            <div class="col-sm-3 m-auto">
              <p class="mb-0">Email</p>
            </div>
            <div class="col-lg-3 m-auto">
              <p class="text-muted mb-0">{{user.email}}</p>
              <p *ngIf="!user.has_verified_email" class="text-warning mb-0">Please confirm your email address</p>
            </div>
            <div class="col-lg">
              <button *ngIf="user.has_verified_email" (click)="changeEmail()" type="button" class="btn btn-primary btn-sm mx-1">Update mail</button>
              <button *ngIf="!user.has_verified_email" (click)="SendMailActivation()" type="button" class="btn btn-primary btn-sm mx-1">Send confirmation again</button>
              <button *ngIf="!user.has_verified_email" (click)="ClearUnverifiedEmail()" type="button" class="btn btn-primary btn-sm mx-1">Reset Email</button>
            </div>
          </div>
          
          <div *ngIf="!user.email">

            <form *ngIf="!user.email" [formGroup]="EmaiForm" (ngSubmit)="SetEmail()">
              <div class="-body text-center">
                <div class="row">
                  <div class="col-sm-8">
                    <div class="form-group">
                      <label for="Email">Email</label>

                      <div class="underlineWhite">
                        <div class="underline">
                          <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
                            <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(2 15) rotate(-45)" />
                          </svg>
                          <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submittedEmailForm && emailform.email.errors }" />
                        </div>
                      </div>
                      
                      <div *ngIf="submittedEmailForm && nameform.firstName.errors" class="invalid-feedback">
                        <p *ngIf="emailform.email.errors.required">Email is required</p>
                        <p *ngIf="!emailform.email.errors.valid">Email is invalid</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group" style="margin-top: 2vh">
                      <button type="submit" [disabled]="isWaitingSetMailReq || !EmaiForm.valid" class="btn btn-primary btn-sm">Set mail</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
        <div *ngIf="user.email">
          <hr>
          <div class="form-check form-switch">
            <input [disabled]="isWaitingMailReq" class="form-check-input" style="border-color:red" [ngStyle]="user.has_subscribed_to_newsletter?{'border-style':'none'} : {'border-style':'solid'}" (change)="Susribe($event);" [checked]="user.has_subscribed_to_newsletter" type="checkbox" id="flexSwitchCheckChecked" checked>
            <label class="form-check-label" for="flexSwitchCheckChecked">subscribe to Newsletter</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
