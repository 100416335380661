import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators, ReactiveFormsModule } from "@angular/forms";
import { User } from 'src/app/login/login.component';
import { Observable, Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { AccountComponent } from 'src/app/account/account.component'; // toast
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {

  user: User;
  userSub: Subscription;
  // name
  public NameForm: FormGroup;
  private firstName: FormControl;
  private lastName: FormControl;
  submittedNameForm: boolean = false;
  nameform;
  isWaitingNameReq: boolean = false;

  public EmaiForm: FormGroup;
  private Email: FormControl;
  emailform;
  submittedEmailForm: boolean = false;
  isWaitingSetMailReq: boolean = false;
  // tabs
  sideTab = 'Account_Informations';

  // mail
  hassubscribeLastValidState: boolean = true;
  isWaitingMailReq: boolean = false;


  constructor(private formBuilder: FormBuilder, private accountService: AccountService, private accountComponent: AccountComponent)
  {
    this.firstName = new FormControl('', [Validators.required]);
    this.lastName = new FormControl('', [Validators.required]);
    this.NameForm = formBuilder.group({
      firstName: this.firstName,
      lastName: this.lastName,
    })
    this.nameform = this.NameForm.controls;
    this.initializeUser();

    this.Email = new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$')]))
    this.EmaiForm = formBuilder.group({
      email: this.Email,
    })
    this.emailform = this.EmaiForm.controls;
  }

  ngOnInit(): void {
  }

  initializeUser() {
    this.userSub = this.accountService.userObservable.subscribe(
      (next: User) => {
        this.user = next;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  onSubmitNameForm() {
    this.submittedNameForm = true;
    // stop here if form is invalid
    if (this.NameForm.invalid) {
      return;
    }
    this.isWaitingNameReq = true;
    var toastStr = "Updating Name";
    this.accountComponent.showToast(toastStr, 2);
    // http req
    this.accountService.updateAccountOwner(this.NameForm.value.firstName, this.NameForm.value.lastName)
    .subscribe(
      (response) => {
        toastStr = "Your account name is updated";
        this.accountComponent.showToast(toastStr, 0);
        this.isWaitingNameReq = false;
      },
      (error) => {//Error callback
        console.log(error);
        toastStr = "Updating Name ";
        this.isWaitingNameReq = false;
        toastStr += this.accountService.getErrorStr(error.status);
        this.accountComponent.showToast(toastStr, 1);
      }
    );
  }

  Susribe(arg) {

    this.user.has_subscribed_to_newsletter = arg.target.checked;
    var toastStr = this.user.has_subscribed_to_newsletter ? "subscribe" : "Unsubscribe";
    this.accountComponent.showToast(toastStr, 2);
    this.isWaitingMailReq = true;
    //call http service
    this.accountService.subscribeAccountToNewsLetter(this.user.has_subscribed_to_newsletter)
      .subscribe(
        (response) => {
          toastStr = this.user.has_subscribed_to_newsletter ? "you have subscribe to mail" : "you have unsubscribe to mail";
          this.accountComponent.showToast(toastStr, 0);
          this.hassubscribeLastValidState = this.user.has_subscribed_to_newsletter;
        },
        (error) => {//Error callback
          toastStr = "";
          this.accountComponent.showToast(toastStr, 1);
          arg.target.checked = this.hassubscribeLastValidState;
        }
      );
    this.isWaitingMailReq = false;
  }

  changeEmail() {
    location.href = environment.apiURL + "/email-change/";
  }

  SetEmail() {
    if (this.EmaiForm.invalid || this.isWaitingSetMailReq) {
      return;
    }
    this.isWaitingSetMailReq = true;
    var toastStr = "Adding Email address";
    this.accountComponent.showToast(toastStr, 2);

    this.accountService.SetEmail(this.EmaiForm.value.email)
      .subscribe(
        (response) => {
          toastStr = "Your email is set";
          this.accountComponent.showToast(toastStr, 0);
          this.isWaitingSetMailReq = false;
        },
        (error) => {//Error callback
          toastStr = "Fail to set your Email";
          this.accountComponent.showToast(toastStr, 1);
          this.isWaitingSetMailReq = false;
        }
      );
  }

  SendMailActivation() {
    this.isWaitingSetMailReq = true;
    var toastStr = "Sending confirmation mail";
    this.accountComponent.showToast(toastStr, 2);

    this.accountService.SendMailActivation()
      .subscribe(
        (response) => {
          toastStr = "Email sended";
          this.accountComponent.showToast(toastStr, 0);
          this.isWaitingSetMailReq = false;
        },
        (error) => {//Error callback
          toastStr = "Fail to to send email";
          this.accountComponent.showToast(toastStr, 1);
          this.isWaitingSetMailReq = false;
        }
      );

  }

  ClearUnverifiedEmail() {
    console.log(this.user);
    if (this.user.has_verified_email || this.isWaitingSetMailReq)
      return;

    this.isWaitingSetMailReq = true;
    var toastStr = "Cleaning Email address";
    this.accountComponent.showToast(toastStr, 2);

    this.accountService.ClearUnverifiedEmail()
      .subscribe(
        (response) => {
          toastStr = "Your email is is cleared";
          this.accountComponent.showToast(toastStr, 0);
          this.isWaitingSetMailReq = false;
        },
        (error) => {//Error callback
          toastStr = "Fail to clear your Email";
          this.accountComponent.showToast(toastStr, 1);
          this.isWaitingSetMailReq = false;
        }
      );
  }

}
