
<div class='scrolltop'>
  <div style="text-align: center;">

    <div *ngIf="ShowParalax()" style="display: flex; justify-content: center; position: relative; pointer-events: none; z-index: 100 ">
      <svg width="min(600px,90vw)" height="50px" max-width="100vw" preserveAspectRatio="xMaxYMax">
        <image loading="lazy" href="assets/assets_web_2/Ornement-v2.svg" alt="Timeline" width="100%" height="100%" />
      </svg>
    </div>

    <h1 class="my-auto pt-5 pt-lg-2" id="title"> Defy the Social Order </h1>
    <p style="padding-top:5vh;" class="mx-4 mx-lg-0">
      Abaddon is ruled by a strict hierarchy, but beneath the surface<br>
      its social classes are locked in a violent power struggle.<br>
      Take advantage of the ongoing conflict by playing all sides: <br>
      Forge alliances, exploit rivalries and work your way up in the hierarchy.<br>
    </p>
    <p class="Brave" style="padding-top:1vh;height:10vh">
    </p>
  </div>
</div>

<div class="scrollDist" #scroll *ngIf="ShowParalax()">

  <svg viewBox="0 0 2000 2200">

    <image loading="lazy" y="0" class="sky" href="assets/PARALAXE/PLAN8.webp" width="2000" height="1100" />
    <image loading="lazy" y="0" href="assets/border3.webp" width="2000" height="100" preserveAspectRatio="none" />

    <a style="cursor: pointer;" id="DownA" (click)="triggerAnim6()">
      <image loading="lazy" y="100" x="1600" href="assets/DownArrow.webp" width="150" height="150" />
    </a>

    <image loading="lazy" y="670px" x="665" class="layer7" href="assets/PARALAXE/PLAN7.webp" width="710" />

    <image loading="lazy" y="668px" x="0" class="layer6" href="assets/PARALAXE/PLAN6.webp" width="2000" />

    <image loading="lazy" y="565px" x="1300" class="layer5" href="assets/PARALAXE/PLAN5.webp" width="670" />

    <image loading="lazy" y="1360px" x="920" class="layer4" href="assets/PARALAXE/PLAN4.webp" width="950" />

    <image loading="lazy" y="720px" x="-3" class="layer3" href="assets/PARALAXE/PLAN3.webp" width="1780" />

    <image loading="lazy" y="1250px" x="-75" fill="2c3d38" class="cloud2" xlink:href="assets/PARALAXE/cloud2.webp" width="1000" height="800" opacity="0.1" />

    <image loading="lazy" y="1140" x="383" class="layer2" href="assets/PARALAXE/PLAN2.webp" width="1355" height="850" />

    <image loading="lazy" y="665" x="0" class="layer1" href="assets/PARALAXE/PLAN1.webp" width="2000" height="1400" />


    <g>
      <foreignObject class="scroll5 scroll SVGB" (click)="triggerAnim5()" (mouseenter)="PlebeiansMouseEnter()" (mouseleave)="PlebeiansMouseLeave()" x="500" y="1800px" width="400" height="160">
        <svg>
          <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
          <rect style="opacity:0" class="RectFP" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
          <text x="60px" fill="white" y="45px" style="font-size: 40px; left: 5vw; ">
            <tspan>Plebeians</tspan>
          </text>
        </svg>
      </foreignObject>

      <g class="paper5 paper" (click)="triggerAnim5()">
        <foreignObject x="200" y="1400" width="600" height="750" >
          <div class="bannerDiv" style="background-image: url(assets/assets_web_2/LORE/PLEBS.webp); "></div>
        </foreignObject>
        <text fill="white" y="1850px" style="font-size: 22px; left: 5vw; font-style: italic;">
          <tspan x="250px" dy="2.2em">The popular classes live at the bottom of the</tspan>
          <tspan x="250px" dy="1.2em">city in slums plagued by poverty and violence.</tspan>
          <tspan x="250px" dy="1.2em">Despite their low status in society, they hold </tspan>
          <tspan x="250px" dy="1.2em">power that even the higher classes fear.</tspan>
          <tspan x="250px" dy="2.2em">Will you pledge yourself to their cause?</tspan>
        </text>
      </g>

      <foreignObject class="scroll4 scroll SVGB" (click)="triggerAnim4()" (mouseenter)="OligarchyMouseEnter()" (mouseleave)="OligarchyMouseLeave()" x="1400" y="1500px" width="400" height="160">
        <svg>
          <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
          <rect style="opacity:0" class="RectFO" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
          <text x="60px" fill="white" y="45px" style="font-size: 40px; left: 5vw; ">
            <tspan>Oligarchy</tspan>
          </text>
        </svg>
      </foreignObject>

      <g class="paper4 paper" (click)="triggerAnim4()">
        <foreignObject x="1150" y="1150" width="600" height="750">
          <div class="bannerDiv" style="background-image: url(assets/assets_web_2/LORE/OLIGARCH.webp); "></div>
        </foreignObject>
        <text fill="white" y="1600px" style="font-size: 22px; left: 15vw; font-style: italic;">
          <tspan x="1200px" dy="2.2em">Although they live with the plebs in the lower</tspan>
          <tspan x="1200px" dy="1.2em">quarters, the oligarchs have high aspirations</tspan>
          <tspan x="1200px" dy="1.2em">thanks to their money and influence.</tspan>
          <tspan x="1200px" dy="2em">Plunder their riches in daring heists</tspan>
          <tspan x="1200px" dy="1.2em">or protect their business for a price.</tspan>
          <tspan x="1200px" dy="1.2em">Which path will you choose?</tspan>
        </text>
      </g>

      <foreignObject class="scroll3 scroll SVGB" (click)="triggerAnim3()" (mouseenter)="GuardiansMouseEnter()" (mouseleave)="GuardiansMouseLeave()" x="500px" y="1200px" width="400" height="160">
        <svg>
          <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
          <rect style="opacity:0" class="RectFG" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
          <text x="60px" fill="white" y="45px" style="font-size: 40px; left: 5vw; ">
            <tspan>Guardians</tspan>
          </text>
        </svg>
      </foreignObject>

      <g class="paper3 paper" (click)="triggerAnim3()">
        <foreignObject x="200" y="750" width="600" height="750" >
          <div class="bannerDiv" style="background-image: url(assets/assets_web_2/LORE/GUARDIANS.webp); "></div>
        </foreignObject>
        <text fill="white" y="1200px" style="font-size: 22px; left: 5vw; font-style: italic;">
          <tspan x="250px" dy="2.2em">The Guardians were founded to keep the peace</tspan>
          <tspan x="250px" dy="1.2em">and defend the city from external threats,</tspan>
          <tspan x="250px" dy="1.2em">but there are those in their ranks with higher</tspan>
          <tspan x="250px" dy="1.2em">ambitions.</tspan>
          <tspan x="250px" dy="2em">Serve in their ranks as a shining paragon</tspan>
          <tspan x="250px" dy="1.2em">of valor or choose an obscure path.</tspan>
          <tspan x="250px" dy="1.2em">The choice is yours.</tspan>
        </text>
      </g>

      <foreignObject class="scroll2 scroll SVGB" (click)="triggerAnim2()" (mouseenter)="ClergyMouseEnter()" (mouseleave)="ClergyMouseLeave()" x="1400px" y="800px" width="400" height="160">
        <svg>
          <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
          <rect style="opacity:0" class="RectFC" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
          <text x="60px" fill="white" y="45px" style="font-size: 40px; left: 5vw; ">
            <tspan>Clergy</tspan>
          </text>
        </svg>
      </foreignObject>

      <g class="paper2 paper" (click)="triggerAnim2()">
        <foreignObject x="1150" y="500" width="600" height="750" >
          <div class="bannerDiv" style="background-image: url(assets/assets_web_2/LORE/CLERGY.webp); "></div>
        </foreignObject>
        <text fill="white" y="950px" style="font-size: 22px; left: 5vw; font-style: italic;">
          <tspan x="1200px" dy="2.2em">The Clergy is the religious authority in the</tspan>
          <tspan x="1200px" dy="1.2em">City, but their power is not only rooted in faith.</tspan>
          <tspan x="1200px" dy="1.2em">With the help of the inquisitors, they control</tspan>
          <tspan x="1200px" dy="1.2em">the use of magic in the city.</tspan>
          <tspan x="1200px" dy="2em">Will you adhere to the Liturgy of the Arcane</tspan>
          <tspan x="1200px" dy="1.2em">or be branded a heretic?</tspan>
        </text>
      </g>

      <foreignObject class="scroll1 scroll SVGB" (click)="triggerAnim1()" (mouseenter)="NobilityMouseEnter()" (mouseleave)="NobilityMouseLeave()" x="500px" y="400px" width="400" height="160">
        <svg>
          <rect id="Rectangle_60" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
          <rect style="opacity:0" class="RectFN" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 33) rotate(-45)" />
          <text x="60px" fill="white" y="45px" style="font-size: 40px; left: 5vw; ">
            <tspan>Nobility</tspan>
          </text>
        </svg>
      </foreignObject>

      <g class="paper1 paper" (click)="triggerAnim1()">
        <foreignObject x="200" y="100" width="600" height="750" >
          <div class="bannerDiv" style="background-image: url(assets/assets_web_2/LORE/NOBILITY.webp);"></div>
        </foreignObject>
        <text x="200px" fill="white" y="550px" style="font-size: 22px; left: 5vw; font-style: italic;">
          <tspan x="250px" dy="2.2em">The noble families of Abaddon claim to trace</tspan>
          <tspan x="250px" dy="1.2em">back to times of myth when the first mortals</tspan>
          <tspan x="250px" dy="1.2em">helped defeat the forces of chaos. These times</tspan>
          <tspan x="250px" dy="1.2em">are long gone and the nobility’s grasp on</tspan>
          <tspan x="250px" dy="1.2em">power is more fragile than ever.</tspan>
          <tspan x="250px" dy="2em">Will you prove yourself worthy before them</tspan>
          <tspan x="250px" dy="1.2em">or will you challenge their authority?</tspan>
        </text>
      </g>
    </g>

    <image loading="lazy" y="0" x="0" href="assets/border3.webp" width="2000" height="100" preserveAspectRatio="none" transform="scale(1, -1) translate(0, -2200)" />
  </svg>

</div>

<div *ngIf="!ShowParalax()">
  <div style="background-image: url(assets/Characters/NOBILITY.webp); background-position: center; height: 500px; position: relative; background-repeat: no-repeat; background-size: cover;">
    <div class='container h-100'>
      <div class='row h-100 align-items-end'>
        <div class='col mx-4 pb-3' style="z-index:1">
          <h1>Nobility</h1>
          <p style="filter: drop-shadow(2px 2px 2px #000000);">
            The noble families of Abaddon claim to trace
            back to times of myth when the first mortals
            helped defeat the forces of chaos. These times
            are long gone and the nobility’s grasp on
            power is more fragile than ever.
            <br>
            Will you prove yourself worthy before them
            or will you challenge their authority?
          </p>
        </div>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; position: relative; pointer-events: none; z-index: 100 ">
    <svg style="position: absolute; transform: translate(0,-50%); " width="min(600px,90vw)" height="50px" max-width="100vw" preserveAspectRatio="xMaxYMax">
      <image loading="lazy" href="assets/assets_web_2/Ornement-v2.svg" alt="Timeline" width="100%" height="100%" />
    </svg>
  </div>
  <div style="background-image: url(assets/Characters/PRETRE.webp); background-position: center; height: 500px; position: relative; background-repeat: no-repeat; background-size: cover;">
    <div class='container h-100'>
      <div class='row h-100 align-items-end'>
        <div class='col mx-4 pb-3' style="z-index:1">
          <h1>Clergy</h1>
          <p style="filter: drop-shadow(2px 2px 2px #000000);">
            The Clergy is the religious authority in the City,
            but their power is not only rooted in faith.
            With the help of the inquisitors, they control
            the use of magic in the city.
            <br />
            Will you adhere to the Liturgy of the Arcane
            or be branded a heretic?
          </p>
        </div>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; position: relative; pointer-events: none; z-index: 100 ">
    <svg style="position: absolute; transform: translate(0,-50%); "width="min(600px,90vw)" height="50px" max-width="100vw" preserveAspectRatio="xMaxYMax">
      <image loading="lazy" href="assets/assets_web_2/Ornement-v2.svg" alt="Timeline" width="100%" height="100%" />
    </svg>
  </div>
  <div style="background-image: url(assets/Characters/GARDIAN.webp); background-position: center; height: 500px; position: relative; background-repeat: no-repeat; background-size: cover;">
    <div class='container h-100'>
      <div class='row h-100 align-items-end'>
        <div class='col mx-4 pb-3' style="z-index:1">
          <h1>Guardians</h1>
          <p style="filter: drop-shadow(2px 2px 2px #000000);">
            The Guardians were founded to keep the peace and defend
            the city from external threats, but there are those in their
            ranks with higher ambitions.
            <br />
            Serve in their ranks as a shining paragon of valor
            or choose an obscure path – the choice is yours.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; position: relative; pointer-events: none; z-index: 100 ">
    <svg style="position: absolute; transform: translate(0,-50%); " width="min(600px,90vw)" height="50px" max-width="100vw" preserveAspectRatio="xMaxYMax">
      <image loading="lazy" href="assets/assets_web_2/Ornement-v2.svg" alt="Timeline" width="100%" height="100%" />
    </svg>
  </div>
  <div style="background-image: url(assets/Characters/OLIGARCH.webp); background-position: center; height: 500px; position: relative; background-repeat: no-repeat; background-size: cover;">
    <div class='container h-100'>
      <div class='row h-100 align-items-end'>
        <div class='col mx-4 pb-3' style="z-index:1">
          <h1>Oligarchy</h1>
          <p style="filter: drop-shadow(2px 2px 2px #000000);">
            Although they live with the plebs in the lower
            quarters, the oligarchs have high aspirations
            thanks to their money and influence.
            Plunder their riches in daring heists
            or protect their business for a price.
            <br/>
            Which path will you choose?
          </p>
        </div>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; position: relative; pointer-events: none; z-index: 100 ">
    <svg style="position: absolute; transform: translate(0,-50%); " width="min(600px,90vw)" height="50px" max-width="100vw" preserveAspectRatio="xMaxYMax">
      <image loading="lazy" href="assets/assets_web_2/Ornement-v2.svg" alt="Timeline" width="100%" height="100%" />
    </svg>
  </div>
  <div style="background-image: url(assets/Characters/PLEBS.webp); background-position: center; height: 500px; position: relative; background-repeat: no-repeat; background-size: cover;">
    <div class='container h-100'>
      <div class='row h-100 align-items-end'>
        <div class='col mx-4 pb-3' style="z-index:1">
          <h1>Plebs</h1>
          <p style="filter: drop-shadow(2px 2px 2px #000000);">
            The popular classes live at the bottom of the city
            in slums plagued by poverty and violence.
            Despite their low status in society, they hold
            power that even the higher classes fear.
            <br />
            Will you pledge yourself to their cause?
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
