<div class="d-flex px-5 justify-content-center">
  <div class="col-lg-12">
    <p class="my-3">ASSISTANCE</p>
    <div class=" my-sm-2">
      <div class="-body text-left">
        <form class="d-flex" [formGroup]="ContactForm" (ngSubmit)="onSubmit()">

          <div class="col-lg-4">
            <p class="h4 mb-4">Contact us</p>
            <div class="raw">
              <div class="col-sm-3">
                <!-- Name -->
                <input *ngIf="user.first_name === undefined" type="text" formControlName="ContactName"
                       class="form-control mb-4" placeholder="Name">
              </div>
              <div class="col-sm-3">
                <!-- Email -->
                <input *ngIf="user.email === undefined" type="email" formControlName="ContactEmail"
                       class="form-control mb-4" placeholder="E-mail">
              </div>
            </div>

            <div class="d-flex pb-2 justify-content-left">
              <div class="raw">
                <div *ngIf="user.first_name !== undefined" class="pb-2">
                  <p style="display: inline">Name : </p>
                  <p class="text-muted m-0 pl-3" style="display: inline">{{user.first_name}} {{user.last_name}}</p>
                </div>
                <div *ngIf="user.email !== undefined">
                  <p style="display: inline">Response mail : </p>
                  <p class="text-muted m-0 pl-3" style="display: inline">{{user.email}}</p>
                </div>
              </div>
            </div>
            <!-- Subject -->
            <label class="pe-2">Subject</label>
            <select formControlName="ContactSubject">
              <option value="" disabled>Choose Subject</option>
              <option *ngFor="let supportSubjectType of supportSubjectTypes" [value]="supportSubjectType">
                {{SupportSubjectToLabelMapping[supportSubjectType]}}
              </option>
            </select>

            <div class="form-check pt-2 d-flex justify-content-left">
              <input class="form-check-input me-2" type="checkbox" value="" checked>
              <label class="form-check-label" for="flexCheckChecked">
                Send me a copy of this message
              </label>
            </div>

            <div class="pt-2 align-self-center">
              <button type="submit" [disabled]="!this.ContactForm.valid" class="btn btn-sm btn-primary">
                Submit
              </button>
            </div>

          </div>
          <div class="col-lg-8">
            <!-- Message -->
            <div class="form-group">
              <textarea style=" min-width: 100%; max-width: 100%; min-height: 25vh; height: 100%; width: 100%; line-height: 1.2;" formControlName="ContactMessage" class="form-control rounded-0 pt-2"
                        rows="3" placeholder="Message"></textarea>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
