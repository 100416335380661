<div class="d-flex justify-content-center">
  <div class="col">
    <p class="px-5 my-3">ORDERS</p>
    <div class="">
      <div class="text-center px-2" style="width: 100%; min-height: 500px; height: fit-content; ">
        <app-orders-templated-list [orders]="listOrders">
        </app-orders-templated-list>
      </div>
    </div>
  </div>
</div>
