import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.css']
})
export class ThanksComponent implements OnInit {
  showCharacters = false;

  ngOnInit() {
    if (window.screen.width > 1660) {
      this.showCharacters = true;
    }
  }

  @HostListener("window:resize", [])
  onResize() {
    var width = window.screen.width;
    this.showCharacters = width > 1660;
  }
}
