import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/login/login.component';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validator, Validators,FormControl } from "@angular/forms";
import { AccountService, SupportSubject, SupportSubjectToLabelMapping } from 'src/app/services/account.service';
import { AccountComponent } from 'src/app/account/account.component'; // toast

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';


@Component({
  selector: 'app-account-assistance',
  templateUrl: './account-assistance.component.html',
  styleUrls: ['./account-assistance.component.css']
})
export class AccountAssistanceComponent implements OnInit {

  public SupportSubjectToLabelMapping = SupportSubjectToLabelMapping;

  public supportSubjectTypes = Object.values(SupportSubject);

  user: User;
  userSub: Subscription;

  public ContactForm: FormGroup;

  ngOnInit(): void {
  }

  initializeUser() {
    this.userSub = this.accountService.userObservable.subscribe(
      (next: User) => {
        this.user = next;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  constructor(private formBuilder: FormBuilder, private accountService: AccountService, private accountComponent: AccountComponent) {

    this.ContactForm = formBuilder.group({
      'ContactName': ['', this.ValidatorName().bind(this)],
      'ContactEmail': ['', Validators.compose([Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$'), this.ValidatorEmail().bind(this)])],
      'ContactSubject': ['', Validators.required],
      'ContactMessage': ['', Validators.required],
      'contactCopy': [''],
    });
    this.initializeUser();
  }

  onSubmit() {
    if (this.ContactForm.invalid) {
      return;
    }
    var name: string;
    var email: string;
    if (this.user.first_name !== undefined)
      name = this.user.first_name + this.user.last_name;
    else name = this.ContactForm.value.ContactName;

    if (this.user.email !== undefined)
      email = this.user.email;
    else email = this.ContactForm.value.ContactEmail;

    this.accountService.sendSupportMessage(
      name,
      email,
      this.ContactForm.value.ContactSubject,
      this.ContactForm.value.ContactMessage,
      this.ContactForm.value.contactCopy
      ).subscribe(() => {
      alert('Your message has been sent.');
        this.ContactForm.reset();
    }, error => {
      console.log('Error', error);
    });
  }

  ValidatorName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) { return null; }
      return this.user.username !== undefined && !control.value ? {} : null;
    }
  }

  ValidatorEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) { return null;}
      return this.user.email !== undefined && !control.value ? {} : null;
    }
  }

}
