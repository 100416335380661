import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";
import {  HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from 'src/app/login/login.component';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/services/account.service';
import { SidenavService } from '../services/sidenav.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public RegisterForm: FormGroup;
  private firstName: FormControl;
  private lastName: FormControl;
  private username: FormControl;
  private password: FormControl;
  private email: FormControl;
  submitted = false;
  loading = false;
  thermsCondition = false;
  FormMessage : string;
  f;

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private accountService: AccountService, private sidenavService: SidenavService) {
    this.firstName = new FormControl('');
    this.lastName = new FormControl('');
    this.username = new FormControl('', [Validators.required]);
    this.password = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.email = new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$')]);

    this.RegisterForm = formBuilder.group({
      firstName: this.firstName,
      lastName: this.lastName,
      username: this.username,
      password: this.password,
      email: this.email
    })
    this.f = this.RegisterForm.controls;
  }

  canCreateAccount(): boolean {
    return true;
  }

  ngOnInit(): void {
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.RegisterForm.invalid) {
      return;
    }
    if (!this.thermsCondition) {
      this.FormMessage = "You must accept terms and conditions to proceed";
    }
    this.createUser(this.RegisterForm.value.username, this.RegisterForm.value.email, this.RegisterForm.value.password, this.RegisterForm.value.firstName, this.RegisterForm.value.lastName);
  }

  AcceptTermsAndCondition(arg) {
    this.thermsCondition = arg.target.checked;
  }

  createUser(User: string, Email: string, Pass: string, firstName: string, lastName: string) {
    const headers = new HttpHeaders()
    const body = {
      "username": User,
      "email": Email,
      "password": Pass,
      "firstName": firstName,
      "lastName": lastName
    };
    this.http.post<any>( environment.apiURL + '/services/create-user', body, { headers, observe: 'response', withCredentials: true })
      .subscribe(
        (response) => {
          this.loading = false;
          let jsonObj: User = JSON.parse(JSON.stringify(response.body))
          this.accountService.setUser(jsonObj);
          this.accountService.SetIsLogin(true);
          this.FormMessage = "Account created and logged in";
          this.sidenavService.toggle();
        },
        (error) => {//Error callback
          console.log(error);
          console.error('error caught in createUser : ' + error.status);
          this.loading = false;
          switch (error.status) {
            case 0:      //BAD_REQUEST
              this.FormMessage = "Unable to connect";
              break;
            case 400:      //BAD_REQUEST
              this.FormMessage = "BAD_REQUEST contact admin";
              break;
            case 403:     //forbidden
              this.FormMessage = "You can't create an account contact admin";
              break;
            case 409:     //CONFLICT
              this.FormMessage = "CONFLICT contact admin";
              break;
            case 429:     //Too Many Requests
              this.FormMessage = "Too Many Requests";
              break;
          }
        }
      );
  }
}
