import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators, ReactiveFormsModule } from "@angular/forms";
import { User } from 'src/app/login/login.component';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { AccountComponent } from 'src/app/account/account.component'; // toast
import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-account-security',
  templateUrl: './account-security.component.html',
  styleUrls: ['./account-security.component.css']
})
export class AccountSecurityComponent implements OnInit {
  user: User;
  userSub: Subscription;
  // password
  public UpdatePasswordForm: FormGroup;
  public SetPasswordForm: FormGroup;
  private passwordValidators: []
  private setFirstPassword: FormControl;
  private setSecondPassword: FormControl;
  private updateFirstPassword: FormControl;
  private updateSecondPassword: FormControl;
  private updateLastPassword: FormControl;
  submittedPasswordForm: boolean = false;
  UpdatePasswordFormControl;
  SetPasswordFormControl;
  isChangingPassword: boolean = false;

  constructor(private formBuilder: FormBuilder, private accountService: AccountService, private accountComponent: AccountComponent) {
    this.updateFirstPassword = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.updateSecondPassword = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.updateLastPassword = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.UpdatePasswordForm = formBuilder.group({
      updateFirstPassword: this.updateFirstPassword,
      updateSecondPassword: this.updateSecondPassword,
      updateLastPassword: this.updateLastPassword
    }, { validators: this.checkUpdatePasswordForm })
    this.UpdatePasswordFormControl = this.UpdatePasswordForm.controls;
    this.setFirstPassword = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.setSecondPassword = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.SetPasswordForm = formBuilder.group({
      setFirstPassword: this.setFirstPassword,
      setSecondPassword: this.setSecondPassword
    }, { validators: this.checkSetPasswordForm })
    this.SetPasswordFormControl = this.SetPasswordForm.controls;
    this.initializeUser();
  }

  ngOnInit(): void {
  }

  initializeUser() {
    this.userSub = this.accountService.userObservable.subscribe(
      (next: User) => {
        this.user = next;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  checkUpdatePasswordForm: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('updateFirstPassword')!.value;
    let confirmPass = group.get('updateSecondPassword')!.value
    return pass === confirmPass ? null : { notSame: true }
  }

  checkSetPasswordForm: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('setFirstPassword')!.value;
    let confirmPass = group.get('setSecondPassword')!.value
    return pass === confirmPass ? null : { notSame: true }
  }

  OnPasswordUpdate() {
    console.log(this.isChangingPassword);
    if (!this.isChangingPassword && this.user.has_password) {
      this.isChangingPassword = true;
      return;
    }
    var toastStr = "";
    this.submittedPasswordForm = true;
    // stop here if form is invalid
    if (this.UpdatePasswordForm.invalid || this.updateFirstPassword.value !== this.updateSecondPassword.value) {
      var toastStr = "Passwords field must be equal.";
      this.accountComponent.showToast(toastStr, 1);
      this.submittedPasswordForm = false;
      return;
    }
    toastStr = "Updating Password";
    this.accountComponent.showToast(toastStr, 2);
    // http req
    this.accountService.updatePassword(this.updateLastPassword.value, this.updateFirstPassword.value)
      .subscribe(
        (response) => {
          toastStr = "Your password is updated";
          this.accountComponent.showToast(toastStr, 0);
          this.isChangingPassword = false;
          this.UpdatePasswordForm.reset()
        },
        (error) => {//Error callback
          console.log(error);
          toastStr = "Updating Password ";
          this.submittedPasswordForm = false;
          toastStr += this.accountService.getErrorStr(error.status);
          this.accountComponent.showToast(toastStr, 1);
        }
    );
  }

  OnSetPassword() {
    console.log(this.isChangingPassword);
    if (!this.isChangingPassword && this.user.has_password) {
      this.isChangingPassword = true;
      return;
    }
    var toastStr = "";
    this.submittedPasswordForm = true;
    // stop here if form is invalid
    if (this.SetPasswordForm.invalid || this.setFirstPassword.value !== this.setSecondPassword.value) {
      var toastStr = "Passwords field must be equal.";
      this.accountComponent.showToast(toastStr, 1);
      this.submittedPasswordForm = false;
      return;
    }
    toastStr = "Setting Password";
    this.accountComponent.showToast(toastStr, 2);
    // http req
    this.accountService.setPassword(this.setFirstPassword.value)
      .subscribe(
        (response) => {
          toastStr = "Your password is updated";
          this.accountComponent.showToast(toastStr, 0);
          this.isChangingPassword = false;
          this.SetPasswordForm.reset()
        },
        (error) => {//Error callback
          console.log(error);
          toastStr = "Setting Password ";
          this.submittedPasswordForm = false;
          toastStr += this.accountService.getErrorStr(error.status);
          this.accountComponent.showToast(toastStr, 1);
        }
    );
  }
}
