
<div class="flexible-container Weapons" style="position: relative;" *ngIf="!IsMobile()">

  <img loading="lazy" src="assets/assets_web_2/separateur.webp" alt="border" style="position: absolute; transform: translate(0%,20%) rotate(-0.9deg); height: auto; width: 95%; left: 2.5%; top: 0; pointer-events: none; z-index: 3">
  <img loading="lazy" src="assets/assets_web_2/separateur.webp" alt="border" style="position: absolute; transform: translate(0%,-15%) rotate(0.8deg); height: auto; width: 95%; left: 2.5%; bottom: 0; pointer-events: none; z-index: 3">
  <img loading="lazy" src="assets/assets_web_2/masque_section_3.webp" alt="Graphic" style="position: absolute; transform: translate(0%,-10px); height: 98%; width: 45vw; bottom: 0; pointer-events: none; user-select: none; z-index: 1 ">
  <svg height="max(1000px,100vh)" width="35vw" id="containerWeapon" style="position: absolute; bottom: 0; left: 0; pointer-events: none; z-index: 2; opacity: 1; "></svg>

  <div id="video_overlays" class="WeaponVO ps-5 pt-3" style="z-index: 3;">

    <h1 id="title_s" class="pt-3">Take up arms</h1>
    <p style="text-align:left">
      Choose two weapons from a whole arsenal at your disposal and switch between them to adapt your playstyle.
    </p>

    <div class="flex-column">

      <div class="d-flex flex-row justify-content-around">

        <div class="col">

          <a style="cursor: pointer; position: relative" (click)="ClickWeaponSword()" (mouseenter)="WeaponSwordEnter()" (mouseleave)="WeaponSwordLeave()">
            <svg viewBox="0 0 220 150" width="min(220px,15vw)" height="min(150px,15vh)" preserveAspectRatio="xMaxYMax">
              <path d="M 0 0 L 55 0 M 90 0 L 200 0 L 200 100 L 150 100 M 15 100 L 0 100 L 0 0" fill="none" stroke="#fff" stroke-width="2" transform="translate(0 15)" [ngClass]="{selected: this.isWeaponOpen == 'SwordOpen'}"></path>
              <rect loading="lazy" alt="Icon" width="25px" height="25px" fill="white" fill-opacity="1" transform="translate(15 115) rotate(-45)" [ngClass]="{selected: this.isWeaponOpen == 'SwordOpen'}"/>
              <rect class="RectFWS" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 115) rotate(-45)" />
              <text width="400" height="160" x="102.5px" fill="white" y="125px" text-anchor="middle" style="font-size: 30px; left: 5vw;">
                <tspan>Sword</tspan>
              </text>
              <image x="0" y="-10" loading="lazy" href="assets/assets_web_2/sword.svg" alt="Icon" width="205px" height="120px" />
            </svg>
          </a>
        </div>

        <div class="col">

          <a style="cursor: pointer; position: relative" (click)="ClickWeaponBow()" (mouseenter)="WeaponBowEnter()" (mouseleave)="WeaponBowLeave()">
            <svg viewBox="0 0 220 150" width="min(220px,15vw)" height="min(150px,15vh)" preserveAspectRatio="xMaxYMax">
              <path d="M 0 0 L 50 0 M 80 0 L 200 0 L 200 100 L 150 100 M 15 100 L 0 100 L 0 0" fill="none" stroke="#fff" stroke-width="2" transform="translate(0 15)" [ngClass]="{selected: this.isWeaponOpen == 'BowOpen'}"></path>
              <rect loading="lazy" alt="Icon" width="25px" height="25px" fill="white" fill-opacity="1" transform="translate(15 115) rotate(-45)" [ngClass]="{selected: this.isWeaponOpen == 'BowOpen'}" />
              <rect style="opacity:0" class="RectFWB" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 115) rotate(-45)" />
              <text width="400" height="160" x="102.5px" fill="white" y="125px" text-anchor="middle" style="font-size: 30px; left: 5vw;">
                <tspan>Bow</tspan>
              </text>
              <image x="-40" y="-10" loading="lazy" href="assets/assets_web_2/Bow.svg" alt="Icon" width="280px" height="130px" />
            </svg>
          </a>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-around">

        <div class="col">
          <a style="cursor: pointer; position: relative;" (click)="ClickWeaponSwordAndShield()" (mouseenter)="WeaponShieldEnter()" (mouseleave)="WeaponShieldLeave()">
            <svg viewBox="0 0 220 150" width="min(220px,15vw)" height="min(150px,15vh)" preserveAspectRatio="xMaxYMax">
              <path d="M 0 0 L 80 0 M 120 0 L 200 0 L 200 100 L 150 100 M 15 100 L 0 100 L 0 0" fill="none" stroke="#fff" stroke-width="2" transform="translate(0 15)" [ngClass]="{selected: this.isWeaponOpen == 'SwordShieldOpen'}"></path>
              <rect loading="lazy" alt="Icon" width="25px" height="25px" fill="white" fill-opacity="1" transform="translate(15 115) rotate(-45)" [ngClass]="{selected: this.isWeaponOpen == 'SwordShieldOpen'}"/>
              <rect style="opacity:0" class="RectFWSH" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 115) rotate(-45)" />
              <image x="0" y="0" loading="lazy" href="assets/assets_web_2/Sword_and_shield.svg" alt="Icon" width="205px" height="150px" />
              <text width="400" height="160" x="102.5px" fill="white" y="125px" text-anchor="middle" style="font-size: 30px; left: 5vw;">
                <tspan>Shield</tspan>
              </text>
            </svg>
          </a>
        </div>
        <div class="col">
          <a style="cursor: pointer; position: relative" (click)="ClickWeaponScythe()" (mouseenter)="WeaponScytheEnter()" (mouseleave)="WeaponScytheLeave()">
            <svg viewBox="0 0 220 150" width="min(220px,15vw)" height="min(150px,15vh)" preserveAspectRatio="xMaxYMax">
              <path d="M 0 0 L 50 0 M 80 0 L 200 0 L 200 100 L 160 100 M 15 100 L 0 100 L 0 0" fill="none" stroke="#fff" stroke-width="2" transform="translate(0 15)" [ngClass]="{selected: this.isWeaponOpen == 'ScytheOpen'}"></path>
              <rect loading="lazy" alt="Icon" width="25px" height="25px" fill="white" fill-opacity="1" transform="translate(15 115) rotate(-45)" [ngClass]="{selected: this.isWeaponOpen == 'ScytheOpen'}"/>
              <rect style="opacity:0" class="RectFWSC" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 115) rotate(-45)" />
              <text width="400" height="160" x="102.5px" fill="white" y="125px" text-anchor="middle" style="font-size: 30px; left: 5vw;">
                <tspan>Scythe</tspan>
              </text>
              <image x="0" y="0" loading="lazy" href="assets/assets_web_2/Scythe.svg" alt="Icon" width="205px" height="100px" />
            </svg>
          </a>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-around">
        <div class="col">
          <a style="cursor: pointer; position: relative" (click)="ClickWeaponHel()" (mouseenter)="WeaponHelEnter()" (mouseleave)="WeaponHelLeave()">
            <svg viewBox="0 0 220 150" width="min(220px,15vw)" height="min(150px,15vh)" preserveAspectRatio="xMaxYMax">
              <path d="M 0 0 L 50 0 M 80 0 L 200 0 L 200 100 L 150 100 M 15 100 L 0 100 L 0 0" fill="none" stroke="#fff" stroke-width="2" transform="translate(0 15)" [ngClass]="{selected: this.isWeaponOpen == 'Hel'}"></path>
              <rect loading="lazy" alt="Icon" width="25px" height="25px" fill="white" fill-opacity="1" transform="translate(15 115) rotate(-45)" [ngClass]="{selected: this.isWeaponOpen == 'Hel'}"/>
              <rect style="opacity:0" class="RectFWC" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 115) rotate(-45)" />
              <text width="400" height="160" x="102.5px" fill="white" y="125px" text-anchor="middle" style="font-size: 30px; left: 5vw;">
                <tspan>Relic</tspan>
              </text>
              <image x="0" y="0" loading="lazy" href="assets/assets_web_2/Scythe.svg" alt="Icon" width="205px" height="100px" />
            </svg>
          </a>
        </div>
        <div class="col">
          <a style="cursor: pointer; position: relative" (click)="ClickWeaponDisjuncture()" (mouseenter)="WeaponDisjunctureEnter()" (mouseleave)="WeaponDisjunctureLeave()">
            <svg viewBox="0 0 220 150" width="min(220px,15vw)" height="min(150px,15vh)" preserveAspectRatio="xMaxYMax">
              <path d="M 0 0 L 50 0 M 80 0 L 200 0 L 200 100 L 150 100 M 15 100 L 0 100 L 0 0" fill="none" stroke="#fff" stroke-width="2" transform="translate(0 15)" [ngClass]="{selected: this.isWeaponOpen == 'Disjuncture'}"></path>
              <rect loading="lazy" alt="Icon" width="25px" height="25px" fill="white" fill-opacity="1" transform="translate(15 115) rotate(-45)" [ngClass]="{selected: this.isWeaponOpen == 'Disjuncture'}"/>
              <rect style="opacity:0" class="RectFWD" id="Rectangle_60_fill" loading="lazy" alt="Icon" width="25px" height="25px" transform="translate(15 115) rotate(-45)" />
              <text width="400" height="160" x="102.5px" fill="white" y="125px" text-anchor="middle" style="font-size: 30px; left: 5vw;">
                <tspan>Relic</tspan>
              </text>
              <image x="0" y="0" loading="lazy" href="assets/assets_web_2/Scythe.svg" alt="Icon" width="205px" height="100px" />
            </svg>
          </a>
        </div>
      </div>

      <div class="row pt-2 px-2 small">
        <div class="SwordText" style=" position: absolute;width:25vw">
          <p>
            The sword is the weapon of choice if you like it close and personal.
            Pull your adversary towards you with telekinesis and stun them with a lunge attack.
          </p>
        </div>
        <div class="Op0 BowText" style=" position: absolute; margin-right: 5vh;width:25vw">
          <p>
            The bow is a versatile weapon that can be used for a quick scattershot or precise long distance attacks.
          </p>
        </div>
        <div class="Op0 SSText" style=" position: absolute; margin-right: 5vh;width:25vw">
          <p>
            Block projectiles and stun your foes while you wait for the right moment to strike back.
          </p>
        </div>
        <div class="Op0 ScytheText" style=" position: absolute; margin-right: 5vh;width:25vw">
          <p>
            In a world where mobility is the key to success, the scythe can give you an edge in combat with its special movement abilities.
          </p>
        </div>
        <div class="Op0 HelText" style=" position: absolute; margin-right: 5vh;width:25vw">
          <p>
            This mid-range weapon is infused with powerful magic and offers a high fire rate, ideal to constantly harrass the enemy.
          </p>
        </div>
        <div class="Op0 ZulText" style=" position: absolute; margin-right: 5vh;width:25vw">
          <p>
            A slow, but very effective distance weapon. Its high energy consumption is more than compensated by its massive damage output.
          </p>
        </div>
      </div>

    </div>

  </div>

  <div id="SoulCastWeaponVideo">
    <div #playerWeaponContainer style="position: absolute; top: 50%; left: 50%; transform: translate(-25% ,-50%)"></div>
  </div>

</div>

<div *ngIf="IsMobile()">
  <div style="display: flex; justify-content: center; position: relative; pointer-events: none; z-index: 100 ">
    <svg style="position: absolute; transform: translate(0,-80%); " width="min(600px,90vw)" height="50px" max-width="100vw" preserveAspectRatio="xMaxYMax">
      <image loading="lazy" href="assets/assets_web_2/Ornement-v2.svg" alt="Timeline" width="100%" height="100%" />
    </svg>
  </div>

  <h1 id="title_s" style="text-align:center">Take up arms</h1>
  <p class="px-4 py-2">
    Choose two weapons from a whole arsenal at your disposal and switch between them to adapt your playstyle.
  </p>

  <div class='embed-container' style="position:relative">
    <svg viewBox="0 0 200 100" width="min(220px,25vw)" height="min(150px,25vh)" style="position:absolute;bottom:0">
      <image x="0" y="0" loading="lazy" href="assets/assets_web_2/sword.svg" alt="Icon" width="205px" height="120px" />
      <text width="400" height="160" x="102.5px" fill="white" y="150px" text-anchor="middle" style="font-size: 40px; left: 5vw;">
        <tspan>Sword</tspan>
      </text>
    </svg>
    <div #playerWeaponSwordMobile></div>
  </div>
  <p class="px-4 py-4">
    The sword is the weapon of choice if you like it close and personal.
    Pull your adversary towards you with telekinesis and stun them with a lunge attack.
  </p>

  <div class='embed-container' style="position:relative">
    <svg viewBox="0 0 200 100" width="min(220px,25vw)" height="min(150px,25vh)" style="position: absolute; bottom: 0">
      <image x="0" y="0" loading="lazy" href="assets/assets_web_2/Sword_and_shield.svg" alt="Icon" width="205px" height="120px" />
      <text width="400" height="160" x="102.5px" fill="white" y="150px" text-anchor="middle" style="font-size: 40px; left: 5vw;">
        <tspan>Shield</tspan>
      </text>
    </svg>
    <div #playerContainerSworldShieldMobile></div>
  </div>
  <p class="px-4 py-4">
    Block projectiles and stun your foes while you wait for the right moment to strike back.
  </p>


  <div class='embed-container' style="position:relative">
    <svg viewBox="0 0 200 100" width="min(220px,25vw)" height="min(150px,25vh)" style="position: absolute; bottom: 0">
      <image x="0" y="0" loading="lazy" href="assets/assets_web_2/Scythe.svg" alt="Icon" width="205px" height="120px" />
      <text width="400" height="160" x="102.5px" fill="white" y="150px" text-anchor="middle" style="font-size: 40px; left: 5vw;">
        <tspan>Scythe</tspan>
      </text>
    </svg>
    <div #playerContainerScytheMobile></div>
  </div>
  <p class="px-4 py-4">
    In a world where mobility is the key to success, the scythe can give you an edge in combat with its special movement abilities.
  </p>


  <div class='embed-container' style="position:relative">
    <svg viewBox="0 0 200 100" width="min(220px,25vw)" height="min(150px,25vh)" style="position: absolute; bottom: 0">
      <image x="0" y="0" loading="lazy" href="assets/assets_web_2/Bow.svg" alt="Icon" width="205px" height="120px" />
      <text width="400" height="160" x="102.5px" fill="white" y="150px" text-anchor="middle" style="font-size: 40px; left: 5vw;">
        <tspan>Bow</tspan>
      </text>
    </svg>
    <div #playerContainerBowMobile></div>
  </div>
  <p class="px-4 py-4">
    The bow is a versatile weapon that can be used for a quick scattershot or precise long distance attacks.
  </p>

  <div class='embed-container' style="position:relative">
    <svg viewBox="0 0 200 100" width="min(220px,25vw)" height="min(150px,25vh)" style="position: absolute; bottom: 0">
      <image x="0" y="0" loading="lazy" href="assets/assets_web_2/sword.svg" alt="Icon" width="205px" height="120px" />
      <text width="400" height="160" x="102.5px" fill="white" y="150px" text-anchor="middle" style="font-size: 40px; left: 5vw;">
        <tspan>Relic</tspan>
      </text>
    </svg>
    <div #playerContainerSniperMobile></div>
  </div>
  <p class="px-4 py-4">
    A slow, but very effective distance weapon. Its high energy consumption is more than compensated by its massive damage output.
  </p>

  <div class='embed-container' style="position:relative">
    <svg viewBox="0 0 200 100" width="min(220px,25vw)" height="min(150px,25vh)" style="position: absolute; bottom: 0">
      <image x="0" y="0" loading="lazy" href="assets/assets_web_2/sword.svg" alt="Icon" width="205px" height="120px" />
      <text width="400" height="160" x="102.5px" fill="white" y="150px" text-anchor="middle" style="font-size: 40px; left: 5vw;">
        <tspan>Relic</tspan>
      </text>
    </svg>
    <div #playerContainerBasicMobile></div>
  </div>
  <p class="px-4 py-4">
    This mid-range weapon is infused with powerful magic and offers a high fire rate, ideal to constantly harrass the enemy.
  </p>
</div>
