import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {switchMap} from 'rxjs';
import {StripeService} from 'ngx-stripe';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  creator_code: string;
  promotion_code: string;
  percentOff: number;
  constructor(private http: HttpClient, private stripeService: StripeService) {
    this.creator_code = ""
    this.promotion_code = ""
  }

  SetCreatorCode(creator_code: string, promotion_code: string, percentOff: number) {
    this.creator_code = creator_code;
    this.promotion_code = promotion_code;
    this.percentOff = percentOff;
  }
  GetPercentOff() {
    return this.percentOff;
  }
  
  checkout(itemId: string, creatorCode: string = "", promotion_code: string = "") {
    // Check the server.js tab to see an example implementation
    let promotion = "";
    if (this.creator_code != "" && this.promotion_code != "")
      promotion = '&creator_code=' + this.creator_code + '&promotion_code=' + this.promotion_code;
    return this.http.get(environment.apiURL + '/services/payments/create-checkout-session/?itemId=' + itemId + promotion)
      .pipe(
        switchMap(session => {
          // @ts-ignore
          return this.stripeService.redirectToCheckout({sessionId: session.sessionId})
        })
      )
  }
}
