<section style="background-image: url(assets/assets_web_2/bg-dashboard.webp); background-attachment: fixed; background-position: center; width: 100vw; min-height: 100vh; position: relative; background-repeat: no-repeat; background-size: cover; ">
  <div id="accountContainer" class="px-md-5">
    <div id="CurrentTitleDiv" style="padding-top:2vh"><h1 id="title">Dashboard</h1></div>

    <div>
      <mat-toolbar class="pb-2" style="justify-content:right">
        <button mat-icon-button *ngIf="sidenavAccount.mode === 'over'" (click)="sidenavAccount.toggle()" class="btn btn-sm btn-secondary">
          <i class="fa-solid fa-bars"></i>
          <p class="m-0" *ngIf="!sidenavAccount.opened">Menu</p>
          <p class="m-0" *ngIf="sidenavAccount.opened">Close</p>
        </button>
      </mat-toolbar>

      <mat-sidenav-container>
        <mat-sidenav #sidenav1="matSidenav" class="position-fixed">
          <div class="sidenavContainer" style="margin-top: 10vh ;">
            <div class="">
              <button type="button" aria-label="Close" *ngIf="sidenavAccount.mode === 'over'" (click)="sidenavAccount.toggle()" class="btn btn-secondary close m-2" style="position: absolute; right: 0; outline: none !important; box-shadow: none !important; background-color: transparent !important ">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="-body text-center">
                <img loading="lazy" src='{{ "assets/Characters/" + this.user.icon_index + ".webp" }}' alt="avatar" class="rounded-circle img-fluid pt-2" style="width: 150px;" draggable="false">
                <p class="pt-4 m-0" style="text-transform: uppercase; font-size: calc(25px + (30 - 25) * ((100vw - 300px) / (2000 - 300))); "> {{this.user.username}}</p>
              </div>
            </div>
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
              <li [ngbNavItem]="1">
                <a ngbNavLink (click)="closeSidenavIfMobile()">
                  <div class="underlineWhite">
                    <div class="underline">
                      <svg height="66px" width="250px">
                        <rect class="navicon" id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(15 33) rotate(-45)" />
                        <text x="45px" fill="white" y="40px" style="font-size: 18px; left: 5vw; ">
                          <tspan>Overview</tspan>
                        </text>
                      </svg>
                    </div>
                  </div>
                </a>
                <ng-template ngbNavContent>
                  <app-account-overview></app-account-overview>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a ngbNavLink (click)="closeSidenavIfMobile()">
                  <div class="underlineWhite">
                    <div class="underline">
                      <svg height="66px" width="250px">
                        <rect class="navicon" id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(15 33) rotate(-45)" />
                        <text x="45px" fill="white" y="40px" style="font-size: 18px; left: 5vw; ">
                          <tspan>Account Settings</tspan>
                        </text>
                      </svg>
                    </div>
                  </div>
                </a>
                <ng-template ngbNavContent>
                  <app-account-settings></app-account-settings>
                </ng-template>
              </li>
              <li [ngbNavItem]="3">
                <a ngbNavLink (click)="closeSidenavIfMobile()">
                  <div class="underlineWhite">
                    <div class="underline">
                      <svg height="66px" width="250px">
                        <rect class="navicon" id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(15 33) rotate(-45)" />
                        <text x="45px" fill="white" y="40px" style="font-size: 18px; left: 5vw; ">
                          <tspan>Security Settings</tspan>
                        </text>
                      </svg>
                    </div>
                  </div>
                </a>
                <ng-template ngbNavContent>
                  <app-account-security></app-account-security>
                </ng-template>
              </li>
              <li [ngbNavItem]="4">
                <a ngbNavLink (click)="closeSidenavIfMobile()">
                  <div class="underlineWhite">
                    <div class="underline">
                      <svg height="66px" width="250px">
                        <rect class="navicon" id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(15 33) rotate(-45)" />
                        <text x="45px" fill="white" y="40px" style="font-size: 18px; left: 5vw; ">
                          <tspan>Orders</tspan>
                        </text>
                      </svg>
                    </div>
                  </div>
                </a>
                <ng-template ngbNavContent>
                  <app-account-orders></app-account-orders>
                </ng-template>
              </li>
              <li [ngbNavItem]="5">
                <a ngbNavLink (click)="closeSidenavIfMobile()">
                  <div class="underlineWhite">
                    <div class="underline">
                      <svg height="66px" width="250px">
                        <rect class="navicon" id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(15 33) rotate(-45)" />
                        <text x="45px" fill="white" y="40px" style="font-size: 18px; left: 5vw; ">
                          <tspan>Assistance</tspan>
                        </text>
                      </svg>
                    </div>
                  </div>
                </a>
                <ng-template ngbNavContent>
                  <app-account-assistance></app-account-assistance>
                </ng-template>
              </li>
            </ul>

            <ul ngbNav class="nav-pills" orientation="vertical">
              <li class="align-self-center p-3">
                <div class="underlineWhite" routerLink="/" style="cursor:pointer">
                  <div class="underline">
                    <p style="font-size:35px;padding:20px">Home</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="info" style="text-align: center; margin-top: 2vh; position: absolute; min-width: 100%; max-width: 100%">
            <div *ngIf="toastStatus === 2" class="alert alert-secondary" role="alert">
              {{toastMessage}}<br />
              <span class="form-check-label spinner-border spinner-border-sm mr-1 text-primary" style="width:20px;height:20px"></span>
            </div>
            <div *ngIf="toastStatus === 0" class="alert alert-success" role="alert">
              Success.<br /> {{toastMessage}}
            </div>
            <div *ngIf="toastStatus === 1" class="alert alert-danger" role="alert">
              Fail, try again later.<br /> {{toastMessage}}
            </div>
          </div>

        </mat-sidenav>


        <mat-sidenav-content class="px-3">
          <div class="content mat-elevation-z8 bg-dark">
            <div [ngbNavOutlet]="nav"></div>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div> 
  </div>

</section>
