<div class="d-flex px-5 justify-content-center">

  <div class="col-lg-12">
    <p class="my-3">SECURITY INFORMATIONS</p>

    <div class=" my-2">
      <div class="-body text-center">
        <form *ngIf="!user.has_password" [formGroup]="SetPasswordForm" (ngSubmit)="OnSetPassword()">
          <div class="-body text-center">
            <p> Password must contain at least 6 characters </p>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="setFirstPassword">New Password</label>
                  <div class="underlineWhite">
                    <div class="underline">
                      <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
                        <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(2 15) rotate(-45)" />
                      </svg>
                      <input type="password" formControlName="setFirstPassword" class="form-control" [ngClass]="{ 'is-invalid':SetPasswordFormControl.setFirstPassword.errors }" />
                    </div>
                  </div>                  
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="setSecondPassword">Repeat New Password</label>
                  <div class="underlineWhite">
                    <div class="underline">
                      <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
                        <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(2 15) rotate(-45)" />
                      </svg>
                      <input type="password" formControlName="setSecondPassword" class="form-control" [ngClass]="{ 'is-invalid':SetPasswordFormControl.setSecondPassword.errors }" />
                    </div>
                  </div>                  
                </div>
              </div>
              <div class="col-sm-3 m-auto">
                <button [disabled]="!SetPasswordForm.valid" class="btn btn-sm btn-primary">
                  Set Password
                </button>
              </div>
            </div>
          </div>
        </form>
        <form *ngIf="user.has_password && isChangingPassword" [formGroup]="UpdatePasswordForm" (ngSubmit)="OnPasswordUpdate()">
          <div class="-body text-center">
            <p> Password must contain at least 6 characters </p>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="updateLastPassword">Last Password</label>
                  <div class="underlineWhite">
                    <div class="underline">
                      <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
                        <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(2 15) rotate(-45)" />
                      </svg>
                      <input type="password" formControlName="updateLastPassword" class="form-control" [ngClass]="{ 'is-invalid': UpdatePasswordFormControl.updateLastPassword.errors }" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="updateFirstPassword">New Password</label>
                  <div class="underlineWhite">
                    <div class="underline">
                      <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
                        <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(2 15) rotate(-45)" />
                      </svg>
                      <input type="password" formControlName="updateFirstPassword" class="form-control" [ngClass]="{ 'is-invalid':UpdatePasswordFormControl.updateFirstPassword.errors }" />
                    </div>
                  </div>                  
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="updateSecondPassword">Repeat New Password</label>
                  <div class="underlineWhite">
                    <div class="underline">
                      <svg height="66px" width="66px" style="position:absolute;left:0;top:0">
                        <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(2 15) rotate(-45)" />
                      </svg>
                      <input type="password" formControlName="updateSecondPassword" class="form-control" [ngClass]="{ 'is-invalid':UpdatePasswordFormControl.updateSecondPassword.errors }" />
                    </div>
                  </div>                  
                </div>
              </div>
              <div class="col-sm-3 m-auto">
                <button [disabled]="!UpdatePasswordForm.valid" class="btn btn-sm btn-primary">
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
        <div *ngIf="user.has_password && !isChangingPassword" class="row">
          <div class="col-sm-3 m-auto">
            <p class="mb-0">Password</p>
          </div>
          <div class="col-sm-4 m-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2-circle" viewBox="0 0 16 16">
              <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
              <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
            </svg> Your password is set.
          </div>
          <div class="col-sm-3 m-auto">
            <button (click)="OnPasswordUpdate()" class="btn btn-sm btn-primary">
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
