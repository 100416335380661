<!--Section: FAQ-->
<app-hud></app-hud>
<section class="m-auto" style="padding-top: 75px; background-image: url(assets/assets_web_2/bg-dashboard.webp); background-attachment: fixed; background-position: bottom; position: relative; background-repeat: no-repeat; background-size: cover;">

  <div class="text-center mb-4 pb-2 pt-5 pt-lg-2">
    <h1 class="my-auto" *ngIf="!french" id="title">Terms & Conditions</h1>
    <h1 class="my-auto" *ngIf="french" id="title">Conditions générales d'utilisation</h1>

    <div class="row justify-content-center">
      <div class="underlineWhite" style="width: 150px; cursor: pointer; padding:0">
        <div class="underline">
          <a (click)="OnClickEn();">
            <svg height="66px" width="150px">
              <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(15 33) rotate(-45)" />
              <text x="45px" fill="white" y="40px" style="font-size: 30px; left: 5vw; ">
                <tspan>English</tspan>
              </text>
            </svg>
          </a>
        </div>
      </div>

      <div class="underlineWhite" style="width: 175px; cursor: pointer; padding:0">
        <div class="underline">
          <a (click)="OnClickFr();">
            <svg height="66px" width="175px">
              <rect id="Rectangle_60" loading="lazy" alt="Icon" width="12px" height="12px" transform="translate(15 33) rotate(-45)" />
              <text x="45px" fill="white" y="40px" style="font-size: 30px; left: 5vw; ">
                <tspan>Francais</tspan>
              </text>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!french" style="width: 700px; max-width:90vw; margin-left: auto; margin-right: auto;z-index:200">
    <img style="width: 700px; max-width: 90vw; height: auto; margin-left: auto; margin-right: auto;" src="assets/OneShotLogo_OneLine.png" alt="OnshotStudioLogo">

    <div>
      <p class="smallTitle">Acceptance of Terms</p>
      <p class="px-2">
        By accessing or playing SoulCast, you agree to be bound by these Terms & Conditions, as well as any applicable laws and regulations.<br />
        If you do not agree to these Terms & Conditions, you may not access or play SoulCast.<br />
      </p>

      <p class="smallTitle">Age Requirement</p>
      <p class="px-2">
        In compliance with GDPR regulations, individuals must be at least 13 years old to access or play SoulCast.<br />
        If you are under 18 years of age, you must obtain parental or legal guardian consent before accessing or using our services.<br />
      </p>

      <p class="smallTitle">License</p>
      <p class="px-2">
        OneShot Studios grants you a non-exclusive, non-transferable, limited license to access and play SoulCast for personal, non-commercial use.<br />
        You may not reproduce, distribute, sell, or modify any part of SoulCast without the prior written consent of OneShot Studios.<br />
      </p>

      <p class="smallTitle">Commercial Exploitation</p>
      <p class="px-2">
        You may not commercially exploit or make SoulCast available on any third-party network, FTP, internet website, torrent site, file-sharing service, intranet, or "cloud" unless you have a valid distribution agreement with OneShot Studios.<br />
        You also agree not to link to any such third-party network, torrent, FTP, file-sharing service, intranet, or "cloud" that is not legally owned by us or our distributors.<br />
        For the sake of clarity, "commercially exploit" as used herein includes offers to perform in-game services for other players in exchange for payment outside of the game.<br />
      </p>

      <p class="smallTitle">Prohibited Third-Party Software</p>
      <p class="px-2">
        You may not use cheats, automation software (bots), hacks, mods, or any other unauthorized third-party software designed to modify the Platform, Services, or in-game experience.<br />
        You may not use any unauthorized third-party software that intercepts, "mines," or otherwise collects information from or through the Platform, Services, or Game, including without limitation any software that reads areas of RAM used by the game to store information about a character or the game environment.<br />
        However, we may, at our sole and absolute discretion, allow the use of certain third-party user interfaces.<br />
      </p>

      <p class="smallTitle">File Modification</p>
      <p class="px-2">
        You may not modify or cause to be modified any files that are a part of the Platform, Services, or Game in any way not expressly authorized by OneShot Studios.<br />
      </p>

      <p class="smallTitle">Matchmaking and Unauthorized Connections</p>
      <p class="px-2">
        You may not provide or develop services for the Platform or Game or intercept, emulate, or redirect the communication protocols used by us in any way, for any purpose, including without limitation unauthorized play over the internet, network play, or as part of content aggregation networks.<br />
        You may not facilitate, create, or maintain any unauthorized connection to the Platform, Game, or the Service, including without limitation (a) any connection to any unauthorized server that emulates or attempts to emulate the Service and (b) any connection using programs or tools not expressly approved by us.<br />
      </p>

      <p class="smallTitle">Account</p>
      <p class="px-2">
        By using SoulCast, a game developed by OneShot Studios, you agree that you will not engage in any buying, selling, or trading of game assets except for certain in-game items that are explicitly permitted by OneShot Studios, such as in-game transactions using an authorized auction house or item trade between OneShot Studios account holders.<br />
        You are expressly prohibited from exchanging game assets outside of the SoulCast service or in the real world for anything of value.<br />
        <br />
        OneShot Studios reserves the right to reduce, liquidate, deactivate, suspend, or terminate your account and/or game assets, including virtual items, housing, characters, and guild halls, if they suspect, after investigation, that you have used any aspect of the service to conduct any fraudulent or illegal activity.<br />
        It is important to note that you will not be entitled to a refund in any event of account termination or deactivation, regardless of the reason.<br />
        <br />
        Accounts are registered to you personally and may not be sold, traded, gifted, or otherwise transferred under any circumstances.<br />
      </p>

      <p class="smallTitle">Payment and Refunds Policy</p>
      <p class="px-2">
        You are eligible for a refund only if you request it within 14 days of purchase and has been played for less than 2 hours, whichever comes first.<br />
        After this period, we cannot offer any refunds. By using our products or services, you agree that you have had sufficient time to evaluate them and that you are satisfied with their quality.<br />
        Certain features of SoulCast may require payment of fees.<br />
        You agree to pay all fees and charges incurred in connection with your use of SoulCast. All fees are non-refundable.<br />
        <br />
        Exceptions:<br />
        In certain cases, we may make exceptions to this refund policy at our sole discretion, such as if there is a technical issue with the product that cannot be resolved or if there is a legal requirement to issue a refund.<br />
      </p>

      <p class="smallTitle">Pre-order</p>
      <p>By pre-ordering Soulcast from OneShot Studios, you acknowledge and agree to the following conditions:</p>
      <p class="px-2">
        OneShot Studios does not guarantee that Soulcast will be delivered on time. Pre-ordering the product does not create an obligation for OneShot Studios to deliver the product by a certain date.<br />
        Any estimated delivery date provided by OneShot Studios is subject to change, and delays may occur due to unforeseen circumstances.<br />
        <br />
        OneShot Studios reserves the right to cancel your pre-order at any time, in which case you will receive a full refund of the purchase price.<br />
        If you cancel your pre-order, OneShot Studios may charge a cancellation fee.<br />
        <br />
        By pre-ordering Soulcast, you acknowledge that the product is still in development and that the final product may differ from the product shown in promotional materials.<br />
        OneShot Studios does not guarantee the accuracy of any specifications or features listed on the product page or in promotional materials.<br />
        <br />
        By purchasing a pack from our website, you will obtain access to the specific version of SoulCast (alpha, beta, etc.) as stated in the pack description.<br />
        However, please note that OneShot Studios reserves the right to make SoulCast a free-to-play game at any time in the future.<br />
        <br />
        If SoulCast does become a free-to-play game, your access to the game will not be affected, and you will continue to have access to the version of the game that you purchased.<br />
        However, you will not be entitled to a refund or any other compensation for the purchase of the pack.<br />
        <br />
        Please be aware that access to the game may be subject to maintenance, updates, and technical issues, which may affect your ability to play the game.<br />
        OneShot Studios will make every effort to minimize any downtime, but we cannot guarantee uninterrupted access to the game.<br />
        <br />
        By purchasing a pack and accessing SoulCast, you agree to comply with all applicable laws and regulations, as well as our terms of use and privacy policy.<br />
        OneShot Studios reserves the right to terminate your access to the game if you violate any of these terms.<br />
        <br />
        Please keep in mind that SoulCast is a work in progress, and features and content are subject to change.<br />
        OneShot Studios makes no guarantee as to the availability or continued use of any particular feature or content within the game.<br />
      </p>

      <p class="smallTitle">Compliance with Laws</p>
      <p class="px-2">
        You may not violate any applicable law or regulation in connection with your use of the Platform, Services, or Game.<br />
      </p>

      <p class="smallTitle">User Conduct</p>
      <p class="px-2">
        You agree to use SoulCast only for lawful purposes and in accordance with these Terms & Conditions. You may not use SoulCast to:<br />
        <br />
        Post, transmit, or distribute any unlawful, threatening, defamatory, obscene, or otherwise objectionable content.<br />
        Engage in any activity that violates the intellectual property rights of OneShot Studios or any third party.<br />
        Attempt to gain unauthorized access to SoulCast or any other computer system or network connected to SoulCast.<br />
        Engage in any activity that interferes with the proper functioning of SoulCast, including but not limited to, distributing viruses, worms, or other harmful software.<br />
      </p>

      <p class="smallTitle">User Content</p>
      <p class="px-2">
        You are solely responsible for any content you post or submit to SoulCast, including but not limited to, comments, messages, and game data.<br />
        By posting or submitting any content to SoulCast, you grant OneShot Studios a worldwide, perpetual, irrevocable, royalty-free, fully-paid, sub-licensable, and transferable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content in any form or media.<br />
      </p>

      <p class="smallTitle">Termination</p>
      <p class="px-2">
        OneShot Studios reserves the right to terminate or suspend your access to SoulCast at any time for any reason. If your access to SoulCast is terminated or suspended, you may not access SoulCast or create a new account without the prior written consent of OneShot Studios.
      </p>

      <p class="smallTitle">Privacy policy</p>
      <p class="px-2">
        OneShot Studios takes the privacy and security of its users' personal information very seriously.<br />
        When you use our platform, we may collect and process certain personal data, such as your name, email address, and IP address, in order to provide you with the best possible service.<br />
        We may also collect information about your use of our platform, such as the pages you visit, the links you click on, and the content you view.<br />
        This data is used to improve our services, personalize your experience, and provide you with targeted marketing communications.<br />
        We may share your personal data with third-party service providers who assist us with various functions, such as data storage and analysis, customer support, and marketing.<br />
        Our cookie policy explains how we use cookies to collect information about your browsing behavior and preferences, and how we use this data to personalize your experience.<br />
        By using SoulCast, you consent to our privacy and cookie policies.
      </p>

      <p class="smallTitle">cookie policy</p>
      <p class="px-2">
        OneShot Studios cookie policy outlines how cookies are used on our platform.<br />
        Cookies are small text files that are stored on a user's device when they visit our website or use our services.<br />
        We use cookies to analyze our traffic. This helps us improve our services and provide a better user experience.<br />
        We also use cookies to remember user preferences and login information, making it easier for users to navigate our platform.<br />
        By using our services, users consent to the use of cookies as outlined in our cookie policy.<br />
        Users can control cookie settings in their browser settings or opt-out of cookies by adjusting their preferences on our platform.<br />
        However, disabling cookies may limit the functionality of our website and services.<br />
      </p>

      <p class="smallTitle">Disclaimer of Warranties</p>
      <p class="px-2">
        SoulCast is provided on an "as is" and "as available" basis, without any warranties of any kind, express or implied, including but not limited to, warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.<br />
        OneShot Studios does not warrant that SoulCast will be error-free or uninterrupted.<br />
      </p>

      <p class="smallTitle">Limitation of Liability</p>
      <p class="px-2">
        OneShot Studios shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of SoulCast, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, even if OneShot Studios has been advised of the possibility of such damages.<br />
      </p>

      <p class="smallTitle">Governing Law and Jurisdiction</p>
      <p class="px-2">
        These Terms & Conditions shall be governed by and construed in accordance with the laws of France.<br />
        Any dispute arising out of or in connection with these Terms & Conditions or the use of SoulCast shall be resolved exclusively in the courts of Versailles, France.<br />
      </p>

      <p class="smallTitle">Indemnification</p>
      <p class="px-2">
        You agree to indemnify, defend, and hold harmless OneShot Studios and its officers, directors, employees, agents, and affiliates from any and all claims, damages, expenses, and liabilities, including reasonable attorneys' fees, arising out of or in connection with your use of SoulCast or any violation of these Terms & Conditions.<br />
      </p>

      <p class="smallTitle">Modifications to Terms & Conditions</p>
      <p class="px-2">
        OneShot Studios reserves the right to modify these Terms & Conditions at any time, without prior notice.<br />
        Your continued use of SoulCast following any such modifications constitutes your acceptance of the revised Terms & Conditions.<br />
      </p>

      <p class="smallTitle">Entire Agreement</p>
      <p class="px-2">
        These Terms & Conditions constitute the entire agreement between you and OneShot Studios regarding your use of SoulCast, superseding any prior agreements or understandings, whether written or oral.
      </p>

      <p class="smallTitle">DIVISIBILITY</p>
      <p class="px-2">
        If any provision of these Terms and Conditions is deemed invalid or unenforceable, such provision shall be removed without affecting the validity or enforceability of the remaining provisions.
      </p>

      <p class="smallTitle">CONTACT</p>
      <p class="px-2">
        If you have any questions, comments, or concerns regarding these Terms and Conditions, please contact us at the following address: support@oneshotstudios.net.
      </p>

    </div>
  </div>

  <div *ngIf="french" style="width: 700px; max-width:90vw; margin-left: auto; margin-right: auto;z-index:200">
    <img style="width: 700px; max-width: 90vw; height: auto; margin-left: auto; margin-right: auto;" src="assets/OneShotLogo_OneLine.png" alt="OnshotStudioLogo">

    <div>
      <div>
        <p class="smallTitle">Acceptation des conditions</p>
        <p class="px-2">
          En accédant ou en jouant à SoulCast, vous acceptez d'être lié par ces Termes et Conditions, ainsi que par toutes les lois et réglementations applicables.<br />
          Si vous n'acceptez pas ces Termes et Conditions, vous ne pouvez pas accéder ou jouer à SoulCast.<br />
        </p>

        <p class="smallTitle">Exigence d'âge</p>
        <p class="px-2">
          Conformément aux règlements du RGPD, les personnes doivent avoir au moins 13 ans pour accéder ou jouer à SoulCast.<br />
          Si vous avez moins de 18 ans, vous devez obtenir le consentement parental ou du tuteur légal avant d'accéder ou d'utiliser nos services.<br />
        </p>

        <p class="smallTitle">Licence</p>
        <p class="px-2">
          OneShot Studios vous accorde une licence limitée, non exclusive et non transférable d'accéder et de jouer à SoulCast à des fins personnelles et non commerciales.<br />
          Vous ne pouvez pas reproduire, distribuer, vendre ou modifier une partie de SoulCast sans l'autorisation écrite préalable de OneShot Studios.<br />
        </p>

        <p class="smallTitle">Exploitation commerciale</p>
        <p class="px-2">
          Vous ne pouvez pas exploiter commercialement ou rendre SoulCast disponible sur un réseau tiers, FTP, site internet, site de torrent, service de partage de fichiers, intranet ou "cloud" sans un accord de distribution valide avec OneShot Studios.<br />
          Vous acceptez également de ne pas lier à un tel réseau tiers, torrent, FTP, service de partage de fichiers, intranet ou "cloud" qui n'est pas légalement détenu par nous ou nos distributeurs.<br />
          Pour plus de clarté, "exploitation commerciale" telle que utilisée dans les présentes inclut les offres de services en jeu pour d'autres joueurs en échange de paiement en dehors du jeu.<br />
        </p>

        <p class="smallTitle">LOGICIELS TIERS INTERDITS</p>
        <p class="px-2">
          Vous ne pouvez pas utiliser de triche, de logiciel d'automatisation (bots), de hacks, de mods ou tout autre logiciel tiers non autorisé conçu pour modifier la plate-forme, les services ou l'expérience du jeu.<br />
          Vous ne pouvez pas utiliser de logiciel tiers non autorisé qui intercepte, " mine " ou recueille de toute autre manière des informations à partir ou par le biais de la plate-forme, des services ou du jeu, y compris, sans s'y limiter, tout logiciel qui lit les zones de mémoire vive utilisées par le jeu pour stocker des informations sur un personnage ou sur l'environnement du jeu.<br />
          Toutefois, nous pouvons, à notre seule et entière discrétion, autoriser l'utilisation de certaines interfaces utilisateur tierces.<br />
        </p>

        <p class="smallTitle">Modification de fichiers</p>
        <p class="px-2">
          Vous ne pouvez pas modifier ou faire modifier les fichiers qui font partie de la Plateforme, des Services ou du Jeu d'une manière qui n'est pas expressément autorisée par OneShot Studios.<br />
        </p>

        <p class="smallTitle">Mise en relation et connexions non autorisées</p>
        <p class="px-2">
          Vous ne pouvez pas fournir ou développer des services pour la plate-forme ou le jeu, ni intercepter, émuler ou rediriger les protocoles de communication que nous utilisons, de quelque manière que ce soit et à quelque fin que ce soit, y compris, mais sans s'y limiter, le jeu non autorisé sur Internet, le jeu en réseau ou dans le cadre de réseaux d'agrégation de contenu.<br />
          Vous ne pouvez pas faciliter, créer ou maintenir une connexion non autorisée à la plate-forme, au jeu ou au service, y compris, sans s'y limiter, (a) toute connexion à un serveur non autorisé qui émule ou tente d'émuler le service et (b) toute connexion utilisant des programmes ou des outils qui n'ont pas été expressément approuvés par nous.<br />
        </p>

        <p class="smallTitle">Compte</p>
        <p class="px-2">
          En utilisant SoulCast, un jeu développé par OneShot Studios, vous acceptez de ne pas vous engager dans l'achat, la vente ou l'échange d'actifs du jeu, à l'exception de certains éléments du jeu qui sont explicitement autorisés par OneShot Studios, tels que les transactions dans le jeu à l'aide d'un hôtel des ventes autorisé ou l'échange d'objets entre les détenteurs de comptes OneShot Studios.<br />
          Il vous est expressément interdit d'échanger des biens du jeu en dehors du service SoulCast ou dans le monde réel contre quoi que ce soit de valeur.<br />
          <br />
          OneShot Studios se réserve le droit de réduire, de liquider, de désactiver, de suspendre ou de résilier votre compte et/ou vos actifs de jeu, y compris les objets virtuels, les logements, les personnages et les halls de guilde, s'ils soupçonnent, après enquête, que vous avez utilisé un aspect quelconque du service pour mener une activité frauduleuse ou illégale.<br />
          Il est important de noter que vous n'aurez droit à aucun remboursement en cas de résiliation ou de désactivation de votre compte, quelle qu'en soit la raison.<br />
          <br />
          Les comptes sont enregistrés à votre nom personnel et ne peuvent en aucun cas être vendus, échangés, donnés ou transférés de quelque manière que ce soit.<br />
        </p>

        <p class="smallTitle">Politique de paiement et de remboursement</p>
        <p class="px-2">
          Vous ne pouvez prétendre à un remboursement que si vous en faites la demande dans les 14 jours suivant l'achat ou si le jeu a été joué pendant moins de 2 heures.<br />
          Passé ce délai, nous ne pouvons plus offrir de remboursement. En utilisant nos produits ou services, vous reconnaissez avoir eu suffisamment de temps pour les évaluer et être satisfait de leur qualité.<br />
          Certaines fonctionnalités de SoulCast peuvent nécessiter le paiement de frais.<br />
          Vous acceptez de payer tous les frais et charges encourus dans le cadre de votre utilisation de SoulCast. Tous les frais ne sont pas remboursables.<br />
          <br />
          Exceptions:<br />
          Dans certains cas, nous pouvons faire des exceptions à cette politique de remboursement à notre seule discrétion, par exemple en cas de problème technique avec le produit qui ne peut être résolu ou s'il existe une obligation légale d'effectuer un remboursement.<br />
        </p>

        <p class="smallTitle">Pré-commande</p>
        <p>En précommandant Soulcast auprès de OneShot Studios, vous reconnaissez et acceptez les conditions suivantes:</p>
        <p class="px-2">
          OneShot Studios ne garantit pas que Soulcast sera livré à temps. La précommande du produit ne crée pas d'obligation pour OneShot Studios de livrer le produit à une certaine date.<br />
          Toute date de livraison estimée fournie par OneShot Studios est susceptible d'être modifiée et des retards peuvent survenir en raison de circonstances imprévues.<br />
          <br />
          OneShot Studios se réserve le droit d'annuler votre précommande à tout moment, auquel cas vous recevrez un remboursement complet du prix d'achat.<br />
          Si vous annulez votre précommande, OneShot Studios peut facturer des frais d'annulation.<br />
          <br />
          En précommandant Soulcast, vous reconnaissez que le produit est encore en cours de développement et que le produit final peut différer du produit présenté dans les documents promotionnels.<br />
          OneShot Studios ne garantit pas l'exactitude des spécifications ou des caractéristiques mentionnées sur la page du produit ou dans le matériel promotionnel.<br />
          <br />
          En achetant un pack sur notre site, vous obtiendrez l'accès à la version spécifique de SoulCast (alpha, beta, etc.) comme indiqué dans la description du pack.<br />
          Cependant, veuillez noter que OneShot Studios se réserve le droit de faire de SoulCast un jeu gratuit à tout moment dans le futur.<br />
          <br />
          Si SoulCast devient un jeu gratuit, votre accès au jeu ne sera pas affecté et vous continuerez à avoir accès à la version du jeu que vous avez achetée.<br />
          Cependant, vous n'aurez pas droit à un remboursement ou à toute autre compensation pour l'achat du pack.<br />
          <br />
          Veuillez noter que l'accès au jeu peut faire l'objet d'une maintenance, de mises à jour et de problèmes techniques, ce qui peut affecter votre capacité à jouer au jeu.<br />
          OneShot Studios fera tout son possible pour minimiser les temps d'arrêt, mais nous ne pouvons pas garantir un accès ininterrompu au jeu.<br />
          <br />
          En achetant un pack et en accédant à SoulCast, vous acceptez de vous conformer à toutes les lois et réglementations applicables, ainsi qu'à nos conditions d'utilisation et à notre politique de confidentialité.<br />
          OneShot Studios se réserve le droit de mettre fin à votre accès au jeu si vous enfreignez l'une de ces conditions.<br />
          <br />
          N'oubliez pas que SoulCast est un projet en cours et que les fonctionnalités et le contenu sont susceptibles d'être modifiés.<br />
          OneShot Studios ne garantit pas la disponibilité ou l'utilisation continue d'une fonction ou d'un contenu particulier dans le jeu.<br />
        </p>

        <p class="smallTitle">Respect des lois</p>
        <p class="px-2">
          Vous ne pouvez violer aucune loi ou réglementation applicable dans le cadre de votre utilisation de la plate-forme, des services ou du jeu.<br />
        </p>

        <p class="smallTitle">Comportement de l'utilisateur</p>
        <p class="px-2">
          Vous acceptez de n'utiliser SoulCast qu'à des fins légales et conformément aux présentes conditions générales. Vous ne pouvez pas utiliser SoulCast pour :<br />
          <br />
          Publier, transmettre ou distribuer tout contenu illégal, menaçant, diffamatoire, obscène ou autrement répréhensible.<br />
          S'engager dans toute activité qui viole les droits de propriété intellectuelle de OneShot Studios ou de tout autre tiers.<br />
          Tenter d'obtenir un accès non autorisé à SoulCast ou à tout autre système informatique ou réseau connecté à SoulCast.<br />
          S'engager dans toute activité qui interfère avec le bon fonctionnement de SoulCast, y compris, mais sans s'y limiter, la distribution de virus, de vers ou d'autres logiciels nuisibles.<br />
        </p>

        <p class="smallTitle">Contenu utilisateur</p>
        <p class="px-2">
          Vous êtes seul responsable de tout contenu que vous publiez ou soumettez à SoulCast, y compris, mais sans s'y limiter, les commentaires, les messages et les données de jeu.<br />
          En publiant ou en soumettant tout contenu à SoulCast, vous accordez à OneShot Studios une licence mondiale, perpétuelle, irrévocable, libre de redevances, entièrement payée, sous-licenciable et transférable pour utiliser, reproduire, modifier, adapter, publier, traduire, créer des œuvres dérivées, distribuer et afficher ce contenu sous quelque forme ou média que ce soit.<br />
        </p>

        <p class="smallTitle">Cessation d'activité</p>
        <p class="px-2">
          OneShot Studios se réserve le droit de résilier ou de suspendre votre accès à SoulCast à tout moment et pour quelque raison que ce soit. Si votre accès à SoulCast est résilié ou suspendu, vous ne pouvez pas accéder à SoulCast ou créer un nouveau compte sans le consentement écrit préalable de OneShot Studios.
        </p>

        <p class="smallTitle">Politique de confidentialité</p>
        <p class="px-2">
          OneShot Studios prend très au sérieux la confidentialité et la sécurité des informations personnelles de ses utilisateurs.<br />
          Lorsque vous utilisez notre plateforme, nous pouvons collecter et traiter certaines données personnelles, telles que votre nom, votre adresse e-mail et votre adresse IP, afin de vous fournir le meilleur service possible.<br />
          Nous pouvons également recueillir des informations sur votre utilisation de notre plateforme, telles que les pages que vous visitez, les liens sur lesquels vous cliquez et le contenu que vous visualisez.<br />
          Ces données sont utilisées pour améliorer nos services, personnaliser votre expérience et vous fournir des communications marketing ciblées.<br />
          Nous pouvons partager vos données personnelles avec des prestataires de services tiers qui nous assistent dans diverses fonctions, telles que le stockage et l'analyse des données, l'assistance à la clientèle et le marketing.<br />
          Notre politique en matière de cookies explique comment nous utilisons les cookies pour collecter des informations sur votre comportement de navigation et vos préférences, et comment nous utilisons ces données pour personnaliser votre expérience<br />
          En utilisant SoulCast, vous acceptez nos politiques en matière de confidentialité et de cookies.
        </p>

        <p class="smallTitle">Politique en matière de cookies</p>
        <p class="px-2">
          La politique de cookies de OneShot Studios décrit comment les cookies sont utilisés sur notre plateforme.<br />
          Les cookies sont de petits fichiers texte qui sont stockés sur l'appareil d'un utilisateur lorsqu'il visite notre site web ou utilise nos services.<br />
          Nous utilisons des cookies pour analyser notre trafic. Cela nous aide à améliorer nos services et à fournir une meilleure expérience utilisateur.<br />
          Nous utilisons également des cookies pour mémoriser les préférences des utilisateurs et les informations de connexion, ce qui facilite la navigation des utilisateurs sur notre plateforme.<br />
          En utilisant nos services, les utilisateurs consentent à l'utilisation de cookies telle que décrite dans notre politique en matière de cookies.<br />
          Les utilisateurs peuvent contrôler les paramètres des cookies dans les paramètres de leur navigateur ou refuser les cookies en ajustant leurs préférences sur notre plateforme.<br />
          Toutefois, la désactivation des cookies peut limiter la fonctionnalité de notre site web et de nos services.<br />
        </p>

        <p class="smallTitle">Exclusion de garantie</p>
        <p class="px-2">
          SoulCast est fourni "en l'état" et "tel que disponible", sans aucune garantie d'aucune sorte, expresse ou implicite, y compris, mais sans s'y limiter, les garanties de qualité marchande, d'adéquation à un usage particulier, de non-contrefaçon ou d'exécution.<br />
          OneShot Studios ne garantit pas que SoulCast sera exempt d'erreurs ou ininterrompu.<br />
        </p>

        <p class="smallTitle">Limitation de la responsabilité</p>
        <p class="px-2">
          OneShot Studios ne sera pas responsable des dommages directs, indirects, accessoires, consécutifs ou punitifs découlant de ou en rapport avec votre utilisation de SoulCast, y compris, mais sans s'y limiter, les dommages pour perte de profits, de bonne volonté, d'utilisation, de données ou d'autres pertes intangibles, même si OneShot Studios a été avisé de la possibilité de tels dommages.<br />
        </p>

        <p class="smallTitle">Droit applicable et juridiction</p>
        <p class="px-2">
          Ces termes et conditions sont régis et interprétés conformément aux lois françaises.<br />
          Tout litige découlant des présentes Conditions générales ou de l'utilisation de SoulCast doit être résolu exclusivement par les tribunaux de Versailles, France.
        </p>

        <p class="smallTitle">Indemnisation</p>
        <p class="px-2">
          Vous acceptez d'indemniser, de défendre et de dégager de toute responsabilité OneShot Studios et ses dirigeants, administrateurs, employés, agents et affiliés pour toute réclamation, tout dommage, toute dépense et toute responsabilité, y compris les frais d'avocat raisonnables, découlant de ou en rapport avec votre utilisation de SoulCast ou toute violation des présentes conditions générales.<br />
        </p>

        <p class="smallTitle">Modifications des conditions générales</p>
        <p class="px-2">
          OneShot Studios se réserve le droit de modifier ces termes et conditions à tout moment, sans préavis.<br />
          En continuant à utiliser SoulCast après de telles modifications, vous acceptez les conditions générales révisées.
        </p>

        <p class="smallTitle">accords antérieurs</p>
        <p class="px-2">
          Les présentes conditions générales constituent l'intégralité de l'accord entre vous et OneShot Studios concernant votre utilisation de SoulCast, et remplacent tous les accords ou arrangements antérieurs, qu'ils soient écrits ou oraux.
        </p>

        <p class="smallTitle">DIVISIBILITÉ</p>
        <p class="px-2">
          Si une disposition de ces Termes et Conditions est jugée invalide ou inapplicable, cette disposition sera supprimée sans affecter la validité ou l'applicabilité des autres dispositions.
        </p>

        <p class="smallTitle">CONTACT</p>
        <p class="px-2">
          Si vous avez des questions, des commentaires ou des préoccupations concernant ces Termes et Conditions, veuillez nous contacter à l'adresse suivante : support@oneshotstudios.net
        </p>
      </div>
    </div>
  </div>

  <img *ngIf="showCharacters" style="width: 600px; max-width: 100vw; height: auto; position: fixed; top: 300px; right: -50px; z-index: 0" src="assets/assets_web_2/TheDuchess.webp" alt="Characters" />
  <img *ngIf="showCharacters" style="width: 600px; max-width: 100vw; height: auto; position: fixed; top: 300px; left: -120px; z-index: 0" src="assets/assets_web_2/Herald.webp" alt="Characters" />
</section>
<!--Section: FAQ-->
<app-footer></app-footer>
