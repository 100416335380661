import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Product} from '../products-templated-list/products-templated-list.component';
import { PaymentsService } from '../services/payments.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient, private paymentsService: PaymentsService) { }

  getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>( environment.apiURL + '/services/orders/get-products', { withCredentials: true })
  }

  getProductById(id: Number): Observable<Product> {
    let headers = new HttpHeaders();
    let params = new HttpParams().set("id", id.toString());
    return this.http.get<Product>( environment.apiURL + '/services/orders/get-product-by-id', { headers: headers, params:params})
  }

}
