import { Component, OnInit, OnDestroy, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { PaymentsService } from '../services/payments.service';
import { Subscription} from 'rxjs';
import { User } from '../login/login.component';
import { DefaultProduct, GetStripeId, Product } from 'src/app/products-templated-list/products-templated-list.component';
import { reducedPrice } from 'src/app/product/product.component';
import { TermsAndConditionsModalComponent } from '../terms-and-conditions-modal/terms-and-conditions-modal.component';
import { AccountService } from 'src/app/services/account.service';
import { ModalService } from '../services/modal.service';
import { LoginModalComponent } from '../login/login-modal/login-modal.component';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit, AfterViewInit {

  constructor(private productsService: ProductsService, private route: ActivatedRoute, private router: Router,
    private accountService: AccountService, private paymentsService: PaymentsService, private modalService: ModalService) { }

  @ViewChildren('moving') descriptionQuery: QueryList<any>;

  product: Product = DefaultProduct;
  description: String[];
  error: Error;
  user: User;
  userSub: Subscription;

  beta1Key = "Beta 1 Key"

  ngOnInit(): void 
  {
    this.userSub = this.accountService.userObservable.subscribe(
      {
        next: user => {
          if(user) {
            this.user = user;
          }
        },
        error: err => {
          this.error = err;
        }
      }
    )

    this.route.paramMap.subscribe( paramMap => 
    {
        let id = paramMap.get('id');
        if(id !== null)
        {
          this.product.id = parseInt(id);
          this.productsService.getProductById(this.product.id).subscribe(
            next => {
              this.product = next;
              if (this.paymentsService.GetPercentOff()) {
                this.product.reduction = this.paymentsService.GetPercentOff()
              }
              this.description = this.product.description.split(",");
            },
            (err) => {
              this.error = err;
              this.router.navigate([".."]);
              console.log(err);
            }
          )
        }
        else
          this.router.navigate([".."]);
    })
  }
  ngAfterViewInit(): void {
    this.descriptionQuery.changes.subscribe(t => {
      var elementRef: any[] = [];
      this.descriptionQuery.forEach(component => elementRef.push(component.nativeElement));
      gsap.timeline()
        .staggerFromTo(elementRef, 0.5, { opacity: 0, x: 400 }, { opacity: 1, x: 0 }, 0.2)
    })
  }

  ngOnDestroy(): void
  {
    this.userSub.unsubscribe();
  }

  ShowKey(value: string) {
    for (var desc in this.description) {
      if (this.description[desc].includes(value)) {
        return true;
      }
    }
    return false;
  }

  getReducedPrice(): number
  {
    return reducedPrice(this.product)
  }

  onBuyEvent(product: Product) 
  {
    let itemId = GetStripeId(product)
    if (this.user?.isLogin) {
      if (!this.user.has_accepted_termsAndConditions) {
        const termsAndConditionsModal = this.modalService.open(TermsAndConditionsModalComponent, { size: 'lg', modalDialogClass: 'modal-dialog-centered' });
        termsAndConditionsModal.componentInstance.SetItemId(itemId);
      }
      else {
        this.paymentsService.checkout(itemId).subscribe(
          () => {
          },
          (error) => {
            console.log(error)
            this.error = error;
          }
        )
      }
    } else {
      const loginModal = this.modalService.open(LoginModalComponent, { size: 'lg', modalDialogClass: 'modal-dialog-centered' });
    }
  }

}
