<div class="flexible-container" style="position:relative">

  <div id="SoulCastHomeVideo" [ngClass]="{'BackGroundPosMobile': isBiggerScreen()}">
    <div #playerHomeContainer class="video" style="position: absolute; top: 50%; left: 50%; transform: translate(-50% ,-50%)"></div>
  </div>

  <div class="alert alert-light small p-2 m-auto" style="width: fit-content; position: absolute;top:10%;left:50%; z-index: 1; transform:translate(-50%,0);text-align:center">
    Website under construction
  </div>

  <div id="video_overlays" class="styling">

    <div [ngClass]="{'posPC': !isBiggerScreen(), 'posMobile': isBiggerScreen()}">

      <svg width="max(100%,30vw)" height="30vw" min-height="300px" shape-rendering="crispEdges"><image loading="lazy" href="assets/assets_web_2/soulcast-logo.svg" alt="Logo" width="100%" height="100%" shape-rendering="crispEdges"/></svg>

      <div class="d-flex justify-content-center pt-3">
        <!--
        <a class="px-2" href="https://www.kickstarter.com" target="_blank" rel="noopener">
          <svg width="max(2.5vw, 35px)" height="max(2.5vw, 35px)"><image loading="lazy" href="assets/assets_web_2/Icon_awesome-kickstarter-k.svg" alt="Icon" width="100%" height="100%" /></svg>
        </a>
          -->
        <a class="px-2" href="https://discord.gg/Tph9tp4Qan" target="_blank" rel="noopener">
          <svg width="max(2.5vw, 35px)" height="max(2.5vw, 35px)"><image loading="lazy" href="assets/assets_web_2/discord.svg" alt="Icon" width="100%" height="100%" /></svg>
        </a>
        <a class="px-2" href="https://www.youtube.com/channel/UCLhG8CiCtx3HCk2XLYZStNg" target="_blank" rel="noopener">
          <svg width="max(2.5vw, 35px)" height="max(2.5vw, 35px)"><image loading="lazy" href="assets/assets_web_2/Icon_awesome-youtube.svg" alt="Icon" width="100%" height="100%" /></svg>
        </a>
        <a class="px-2" href="https://www.instagram.com/playsoulcast" target="_blank" rel="noopener">
          <svg width="max(2.5vw, 35px)" height="max(2.5vw, 35px)"><image loading="lazy" href="assets/assets_web_2/instagram.svg" alt="Icon" width="100%" height="100%" /></svg>
        </a>
        <a class="px-2" href="https://www.facebook.com/SoulCastOfficial" target="_blank" rel="noopener">
          <svg width="max(2.5vw, 35px)" height="max(2.5vw, 35px)"><image loading="lazy" href="assets/assets_web_2/facebook.svg" alt="Icon" width="100%" height="100%" /></svg>
        </a>
        <a class="px-2" href="https://twitter.com/PlaySoulCast" target="_blank" rel="noopener">
          <svg width="max(2.5vw, 35px)" height="max(2.5vw, 35px)"><image loading="lazy" href="assets/assets_web_2/Icon_awesome-twitter.svg" alt="Icon" width="100%" height="100%" /></svg>
        </a>
      </div>
    </div>


    <svg class="fadeOut" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 300 400" style="width:15vw;margin-top:20vh">
      <path style="width: 100%; opacity: 0.1;" id="line" fill="none" stroke="#fff" stroke-width="2" d="M 0 0 C 100 0 0 0 300 0 C 300 200 300 300 150 350 C 0 300 0 200 0 0 M 26.5 27.5 L 270 27.5 C 271.707 107.481 275.096 205.397 241.206 230.935 C 223.85 206.5 218.5 187.75 210.8 171.35 C 228.2 160.65 243.25 150 256 134.9 C 181.417 67.298 113.275 68.024 42.15 138.2 C 56.3333 147.05 68.8167 159.2 89 171 C 85 191.1 69.95 215.5 56.9 228.55 C 31.45 199.45 20.1 110.1 26.45 27.45 M 127.5 118.15 C 143.2 105.1 156.95 103.75 173 118.5 C 157.6 135.55 143.55 135.2 127.5 118.15 M 105.75 121.15 C 133.15 154.7 168.65 155.4 189.4 121.5 C 198.45 124.85 205.8 128.5 213.5 133.85 C 170.3 171.35 130.85 170.7 84 135.9 C 90.35 127.85 97.35 123.85 105.75 121.15 M 113.2 181.4 C 120.35 184.25 126.15 184.75 135.05 185.1 C 134.85 196.55 137 269.2 135.1 290.85 C 123.3 287.25 98.3 275.45 78.8 251.85 C 91.1 228.8 103.35 204.95 113.2 181.4 M 184.55 182.5 C 177.6833 184.0333 170.8167 185.5667 163.05 184.85 C 163.7 195.85 162.6 269.15 164.8 291.4 C 177.75 287.5 207.2 273.45 220.95 253.35 C 207.9 231.95 195.5 206.15 184.45 182.4" />
    </svg>


  </div>

  <img loading="lazy" *ngIf="!isBiggerScreen() && showFirstBorder" [@fadeInOut] src="assets/border3.webp" alt="border" style="position: absolute; transform: translate(0%,1%) rotate(180deg); height: 100px; width: 100%; bottom: 0; pointer-events: none;">


</div>
<div *ngIf="isBiggerScreen() && showFirstBorder" [@fadeInOut] style="display: flex;width:100vw; justify-content: center; position: absolute;bottom:0; pointer-events: none; z-index: 100 ">
  <svg width="min(600px,90vw)" height="50px" max-width="100vw" preserveAspectRatio="xMaxYMax" style="transform: translate(0,50%)">
    <image loading="lazy" href="assets/assets_web_2/Ornement-v2.svg" alt="Timeline" width="100%" height="100%" />
  </svg>
</div>
