import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import Player from '@vimeo/player';

enum GamePlayOpen {
  Lore,
  Explore,
  Quest,
  Crime
}

@Component({
  selector: 'app-gameplay-activities',
  templateUrl: './gameplay-activities.component.html',
  styleUrls: ['./gameplay-activities.component.css']
})
export class GameplayActivitiesComponent implements OnInit, AfterViewInit {
  @ViewChild('playerGamePlayContainer') playerContainer: ElementRef;
  constructor() { }
  videoPlayer;
  isGamePlayOpen: GamePlayOpen = GamePlayOpen.Lore;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    const options = {
      id: 798424855,
      height: document.getElementById('SoulCastGamePlayVideo')?.clientHeight,
      loop: true,
      background: true,
      controls: false,
      autoplay: false,
      autopause: false
    };
    this.videoPlayer = new Player(this.playerContainer.nativeElement, options);

    // IntersectionObserver
    let setupIntersectionObserver = function (target) {
      target.videoPlayer.pause();
      var optionsIntersectionObserver = { threshold: 0.1 }
      const callback = function (entries) {
        if (entries[0].isIntersecting) {
          target.videoPlayer.play();
        } else {
          target.videoPlayer.pause();
        }
      }
      var observer = new IntersectionObserver(callback, optionsIntersectionObserver);
      observer.observe(target.videoPlayer.element);
    };
    this.videoPlayer.ready().then(setupIntersectionObserver(this));
  }

  ClickLore() {
    if (this.isGamePlayOpen == GamePlayOpen.Lore)
      return;
    gsap.fromTo('.RectFL', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });
    this.ChangeVideoURL(798424855)
    this.OnClickClose();
    this.isGamePlayOpen = GamePlayOpen.Lore;
    this.OnClickOpen();
  }
  ClickWorld() {
    if (this.isGamePlayOpen == GamePlayOpen.Explore)
      return;
    gsap.fromTo('.RectFW', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });
    this.ChangeVideoURL(798481319);
    this.OnClickClose();
    this.isGamePlayOpen = GamePlayOpen.Explore;
    this.OnClickOpen();
  }
  ClickQuest() {
    if (this.isGamePlayOpen == GamePlayOpen.Quest)
      return;
    gsap.fromTo('.RectFQ', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });
    this.ChangeVideoURL(798427036);
    this.OnClickClose();
    this.isGamePlayOpen = GamePlayOpen.Quest;
    this.OnClickOpen();
  }

  ClickCrime() {
    if (this.isGamePlayOpen == GamePlayOpen.Crime)
      return;
    gsap.fromTo('.RectFC', 0.25, { scale: 2, x: 0 }, { scale: 1, x: 15 });
    this.ChangeVideoURL(798879722);
    this.OnClickClose();
    this.isGamePlayOpen = GamePlayOpen.Crime;
    this.OnClickOpen();
  }

  LoreMouseEnter() {
    gsap.fromTo('.RectFL', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  LoreMouseLeave() {
    if (this.isGamePlayOpen != GamePlayOpen.Lore)
      gsap.fromTo('.RectFL', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  QuestMouseEnter() {
    gsap.fromTo('.RectFQ', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  QuestMouseLeave() {
    if (this.isGamePlayOpen != GamePlayOpen.Quest)
      gsap.fromTo('.RectFQ', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  WorldMouseEnter() {
    gsap.fromTo('.RectFW', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  WorldMouseLeave() {
    if (this.isGamePlayOpen != GamePlayOpen.Explore)
      gsap.fromTo('.RectFW', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }
  CrimeMouseEnter() {
    gsap.fromTo('.RectFC', 0.25, { autoAlpha: 0 }, { autoAlpha: 1 });
  }
  CrimeMouseLeave() {
    if (this.isGamePlayOpen != GamePlayOpen.Crime)
      gsap.fromTo('.RectFC', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
  }

  OnClickClose() {
    if (this.isGamePlayOpen == GamePlayOpen.Lore) {
      gsap.timeline().to(".LoreText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFL', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
    if (this.isGamePlayOpen == GamePlayOpen.Explore) {
      gsap.timeline().to(".ExploreText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFW', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
    if (this.isGamePlayOpen == GamePlayOpen.Quest) {
      gsap.timeline().to(".QuestText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFQ', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
    if (this.isGamePlayOpen == GamePlayOpen.Crime) {
      gsap.timeline().to(".CrimeText", { autoAlpha: 0, duration: 0.5 });
      gsap.fromTo('.RectFC', 0.25, { autoAlpha: 1 }, { autoAlpha: 0 });
    }
  }

  OnClickOpen() {
    if (this.isGamePlayOpen == GamePlayOpen.Lore)
      gsap.timeline().to(".LoreText", { autoAlpha: 1, duration: 0.5 });
    if (this.isGamePlayOpen == GamePlayOpen.Explore)
      gsap.timeline().to(".ExploreText", { autoAlpha: 1, duration: 0.5 });
    if (this.isGamePlayOpen == GamePlayOpen.Quest)
      gsap.timeline().to(".QuestText", { autoAlpha: 1, duration: 0.5 });
    if (this.isGamePlayOpen == GamePlayOpen.Crime)
      gsap.timeline().to(".CrimeText", { autoAlpha: 1, duration: 0.5 });
  }

  ChangeVideoURL(value: number) {
    this.videoPlayer.loadVideo(value).then(function (this) {
      this.videoPlayer.play();
    })
  }

}
