import { Component, OnInit, OnDestroy } from '@angular/core';
import { User, DefaultUser } from 'src/app/login/login.component';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { AccountComponent } from 'src/app/account/account.component'; // toast
import { environment } from 'src/environments/environment'
declare var $: any;

@Component({
  selector: 'app-account-overview',
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.css']
})
export class AccountOverviewComponent implements OnInit, OnDestroy {

  constructor(private account: AccountService, private accountComponent: AccountComponent) { this.initializeUser(); }

  user: User;
  userSub: Subscription;

  progressPRC: number = 100;

  selectedPicId;

  ngOnInit(): void {
    $('#avatarModal').insertAfter($('body'));
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  initializeUser() 
  {
    this.user = DefaultUser
    this.userSub = this.account.userObservable.subscribe(
      (next: User) => 
      {
        // Cette ligne est executée chaque fois qu'un nouvel user est emis par le subject
        if(next != null)
          this.user = next;
        else
          this.user = DefaultUser
        this.CheckProgressBar();
        this.selectedPicId = this.user.icon_index;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  CheckProgressBar() {
    this.progressPRC = 100;
    if (!this.user.email)
      this.progressPRC -= 20;
    if (!this.user.first_name)
      this.progressPRC -= 20;
    if (!this.user.has_password )
      this.progressPRC -= 20;
    if (!this.user.has_subscribed_to_newsletter || !this.user.email)
      this.progressPRC -= 20;
    if (!this.user.has_verified_email)
      this.progressPRC -= 20;
  }

  SelectPic(num: number) {
    this.selectedPicId = num;
  }

  SaveAvatarPic() {
    var toastStr: string;
    this.account.changeAvatarPic(this.selectedPicId).subscribe(
      (response) => {
        toastStr = "Your account avatar is updated";
        this.accountComponent.showToast(toastStr, 0);
      },
      (error) => {//Error callback
        console.log(error);
        toastStr = "Updating avatar ";
        toastStr += this.account.getErrorStr(error.status);
        this.accountComponent.showToast(toastStr, 1);
      }
    );
  }

  LoginSteam()
  {
    location.href = environment.apiURL + "/login/steam";
  }

}
