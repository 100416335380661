import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { OrdersService } from 'src/app/services/orders.service';
import {  Order, DefaultOrder } from "src/app/orders-templated-list/orders-templated-list.component"

@Component({
  selector: 'app-account-orders',
  templateUrl: './account-orders.component.html',
  styleUrls: ['./account-orders.component.css']
})
export class AccountOrdersComponent implements OnInit {

  constructor(private orders: OrdersService) 
  { 
  }

  ordersSub: Subscription;
  listOrders: Array<Order> = [];

  ngOnInit(): void 
  {
    this.ordersSub = this.orders.ordersObservable.subscribe(
      (next: Array<Order>) => 
      {
        this.listOrders = next;
        //this.updateCollapsedList();
      },
      (error) => {
        console.log(error);
      }
    )

    this.orders.getOrders()
  }

  ngOnDestroy(): void {
    //this.ordersSub.unsubscribe();
  }

}
