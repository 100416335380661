import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  constructor() 
  { 
    let url = location.href;
    // Remove domain name from URL
    url = url.replace(/^.*\/\/[^\/]+/, '');
    location.href = environment.apiURL + url
  }

  ngOnInit(): void 
  {
  }

}
